import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { find } from "lodash";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import TextField from "../../components/field/TextField";
import EditForm from "../../components/field/EditForm";
import MultiComboBox from "../../components/field/MultiComboBox";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import { technologiesUsedOptions } from "../../utilities/constants";

function DriverGroupDetailsForm(props) {
  const [item, onChanged, onCancel, onNext] = [
    props.item,
    props.onChanged,
    props.onCancel,
    props.onNext,
  ];

  if (item.reservationsEnabled == undefined) {
    item.reservationsEnabled = false;
    onChanged(item);
  }

  const [name, setName] = useState("");
  const [technologiesUsed, setTechnologiesUsed] = useState([]);
  const [reservationsEnabled, setReservationsEnabled] = useState(false);

  useEffect(() => {
    setName(item.name);

    setReservationsEnabled(item.reservationsEnabled);

    if (item.accessControl != undefined) {
      setTechnologiesUsed(
        item.accessControl.map((x) => {
          return {
            name: find(technologiesUsedOptions, { id: x }).name,
            id: x,
          };
        })
      );
    }
  }, [item]);

  const changeName = (value) => {
    item.name = value;
    setName(value);
    onChanged(item);
  };

  const onReservationsEnabledChange = (enabled) => {
    item.reservationsEnabled = enabled;
    setReservationsEnabled(enabled);
    onChanged(item);
  };

  const formComponents = [
    <TextField
      key={1}
      value={name}
      placeholder="Enter name"
      validationController={(value) => {
        const valueTrimmed = value.trim();
        if (valueTrimmed == "") {
          return {
            success: false,
            text: "Name cannot be  empty.",
          };
        }

        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(value) => changeName(value)}
    />,
    <MultiComboBox
      key={2}
      values={technologiesUsed}
      label="Technology used"
      options={technologiesUsedOptions}
      validationController={(values) => {
        if (values.length === 0) {
          return {
            success: false,
            text: "You must select at least one technology",
          };
        }

        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(values) => {
        if (item.accessControl == undefined) {
          item.accessControl = [];
        }

        item.accessControl = values.map((x) => x.id);
        setTechnologiesUsed(values);
        onChanged(item);
      }}
    />,
    <FormControlLabel
      control={
        <Checkbox
          checked={reservationsEnabled}
          onChange={(event) =>
            onReservationsEnabledChange(event.target.checked)
          }
          color="primary"
        />
      }
      label="Reservations enabled"
    />,
  ];

  const form = (
    <EditForm
      components={formComponents}
      continueAction={<PrimaryButton value="Next" />}
      cancelAction={<PrimaryButton onClick={() => onCancel()} value="Cancel" />}
      onValidated={(result) => {
        if (result) {
          onNext();
        }
      }}
    />
  );

  return <div>{form}</div>;
}

export default withRouter(DriverGroupDetailsForm);
