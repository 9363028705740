import { getParkingLot, saveParkingLot } from "../../utilities/apiUtils";
import { paths } from "../../utilities/constants";
import ParkingLotForm from "./ParkingLotForm";

export const ParkingLotCrudConfig = {
  getTitle: (item) => {
    if (!item.id) {
      return "Create new parking lot";
    }

    return `Edit parking lot "${item.name}"`;
  },

  fetch: (id) => {
    return getParkingLot(id);
  },

  save: (item) => {
    return saveParkingLot(item);
  },

  indexPage: (item) => {
    return `${paths.accessManagementPath}/parking-lots`;
  },

  itemForm: <ParkingLotForm />,
};
