import React, { useState, useEffect } from "react";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import EditForm from "../../components/field/EditForm";
import ClientPaymentConfigurationForm from "../../components/payments/ClientPaymentConfigurationForm";
import { paymentStrategies } from "../../utilities/constants";
import { getClientPaymentSettings } from "../../utilities/apiUtils";
import SpinnerImage from "../../assets/images/spinner.gif";

function ClientUserPaymentSettingsForm(props) {
  const [item, onChanged, onCancel, onNext, onBack] = [
    props.item,
    props.onChanged,
    props.onCancel,
    props.onNext,
    props.onBack,
  ];

  if (item.client == undefined) {
    item.client = {};
  }

  const [settings, setSettings] = useState(item.client.pricing || {});
  const [isValid, setIsValid] = useState(true);
  const [validationCounter, setValidationCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (item.client.pricing == undefined) {
      setLoading(true);
      getClientPaymentSettings().then((res) => {
        if (res.data) {
          item.client.pricing = res.data;
        } else {
          item.client.pricing = {
            price: {
              amount: 0.0,
              currency: 'USD',
            },
            strategy: paymentStrategies.Free,
          };
        }
        setSettings(item.client.pricing);
        onChanged(item);
        setLoading(false);
      });
    } else {
      setSettings(item.client.pricing);
    }
    
  }, [item.client.pricing]);

  const processSettingsChanged = (newSettings, isValid) => {
    item.client.pricing = newSettings;
    setSettings(newSettings);
    setIsValid(isValid);
    onChanged(item);
  };

  const handleOnNext = () => {
    setValidationCounter(validationCounter + 1);
    return isValid;
  };

  const formComponents = [
    <ClientPaymentConfigurationForm
      settings={settings}
      onChanged={processSettingsChanged}
      validationCounter={validationCounter}
    />
  ];

  const form = (
    <EditForm
      components={formComponents}
      continueAction={<PrimaryButton value="Next" />}
      cancelAction={<PrimaryButton onClick={() => onCancel()} value="Cancel" />}
      backAction={<PrimaryButton onClick={() => onBack()} value="Back" />}
      onValidated={(result) => {
        const handleResult = handleOnNext();
        if (result && handleResult) {
          onNext();
        }
      }}
    />
  );

  return <>
    { loading && <div class="alert alert-primary"><img src={SpinnerImage} className="spinner-image" /> Loading...</div> }
    { !loading && <div>{form}</div> }
  </>;
}

export default ClientUserPaymentSettingsForm;
