import React from "react";
import { withRouter } from "react-router-dom";
import FormTabs from "../genericModifyPage/FormTabs";
import ClientDetailsForm from "./ClientDetailsForm";
import ClientFinalStepForm from "./ClientFinalStepForm";
import ClientUserPaymentSettingsForm from "./ClientUserPaymentSettingsForm";

function ClientForm(props) {
  const [item, onChanged, onSave, onCancel, type] = [
    props.item,
    props.onChanged,
    props.onSave,
    props.onCancel,
    props.type,
  ];

  return (
    <FormTabs
      item={item}
      onChanged={onChanged}
      onSave={onSave}
      onCancel={onCancel}
      history={props.history}
      pageProps={props.pageProps}
      additionalProps={{
        type: type,
      }}
      tabs={[
        {
          title: "Details",
          body: <ClientDetailsForm />,
        },
        {
          title: "Payment settings",
          body: <ClientUserPaymentSettingsForm />,
        },
        {
          title: "Final step",
          body: <ClientFinalStepForm />,
        },
      ]}
    />
  );
}

export default withRouter(ClientForm);
