import React, { useState, useEffect } from "react";
import { find, remove } from "lodash";
import AccessTable from "./AccessTable";
import { Button, InputLabel, MenuItem, Select } from "@material-ui/core";
import CommonModal from "../../components/commonModal/CommonModal";
import MonthPicker from "../../components/date/MonthPicker";
import YearPicker from "../../components/date/YearPicker";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";

const setItemRestrictionData = (item) => {
  if (item.accessRestriction == undefined) {
    item.accessRestriction = {
      customRestrictions: [],
      defaultRestrictions: [],
    };
  }

  if (item.accessRestriction.defaultRestrictions == undefined) {
    item.accessRestriction.defaultRestrictions = [];
  }

  if (item.accessRestriction.customRestrictions == undefined) {
    item.accessRestriction.customRestrictions = [];
  }
};

function DriverGroupAccessPermissionsForm(props) {
  const [item, onChanged, onCancel, onNext, onBack] = [
    props.item,
    props.onChanged,
    props.onCancel,
    props.onNext,
    props.onBack,
  ];

  const [restrictionMode, setRestrictionMode] = useState("default");
  const [datesSelectable, setDatesSelectable] = useState(false);
  const [restrictionDateSelected, setRestrictionDateSelected] = useState("");
  const [restrictionModalShown, setRestrictionModalShown] = useState(false);
  const [year, setYear] = useState(2021);
  const [monthSelected, setMonthSelected] = useState("01");
  const [
    restrictionSelectionErrorVisible,
    setRestrictionSelectionErrorVisible,
  ] = useState(false);
  const [restrictionSelectionErrorText, setRestrictionSelectionErrorText] =
    useState("");
  const [restrictionsSelected, setRestrictionsSelected] = useState([]);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    setItemRestrictionData(item);

    if (restrictionMode == "default") {
      setEnabled(true);
      setRestrictionsSelected(item.accessRestriction.defaultRestrictions);
    } else {
      const [year, month] = restrictionDateSelected.split("-");
      const restrictionData = find(
        item.accessRestriction.customRestrictions,
        (x) => x.yearMonth.year == year && x.yearMonth.month == month
      );

      if (restrictionData) {
        setRestrictionsSelected(restrictionData.restrictions);
        setEnabled(true);
      } else {
        setEnabled(false);
      }
    }
  }, [restrictionMode, restrictionDateSelected]);

  const processRestrictions = (newRestrictions) => {
    setRestrictionsSelected(newRestrictions);

    if (restrictionMode == "default") {
      item.accessRestriction.defaultRestrictions = newRestrictions;
    } else {
      const [year, month] = restrictionDateSelected.split("-");
      const restrictionData = find(
        item.accessRestriction.customRestrictions,
        (x) => x.yearMonth.year == year && x.yearMonth.month == month
      );
      restrictionData.restrictions = newRestrictions;
      remove(
        item.accessRestriction.customRestrictions,
        (x) => x.yearMonth.year == year && x.yearMonth.month == month
      );
      item.accessRestriction.customRestrictions.push(restrictionData);
    }

    onChanged(item);
  };

  const handleChange = (event) => {
    setRestrictionMode(event.target.value);
  };

  const handleRestrictionDateChange = (event) => {
    setRestrictionDateSelected(event.target.value);
  };

  const addRestrictionDate = () => {
    setRestrictionSelectionErrorText("");
    setRestrictionSelectionErrorVisible(false);
    setMonthSelected("01");
    setYear(2021);

    setRestrictionModalShown(true);
  };

  const rejectChanges = () => {
    setRestrictionModalShown(false);
  };

  const saveChanges = () => {
    const restriction = find(
      item?.accessRestriction?.customRestrictions.map((x) => x.yearMonth) || [],
      (x) => x.year == year && x.month == monthSelected
    );
    if (restriction != undefined) {
      setRestrictionSelectionErrorText(
        "This restriction has been added already."
      );
      setRestrictionSelectionErrorVisible(true);
      return;
    }

    setRestrictionSelectionErrorText("");
    setRestrictionSelectionErrorVisible(false);

    if (item.accessRestriction == undefined) {
      item.accessRestriction = {
        customRestrictions: [],
      };
    } else if (item.accessRestriction.customRestrictions == undefined) {
      item.accessRestriction.customRestrictions = [];
    }

    item.accessRestriction.customRestrictions.push({
      yearMonth: {
        year: year.toString(),
        month: monthSelected,
      },
      restrictions: [],
    });

    setRestrictionModalShown(false);
    setRestrictionDateSelected(`${year}-${monthSelected}`);
    onChanged(item);
  };

  const eraseRestrictionSelected = () => {
    const [year, month] = restrictionDateSelected.split("-");
    setRestrictionDateSelected("");

    remove(
      item?.accessRestriction?.customRestrictions,
      (x) => x.yearMonth.year == year && x.yearMonth.month == month
    );
    onChanged(item);
  };

  useEffect(() => {
    setDatesSelectable(restrictionMode == "year-month");
  }, [restrictionMode]);

  const restrictionDates = (
    item?.accessRestriction?.customRestrictions.map((x) => x.yearMonth) || []
  ).map((x) => (
    <MenuItem value={x.year + "-" + x.month}>{x.year + "-" + x.month}</MenuItem>
  ));

  return (
    <>
      <CommonModal
        show={restrictionModalShown}
        onClose={rejectChanges}
        body={
          <>
            {restrictionSelectionErrorVisible && (
              <div class="alert alert-danger" role="alert">
                {restrictionSelectionErrorText}
              </div>
            )}
            <YearPicker getter={year} setter={setYear} />
            <MonthPicker getter={monthSelected} setter={setMonthSelected} />
          </>
        }
        title="Add restriction date"
        footer={
          <div>
            <Button variant="secondary" onClick={() => rejectChanges()}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => saveChanges()}>
              Save
            </Button>
          </div>
        }
      />
      <div>
        <div class="form-group">
          <InputLabel id="restriction-mode-selection">
            Restriction mode
          </InputLabel>
          <Select
            labelId="restriction-mode-selection"
            value={restrictionMode}
            onChange={handleChange}
          >
            <MenuItem value="default">Default</MenuItem>
            <MenuItem value="year-month">Specific year and month</MenuItem>
          </Select>
        </div>
        {datesSelectable && (
          <>
            {restrictionDates.length > 0 && (
              <div class="form-group">
                <div class="form-row">
                  <div class="form-group">
                    <InputLabel id="current-restriction-dates">
                      Current restriction dates
                    </InputLabel>
                    <Select
                      labelId="current-restriction-dates"
                      value={restrictionDateSelected}
                      onChange={handleRestrictionDateChange}
                    >
                      <MenuItem value="">
                        <em>Select restriction date for modification</em>
                      </MenuItem>
                      {restrictionDates}
                    </Select>
                  </div>
                  {restrictionDateSelected != "" && (
                    <div class="form-group">
                      <Button
                        variant="primary"
                        class="btn btn-primary"
                        onClick={() => eraseRestrictionSelected()}
                      >
                        Erase selected restriction date
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div class="form-group">
              <Button
                variant="primary"
                onClick={() => addRestrictionDate()}
                class="btn btn-primary"
              >
                Add restriction date
              </Button>
            </div>
          </>
        )}
        <div class="form-group">
          <AccessTable
            restrictions={restrictionsSelected}
            onChanged={processRestrictions}
            enabled={enabled}
          />
        </div>
        <div class="action-buttons">
          <div class="row">
            <PrimaryButton onClick={() => onCancel()} value="Cancel" />
            <PrimaryButton onClick={() => onBack()} value="Back" />
            <PrimaryButton onClick={() => onNext()} value="Save" />
          </div>
        </div>
      </div>
    </>
  );
}

export default DriverGroupAccessPermissionsForm;
