import "./ActionButton.scss";

export default function ActionButton(props) {
  const { onClick, value } = props;
  return (
    <button onClick={onClick} class="alot-action-button">
      {value}
    </button>
  );
}
