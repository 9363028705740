import React from "react";
import { withRouter } from "react-router-dom";
import GenericModifyPage from "../genericModifyPage/GenericModifyPage";
import { DriverGroupCrudConfig } from "./DriverGroupCrudConfig";

import "./ModifyDriverGroupPage.scss";

function ModifyDriverGroupPage(props) {
  const { id } = props.match.params;

  return (
    <GenericModifyPage
      id={id}
      getTitle={DriverGroupCrudConfig.getTitle}
      fetch={DriverGroupCrudConfig.fetch}
      save={DriverGroupCrudConfig.save}
      indexPage={DriverGroupCrudConfig.indexPage}
      itemForm={DriverGroupCrudConfig.itemForm}
      validate={DriverGroupCrudConfig.validate}
    />
  );
}

export default withRouter(ModifyDriverGroupPage);
