import React, { useState, useEffect } from "react";
import Table from "./Table";
import SpinnerImage from "../../assets/images/spinner.gif";
import "./PaginatedTable.scss";

export default function PaginatedTable(props) {
  const { headers, generateRow, countLoader, itemsLoader, noItemsText, } = props;
  const itemsPerPage = props.itemsPerPage || 10;

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [itemsCount, setItemsCount] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(-1);

  useEffect(() => {
    countLoader().then((res) => {
      if (res.data) {
        setItemsCount(res.data.count);
        console.log('ass', res.data.count, itemsPerPage);
        setNumPages(Math.ceil(res.data.count / itemsPerPage));

        if (res.data.count === 0) {
          setLoading(false);
          return;
        }

        setCurrentPage(0);
      }
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    itemsLoader(/* limit =*/itemsPerPage, /* skip =*/currentPage * itemsPerPage).then((items) => {
      if (items.data) {
        setItems(items.data);
        setLoading(false);
      }
    });
  }, [currentPage]);

  const navigateToPage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="paginatedTable">
      { !loading && itemsCount === 0 && <div className="alert alert-primary">{noItemsText}</div> }
      { loading && <div className="alert alert-primary"><img src={SpinnerImage} className="spinner-image" /> Loading...</div> }
      { !loading && itemsCount > 0 &&
      <>
        <div className="content">
          <Table
            headers={headers}
            generateRow={generateRow}
            items={items}
          />
        </div>
        { numPages > 1 &&
        <div className="pagination-area">
          <ul className="pagination justify-content-center flex-wrap">
            <li className={`page-item${currentPage == 0 ? ' disabled' : ''}`}>
              <a className="page-link" href="#/" onClick={() => navigateToPage(currentPage - 1)}>Previous</a>
            </li>
            {
              [...Array(numPages).keys()].map((pageNumber) => {
                return <li className={`page-item${pageNumber == currentPage ? ' active' : ''}`}><a className="page-link" href="#/" onClick={() => navigateToPage(pageNumber)}>{pageNumber + 1}</a></li>;
              })
            }
            <li className={`page-item${currentPage == numPages - 1 ? ' disabled' : ''}`}>
              <a className="page-link" href="#/" onClick={() => navigateToPage(currentPage + 1)}>Next</a>
            </li>
          </ul>
        </div>
        }
      </>
      }
    </div>
  );
}
