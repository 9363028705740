import React from "react";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import GlobalProvider from "./utilities/context/GlobalProvider";
import ContextUpdateWrapper from "./components/ContextUpdateWrapper/ContextUpdateWrapper";
import DashboardPage from "./pages/dashboardPage/DashboardPage";
import AnalyticsPage from "./pages/analyticsPage/AnalyticsPage";
import LoginPage from "./pages/loginPage/LoginPage";
import ParkingLotsPage from "./pages/accessManagementParkingLots/ParkingLotsPage";
import DriversPage from "./pages/accessManagementDrivers/DriversPage";
import GateManagementPage from "./pages/accessManagementGateManagement/GateManagementPage";
import ReservationsPage from "./pages/accessManagementReservations/ReservationsPage";
import PaymentsPage from "./pages/revenueManagementPayments/PaymentsPage";
import InvoicesPage from "./pages/revenueManagementInvoices/InvoicesPage";
import BillingPage from "./pages/revenueManagementBilling/BillingPage";
import AdminsPage from "./pages/multiUserManagementAdmins/AdminsPage";
import SubAdminsPage from "./pages/multiUserManagementSubAdmins/SubAdminsPage";
import DriverGroupsPage from "./pages/multiUserManagementDriverGroups/DriverGroupsPage";
import { roles, paths } from "../src/utilities/constants";
import { PrivateRoute } from "./components/privateRoute/PrivateRoute";
import ModifyDriverGroupPage from "./pages/multiUserManagementModifyDriverGroup/ModifyDriverGroupPage";
import ModifyParkingLotPage from "./pages/accessManagementModifyParkingLot/ModifyParkingLotPage";
import ModifyDriverPage from "./pages/accessManagementModifyDriver/ModifyDriverPage";
import ParkingLotsDetailsPage from "./pages/accessManagementParkingLotsDetailsView/ParkingLotsDetailsPage";
import ModifyInvoicePage from "./pages/revenueManagementModifyInvoice/ModifyInvoicePage";
import InvoiceDetailsPage from "./pages/revenueManagementInvoiceDetails/InvoiceDetailsPage";
import ModifyReservationPage from "./pages/accessManagementModifyReservation/ModifyReservationPage";
import ModifyGatePage from "./pages/accessManagementModifyGate/ModifyGatePage";
import ReservationSettingsPage from "./pages/settings/reservation/ReservationSettingsPage";
import ModifyAdminPage from "./pages/multiUserManagementModifyAdmin/ModifyAdminPage";
import ModifySubAdminPage from "./pages/multiUserManagementModifySubAdmin/ModifySubAdminPage";
import InvoicePaymentActionPage from "./pages/revenueManagementInvoicePaymentAction/InvoicePaymentActionPage";
import DriverPaymentSettingsPage from "./pages/settings/driverPayments/DriverPaymentSettingsPage";
import ClientPaymentSettingsPage from "./pages/settings/clientPayments/ClientPaymentSettingsPage";

function App() {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <GlobalProvider>
        <ContextUpdateWrapper>
          <BrowserRouter>
            <Switch>
              <PrivateRoute
                exact
                path="/dashboard"
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={DashboardPage}
              />
              <PrivateRoute
                exact
                path="/analytics"
                component={AnalyticsPage}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
              />
              <PrivateRoute
                exact
                path={`${paths.accessManagementPath}/parking-lots`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={ParkingLotsPage}
              />
              <PrivateRoute
                exact
                path={`${paths.accessManagementPath}/parking-lots/:id`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={ModifyParkingLotPage}
              />
              <PrivateRoute
                exact
                path={`${paths.accessManagementPath}/parking-lots/details-view/:id`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={ParkingLotsDetailsPage}
              />
              <PrivateRoute
                exact
                path={`${paths.accessManagementPath}/drivers`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={DriversPage}
              />
              <PrivateRoute
                exact
                path={`${paths.accessManagementPath}/drivers/:id`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={ModifyDriverPage}
              />
              <PrivateRoute
                exact
                path={`${paths.accessManagementPath}/gate-management`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={GateManagementPage}
              />
              <PrivateRoute
                exact
                path={`${paths.accessManagementPath}/gate-management/:id`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={ModifyGatePage}
              />
              <PrivateRoute
                exact
                path={`${paths.accessManagementPath}/reservations`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={ReservationsPage}
              />
              <PrivateRoute
                exact
                path={`${paths.accessManagementPath}/reservations/:id`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                  roles.Driver,
                ]}
                component={ModifyReservationPage}
              />
              <PrivateRoute
                exact
                path={`${paths.revenueanagementPath}/payments`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={PaymentsPage}
              />
              <PrivateRoute
                exact
                path={`${paths.revenueanagementPath}/invoices`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={InvoicesPage}
              />
              <PrivateRoute
                exact
                path={`${paths.revenueanagementPath}/invoices/client/:id`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={InvoicesPage}
              />
              <PrivateRoute
                exact
                path={`${paths.revenueanagementPath}/invoices/modify/:id`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={ModifyInvoicePage}
              />
              <PrivateRoute
                exact
                path={`${paths.revenueanagementPath}/invoices/modify/:id/company/:company`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={ModifyInvoicePage}
              />
              <PrivateRoute
                exact
                path={`${paths.revenueanagementPath}/invoices/details/:id`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={InvoiceDetailsPage}
              />
              <PrivateRoute
                exact
                path={`${paths.revenueanagementPath}/invoices/pay/:id`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={InvoicePaymentActionPage}
              />
              <PrivateRoute
                exact
                path={`${paths.settings}/reservation`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={ReservationSettingsPage}
              />
              <PrivateRoute
                exact
                path={`${paths.settings}/driver-payments`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={DriverPaymentSettingsPage}
              />
              <PrivateRoute
                exact
                path={`${paths.settings}/client-payments`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                ]}
                component={ClientPaymentSettingsPage}
              />
              <PrivateRoute
                exact
                path={`${paths.revenueanagementPath}/billing`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={BillingPage}
              />

              <PrivateRoute
                exact
                path={`${paths.multiUserManagementPath}/admins`}
                roles={[roles.MasterUser, roles.SuperAdmin]}
                component={AdminsPage}
              />
              <PrivateRoute
                exact
                path={`${paths.multiUserManagementPath}/admins/:id`}
                roles={[roles.MasterUser, roles.SuperAdmin]}
                component={ModifyAdminPage}
              />
              <PrivateRoute
                exact
                path={`${paths.multiUserManagementPath}/sub-admins/:id`}
                roles={[roles.MasterUser, roles.SuperAdmin, roles.Admin]}
                component={ModifySubAdminPage}
              />

              <PrivateRoute
                exact
                path={`${paths.multiUserManagementPath}/sub-admins`}
                roles={[roles.MasterUser, roles.SuperAdmin, roles.Admin]}
                component={SubAdminsPage}
              />
              <PrivateRoute
                exact
                path={`${paths.multiUserManagementPath}/driver-groups`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={DriverGroupsPage}
              />
              <PrivateRoute
                exact
                path={`${paths.multiUserManagementPath}/driver-groups/:id`}
                roles={[
                  roles.MasterUser,
                  roles.SuperAdmin,
                  roles.Admin,
                  roles.SubAdmin,
                ]}
                component={ModifyDriverGroupPage}
              />

              <Route exact path="/login" component={LoginPage} />
              <Redirect to="/login" />
            </Switch>
          </BrowserRouter>
        </ContextUpdateWrapper>
      </GlobalProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
