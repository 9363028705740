import React, { useState, useEffect } from "react";
import { find, remove } from "lodash";
import { Button } from "@material-ui/core";
import MultiTextField from "../../components/field/MultiTextField";
import "./DriverLicensePlateAliasesForm.scss";
import { expectAtLeastOneOption } from "../../components/field/validation/MultiTextFieldUtil";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import ComboBox from "../../components/field/ComboBox";
import { expectValueSet } from "../../components/field/validation/ComboBoxUtil";
import EditForm from "../../components/field/EditForm";

function DriverLicensePlateAliasesForm(props) {
  const [item, onChanged, onBack, onCancel, onNext] = [
    props.item,
    props.onChanged,
    props.onBack,
    props.onCancel,
    props.onNext,
  ];

  const [licensePlates, setLicensePlates] = useState([]);
  const [licensePlatesForSelection, setLicensePlatesForSelection] = useState(
    []
  );
  const [primaryLicensePlateNumber, setPrimaryLicensePlateNumber] = useState(
    {}
  );

  if (item.driver == undefined) {
    item.driver = {};
  }

  if (item.driver.licensePlates == undefined) {
    item.driver.licensePlates = [];
  }

  useEffect(() => {
    setLicensePlates(item.driver.licensePlates);
    if (item.driver.licensePlates.length < 1) {
      return;
    }

    const lpnValues = item.driver.licensePlates.map((item) => {
      return {
        id: item.number,
        name: item.number,
      };
    });
    setLicensePlatesForSelection(lpnValues);

    const primaryLPN = item.driver.primaryLicensePlateNumber;
    const primaryLpnExisting = find(lpnValues, (x) => x.id == primaryLPN);
    if (
      primaryLpnExisting == undefined ||
      primaryLpnExisting == null ||
      primaryLpnExisting == ""
    ) {
      changePrimaryLicensePlateNumber(lpnValues[0]);
    } else if (
      primaryLPN != undefined &&
      primaryLPN != "" &&
      primaryLPN != null
    ) {
      setPrimaryLicensePlateNumber({
        id: primaryLPN,
        name: primaryLPN,
      });
    } else {
      changePrimaryLicensePlateNumber(lpnValues[0]);
    }
  }, [item]);

  const removeLicensePlateAlias = (plate, alias) => {
    const newState = licensePlates;
    remove(
      find(newState, (x) => x.number == plate).aliases,
      (x) => x.alias == alias
    );
    setLicensePlates(newState);
    item.driver.licensePlates = newState;
    onChanged(item);
  };

  const addLicensePlateAlias = (plate, alias) => {
    const newState = licensePlates;
    find(newState, (x) => x.number == plate).aliases.push({
      alias: alias,
    });
    setLicensePlates(newState);
    item.driver.licensePlates = newState;
    onChanged(item);
  };

  const changePrimaryLicensePlateNumber = (value) => {
    item.driver.primaryLicensePlateNumber = value.id;
    setPrimaryLicensePlateNumber(value);
    onChanged(item);
  };

  const formComponents = [
    <ComboBox
      label="Primary license plate number"
      options={licensePlatesForSelection}
      value={primaryLicensePlateNumber}
      validationController={(value) =>
        expectValueSet(value, "You must select a primary license plate number.")
      }
      onValueChanged={(value) => changePrimaryLicensePlateNumber(value)}
      selectionPlaceholder="None"
    />,
    <div class="form-group">
      {licensePlates.map((plate) => (
        <div class="plate-card">
          <span class="plate-card-header">{plate.number}</span>
          <div class="aliases">
            <MultiTextField
              options={plate.aliases.map((x) => x.alias)}
              placeholder="Enter license plate alias and click add"
              onRemoveTriggered={(option) =>
                removeLicensePlateAlias(plate.number, option)
              }
              onOptionAdded={(option) =>
                addLicensePlateAlias(plate.number, option)
              }
              selectionLabel="License plates aliases:"
            />
          </div>
        </div>
      ))}
    </div>,
  ];

  const form = (
    <EditForm
      components={formComponents}
      continueAction={<PrimaryButton value="Next" />}
      backAction={<PrimaryButton onClick={() => onBack()} value="Back" />}
      cancelAction={<PrimaryButton onClick={() => onCancel()} value="Cancel" />}
      onValidated={(result) => {
        if (result) {
          onNext();
        }
      }}
    />
  );

  return <div>{form}</div>;
}

export default DriverLicensePlateAliasesForm;
