import { tokenConstants } from "./constants";

export const addToStorage = (key, value) => {
  if (value) {
    localStorage.setItem(key, value);
  }
};

export const getFromStorage = (key) => {
  return localStorage.getItem(key);
};

export const removeFromStorage = (key) => {
  return localStorage.removeItem(key);
};

export const addToken = (value) => {
  addToStorage(tokenConstants.TOKEN_STORAGE_KEY, value);
};

export const getToken = () => {
  return getFromStorage(tokenConstants.TOKEN_STORAGE_KEY);
};

export const removeToken = () => {
  return removeFromStorage(tokenConstants.TOKEN_STORAGE_KEY);
};
