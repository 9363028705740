import React, { useEffect, useState } from "react";
import { filter, get } from "lodash";
import FieldsContainer from "./FieldsContainer";

function EditForm(props) {
  const [components, continueAction, cancelAction, onValidated, backAction] = [
    props.components,
    props.continueAction,
    props.cancelAction,
    props.onValidated,
    props.backAction,
  ];
  var numValidableComponents = 0;
  const [validationCounter, setValidationCounter] = useState(0);
  const [validationResult, setValidationResult] = useState([]);

  const processFieldsContainer = (
    element,
    validationCounterState,
    validationResultSetter
  ) => {
    const componentsArray = get(element, "props.components", []);
    const label = get(element, "props.label", "");
    const components = componentsArray.map((element) => {
      if (element.type.name == "FieldsContainer") {
        return processFieldsContainer(
          element,
          validationCounterState,
          validationResultSetter
        );
      }

      if (element.props.validationController) {
        numValidableComponents++;
      }

      return (
        <div class="form-group">
          {React.cloneElement(element, {
            validationCounter: validationCounterState,
            onValidated: (result) => {
              validationResultSetter((validationResult) => [
                ...validationResult,
                result,
              ]);
            },
          })}
        </div>
      );
    });

    const fields = <FieldsContainer components={components} label={label} />;

    return fields;
  };

  const processElement = (
    element,
    validationCounterState,
    setValidationResult
  ) => {
    if (element.type.name == "FieldsContainer") {
      return processFieldsContainer(
        element,
        validationCounterState,
        setValidationResult
      );
    }

    if (element.props.validationController) {
      numValidableComponents++;
    }

    return (
      <div class="form-group">
        {React.cloneElement(element, {
          validationCounter: validationCounterState,
          onValidated: (result) => {
            setValidationResult((validationResult) => [
              ...validationResult,
              result,
            ]);
          },
        })}
      </div>
    );
  };

  useEffect(() => {
    if (validationCounter == 0) {
      // Nothing triggered the validation.
      return;
    }

    const validationPassed =
      filter(validationResult, (x) => x.success).length ===
      numValidableComponents;

    onValidated(validationPassed);
  }, [validationResult]);

  const validate = () => {
    setValidationResult([]);
    setValidationCounter(validationCounter + 1);
  };

  const fields = components.map((element) =>
    processElement(element, validationCounter, setValidationResult)
  );

  const Continue = React.cloneElement(continueAction, {
    onClick: () => {
      validate();
    },
  });

  return (
    <>
      {fields}
      <div class="action-buttons">
        <div class="row">
          {cancelAction}
          {backAction && backAction}
          {Continue}
        </div>
      </div>
    </>
  );
}

export default EditForm;
