import axios from "axios";
import { getToken } from "./localStorageUtils";
import { methods } from "./constants";
import { useContext } from "react";
import GlobalContext from "./context/GlobalContext";

export const BFF_URL = process.env.REACT_APP_BACKEND_URL;

const callApi = async (method, url, data = "", query = "") => {
  if (!url || !method) return;
  const token = getToken();
  const request = {
    url: `${BFF_URL}${url}`,
    method,
  };

  if (token) {
    request.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  if (query) {
    request.params = query;
  }
  if (data) {
    request.data = data;
  }

  return axios(request);
};

export function loginUser(data) {
  return callApi(methods.POST, "login", data)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return error.response.data;
      }
    });
}

export function logoutUser(token) {
  return callApi(methods.POST, "logout", { token: token })
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return error.response.data;
      }
    });
}

export async function createClient(data) {
  try {
    const res = await callApi(methods.POST, "clients/create", data);
    return res;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
}

export function getUserData(userId) {
  return callApi(methods.GET, `users/${userId}`);
}

export function getAdmins() {
  return callApi(methods.GET, `clients/admins/all`);
}

export function getSubAdmins() {
  return callApi(methods.GET, `clients/subadmins/all`);
}

export function deleteClient(clientId) {
  return callApi(methods.DELETE, `clients/${clientId}`);
}

export function getDriverGroups() {
  return callApi(methods.GET, `driver-groups/all`);
}

export function getDriverGroupHeaders() {
  return callApi(methods.GET, `driver-groups/all/header`);
}

export function getDriverGroupHeadersFull() {
  return callApi(methods.GET, `driver-groups/all/header-full`);
}

export function deleteDriverGroup(driverGroupId) {
  return callApi(methods.DELETE, `driver-groups/${driverGroupId}`);
}

export function getDriverGroup(id) {
  return callApi(methods.GET, `driver-groups/${id}`);
}

export async function saveDriverGroup(data) {
  let result;
  if (data.id) {
    result = callApi(methods.PATCH, "driver-groups/update", data);
  } else {
    result = callApi(methods.POST, "driver-groups/create", data);
  }

  try {
    const res = await result;
    return res;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
}

export function getParkingLots() {
  return callApi(methods.GET, `parking-lots/all`);
}

export function getParkingLotsForSharing() {
  return callApi(methods.GET, `parking-lots/all/sharing`);
}

export function deleteParkingLot(parkingLotId) {
  return callApi(methods.DELETE, `parking-lots/${parkingLotId}`);
}

export function getParkingLot(id) {
  return callApi(methods.GET, `parking-lots/${id}`);
}

export function getParkingLotOccupancyCount(id) {
  return callApi(methods.GET, `occupancy/current/${id}`);
}

export function getParkingLotOccupancyData(where, limit, skip) {
  return callApi(methods.GET, `occupancy`, "", {
    filter: {
      where,
      limit,
      skip,
    },
  });
}

export function getParkingLotOccupancyDataCount(where) {
  return callApi(methods.GET, `occupancy/count`, "", {
    filter: {
      where,
    },
  });
}

export function getParkingLotReservationData(where) {
  return callApi(methods.GET, `reservations/all`, "", {
    filter: {
      where,
    },
  });
}

export function getParkingLotPartitioningData(id) {
  return callApi(methods.GET, `occupancy/partitioning/${id}`);
}

export async function saveParkingLot(data) {
  let result;
  if (data.id) {
    result = callApi(methods.PATCH, "parking-lots/update", data);
  } else {
    result = callApi(methods.POST, "parking-lots/create", data);
  }

  try {
    const res = await result;
    return res;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
}

export function getDrivers() {
  return callApi(methods.GET, `drivers/all`);
}

export function deleteDriver(id) {
  return callApi(methods.DELETE, `drivers/${id}`);
}

export function getDriver(id) {
  return callApi(methods.GET, `drivers/${id}`);
}

export async function saveDriver(data) {
  let result;
  if (data.driver.id) {
    result = callApi(methods.PATCH, "drivers/update", data);
  } else {
    result = callApi(methods.POST, "drivers/create", data);
  }

  try {
    const res = await result;
    return res;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
}

export function getInvoices(cId) {
  return callApi(methods.GET, `invoices/all`, "", {
    filter: {
      where: {
        clientId: cId,
      },
    },
  });
}

export function getPayments(cId) {
  return callApi(methods.GET, `invoices/payments/all`);
}

export function getInvoiceClients() {
  return callApi(methods.GET, `invoices/clients`);
}

export function deleteInvoice(id) {
  return callApi(methods.DELETE, `invoices/${id}`);
}

export function getInvoice(id) {
  return callApi(methods.GET, `invoices/${id}`);
}

export async function saveInvoice(data) {
  let result;
  if (data.id) {
    result = callApi(methods.PATCH, "invoices/update", data);
  } else {
    result = callApi(methods.POST, "invoices/create", data);
  }

  try {
    const res = await result;
    return res;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
}

export function getClientHeaders() {
  return callApi(methods.GET, `clients/all/header`);
}

export function getParkingLotSummaryRecords() {
  return callApi(methods.GET, `parking-lots/summary`);
}

export async function saveReservation(data) {
  let result;
  if (data.id) {
    result = callApi(methods.PATCH, "reservations/update", data);
  } else {
    result = callApi(methods.POST, "reservations/create", data);
  }

  try {
    const res = await result;
    return res;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
}

export function getReservations(filter = null) {
  if (filter != null) {
    return getParkingLotReservationData(filter);
  }
  return callApi(methods.GET, `reservations/all`);
}

export function deleteReservation(id) {
  alert(`Assume reservation has been deleted with ID=${id}.`);
  return Promise.resolve(null);
  // return callApi(methods.DELETE, `reservations/${id}`);
}

export function getReservation(id) {
  return callApi(methods.GET, `reservations/${id}`);
}

export function cancelReservation(id) {
  alert(`Assume reservation has been canceled with ID=${id}.`);
  return Promise.resolve(null);
}

export function getParkingLotsAvailable() {
  return callApi(methods.GET, `/driver-parking/parking-lots-available`);
}

export function getParkingLotsAvailableForReservation() {
  return callApi(
    methods.GET,
    `/driver-parking/parking-lots-available-for-reservation`
  );
}

export function getCurrentUserDriverData() {
  return callApi(methods.GET, `drivers/me`);
}

export function getCurrentUserDriverGroupData() {
  return callApi(methods.GET, `drivers/my-group`);
}

export function getCurrentUserReservationSettings() {
  return getCurrentUserDriverData().then((res) => {
    if (res.data && res.data.driver) {
      return callApi(
        methods.GET,
        `/reservation-settings/user/${res.data.driver.userId}`
      );
    }

    return null;
  });
}

export function getReservationSettings() {
  return callApi(methods.GET, `reservation-settings/fetch`);
}

export async function saveReservationSettings(data) {
  const result = callApi(methods.PUT, "reservation-settings/save", data);

  try {
    const res = await result;
    return res;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
}

export function getClient(id) {
  return callApi(methods.GET, `clients/${id}`);
}

export function getGateData() {
  return callApi(methods.GET, `gate-state/all`);
}

export function getGateDataItem(id) {
  return callApi(methods.GET, `gate-state/${id}`);
}

export function saveGateData(data) {
  return callApi(methods.POST, "gate-state/save", data);
}

export function deleteGateStateData(id) {
  return callApi(methods.DELETE, `gate-state/${id}`);
}

export async function saveClient(data) {
  let result;
  if (data.client.id) {
    result = callApi(methods.PATCH, "clients/update", data);
  } else {
    result = callApi(methods.POST, "clients/create", data);
  }

  try {
    const res = await result;
    return res;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
}

export function processInvoice(nonce, invoiceId) {
  return callApi(methods.POST, "payments/process-invoice", {
    invoiceId: invoiceId,
    paymentNonce: nonce,
  });
}

export function getUserDriverPaymentSettings(userId) {
  return callApi(
    methods.GET,
    `/payment-settings/user/${userId}`
  );
}

export function getDriverPaymentSettings() {
  return callApi(methods.GET, `payment-settings/fetch`);
}

export async function saveDriverPaymentSettings(data) {
  const result = callApi(methods.PUT, "payment-settings/save", data);

  try {
    const res = await result;
    return res;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
}

export function getUserClientPaymentSettings(userId) {
  return callApi(methods.GET, `/client-payment-settings/user/${userId}`);
}

export function getClientPaymentSettings() {
  return callApi(methods.GET, `client-payment-settings/fetch`);
}

export async function saveClientPaymentSettings(data) {
  const result = callApi(methods.PUT, "client-payment-settings/save", data);

  try {
    const res = await result;
    return res;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
}