import React from "react";
import { find, filter, remove } from "lodash";
import "./AccessTable.scss";

function AccessTable(props) {
  const [restrictions, onChanged, enabled] = [
    props.restrictions || [],
    props.onChanged,
    props.enabled,
  ];

  const processHour = (event, day, hour) => {
    if (isRestricted(day, hour)) {
      event.target.classList.remove("restricted");
      event.target.classList.add("allowed");

      const data = find(restrictions, (x) => x.weekDay == day);
      remove(data.hoursRestricted, (x) => x == hour);
      remove(restrictions, (x) => x.weekDay == day);
      if (data.hoursRestricted.length > 0) {
        restrictions.push(data);
      }
    } else {
      event.target.classList.remove("allowed");
      event.target.classList.add("restricted");

      const data = find(restrictions, (x) => x.weekDay == day);
      if (data) {
        data.hoursRestricted.push(hour);
        remove(restrictions, (x) => x.weekDay == day);
        restrictions.push(data);
      } else {
        restrictions.push({
          weekDay: day,
          hoursRestricted: [hour],
        });
      }
    }

    onChanged(restrictions);
  };

  const isRestricted = (day, hour) => {
    return (
      filter(
        restrictions,
        (x) => x.weekDay == day && x.hoursRestricted.includes(hour)
      ).length > 0
    );
  };

  const dayMappings = {
    mo: "Monday",
    tu: "Tuesday",
    we: "Wednesday",
    th: "Thursday",
    fr: "Friday",
    sa: "Saturday",
    su: "Sunday",
  };

  const weekDays = ["mo", "tu", "we", "th", "fr", "sa", "su"].map((day) => {
    const dayName = dayMappings[day];
    const daysTillNoon = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((hour) => (
      <td
        onClick={(event) => processHour(event, day, hour)}
        className={isRestricted(day, hour) ? "restricted" : "allowed"}
      >
        {hour == 0 ? 12 : hour}AM
      </td>
    ));
    const daysAfterNoon = [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(
      (hour) => (
        <td
          onClick={(event) => processHour(event, day, hour)}
          className={isRestricted(day, hour) ? "restricted" : "allowed"}
        >
          {hour == 12 ? hour : hour - 12}PM
        </td>
      )
    );

    return (
      <>
        <tr>
          <th scope="row" rowSpan="2">
            {dayName}
          </th>
          {daysTillNoon}
        </tr>
        <tr>{daysAfterNoon}</tr>
      </>
    );
  });

  return (
    <div className="access-table">
      {!enabled && (
        <p>
          Configuration matrix is disabled. You have to add/select date for
          configuration. Or select default mode again.
        </p>
      )}
      <table className={`table ${enabled ? "enabled" : "disabled"}`}>
        <thead>
          <tr>
            <th scope="col">Weekday</th>
            <th scope="col" colSpan="12">
              Hours allowed
            </th>
          </tr>
        </thead>
        <tbody>{weekDays}</tbody>
      </table>
    </div>
  );
}

export default AccessTable;
