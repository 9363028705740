import React from "react";
import { withRouter } from "react-router-dom";
import Header from "../../../components/header/Header";
import Sidebar from "../../../components/sidebar/Sidebar";
import Tabs from "../../../components/tabs/Tabs";
import ReservationSettingsForm from "./ReservationSettingsForm";

function ReservationSettingsPage(props) {
  const tabs = (
    <Tabs
      currentTabNumber={0}
      tabsCanBeChangedManually={false}
      tabs={[
        {
          title: "Reservation Settings",
          body: <ReservationSettingsForm history={props.history} />,
        },
      ]}
    />
  );

  return (
    <>
      <Header />
      <div className="content-page">
        <div class="container-fluid">
          <div class="row">
            <Sidebar />
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10">
              {tabs}
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(ReservationSettingsPage);
