import React, { useState, useEffect } from "react";
import { deleteGateStateData, getGateData } from "../../utilities/apiUtils";
import Table from "../../components/table/Table";
import "./GateManagementListPage.scss";
import { paths } from "../../utilities/constants";
import { withRouter } from "react-router-dom";
import SecondaryButton from "../../components/secondaryButton/SecondaryButton";
import ActionButton from "../../components/actionButton/ActionButton";
import "./GateManagementListPage.scss";

function GateManagementListPage(props) {
  const [gateData, setGateData] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    loadGateData();
  }, []);

  const loadGateData = () => {
    setPageLoading(true);
    getGateData().then((res) => {
      if (res.data) {
        setGateData(res.data);
        setPageLoading(false);
      }
    });
  };

  const manageControlUnitState = (controlUnitId) => {
    props.history.push(
      `${paths.accessManagementPath}/gate-management/${controlUnitId}`
    );
  };

  const resetState = (controlUnitId) => {
    deleteGateStateData(controlUnitId).then(() => {
      window.location.reload();
    });
  };

  const getControlUnitRow = (item) => {
    return (
      <>
        <tr key={item.controlUnit.id}>
          <td>{item.controlUnit.id}</td>
          <td>{item.controlUnit.name}</td>
          <td>{item.comments == "" ? "-" : item.comments}</td>
          <td>
            {item.direction == "" ? (
              <ActionButton
                onClick={() => manageControlUnitState(item.controlUnit.id)}
                value="Open the Gate"
              />
            ) : (
              <SecondaryButton
                variant="secondary"
                onClick={() => resetState(item.controlUnit.id)}
                value="Close the Gate"
              />
            )}
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <strong>Parking lots</strong>
            <br />
            {item.parkingLots.map((item) => (
              <span className="lot-name">{item.name}</span>
            ))}
            <br />
            <br />
          </td>
        </tr>
      </>
    );
  };

  return (
    <>
      {pageLoading ? (
        <div class="alert alert-primary">Loading.</div>
      ) : (
        <>
          {gateData && !gateData.length && (
            <div class="alert alert-primary">
              There are no parking lot control units that you can access.
            </div>
          )}
          {gateData && !!gateData.length && (
            <>
              <Table
                headers={[
                  <th>ID</th>,
                  <th>Name</th>,
                  <th>Comments</th>,
                  <th>Actions</th>,
                ]}
                generateRow={getControlUnitRow}
                items={gateData}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export default withRouter(GateManagementListPage);
