import React from "react";
import { Modal } from "react-bootstrap";

function CommonModal(props) {
  const { show, onClose, body, title, footer } = props;
  return (
    <Modal show={show} animation={false} onHide={onClose}>
      <Modal.Header closeButton>
        {!!title && <Modal.Title>{title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  );
}

export default CommonModal;
