import React from "react";
import { withRouter } from "react-router-dom";
import DriverDetailsForm from "./DriverDetailsForm";
import DriverLicensePlateAliasesForm from "./DriverLicensePlateAliasesForm";
import DriverFinalStepForm from "./DriverFinalStepForm";
import FormTabs from "../genericModifyPage/FormTabs";

function DriverForm(props) {
  const [item, onChanged, onSave, onCancel] = [
    props.item,
    props.onChanged,
    props.onSave,
    props.onCancel,
  ];

  return (
    <FormTabs
      item={item}
      onChanged={onChanged}
      onSave={onSave}
      onCancel={onCancel}
      history={props.history}
      pageProps={props.pageProps}
      tabs={[
        {
          title: "Details",
          body: <DriverDetailsForm />,
        },
        {
          title: "License plate aliases",
          body: <DriverLicensePlateAliasesForm />,
        },
        {
          title: "Final step",
          body: <DriverFinalStepForm />,
        },
      ]}
    />
  );
}

export default withRouter(DriverForm);
