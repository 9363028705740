import React, { useEffect, useState } from "react";
import PayPalImage from "../../assets/images/paypal.svg";
import CreditCardImage from "../../assets/images/card.svg";
import MCImage from "../../assets/images/mc.svg";
import AmexImage from "../../assets/images/amex.svg";
import VisaImage from "../../assets/images/visa.svg";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import CreditCardForm from "./CreditCardForm";
import braintree from "braintree-web";
import "./BraintreeDropIn.scss";
import { processPayment } from "./Util";

const Options = {
  list: "list",
  payPal: "paypal",
  creditCard: "credit-card",
};

export default function BraintreeDropIn(props) {
  const [type, entityId, history] = [props.type, props.entityId, props.history];
  const [option, setOption] = useState(Options.creditCard);
  const [error, setError] = useState("");
  const [paymentInProgress, setPaymentInProgress] = useState(false);

  useEffect(() => {
    const paypalButton = document.querySelector(".paypal-button");

    braintree.client.create(
      {
        authorization: "sandbox_6m2cjghn_7s4bt2qwq9pjyqxx",
      },
      function (clientErr, clientInstance) {
        if (clientErr) {
          return;
        }

        braintree.paypal.create(
          {
            client: clientInstance,
          },
          function (paypalErr, paypalInstance) {
            if (paypalErr) {
              console.error("Error creating PayPal:", paypalErr);
              return;
            }

            paypalButton.removeAttribute("disabled");

            paypalButton.addEventListener(
              "click",
              function (event) {
                setPaymentInProgress(true);
                paypalInstance.tokenize(
                  {
                    flow: "vault",
                  },
                  function (tokenizeErr, payload) {
                    if (tokenizeErr) {
                      setPaymentInProgress(false);
                      if (tokenizeErr.type !== "CUSTOMER") {
                        console.error("Error tokenizing:", tokenizeErr);
                      }
                      return;
                    }

                    paypalButton.setAttribute("disabled", true);
                    console.log(payload);
                    processPayment(
                      type,
                      entityId,
                      payload.nonce,
                      props.history,
                      setError,
                      setPaymentInProgress
                    );
                  }
                );
              },
              false
            );
          }
        );
      }
    );
  }, [option]);

  return (
    <div class="payment-form">
      {paymentInProgress && (
        <div class="alert alert-success" role="alert">
          Processing payment.
        </div>
      )}
      {error != "" && (
        <div class="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      <div className="payment-row">
        <div
          className={`form-wrapper ${
            option == Options.creditCard ? "not-active" : ""
          }`}
          onClick={() => setOption(Options.payPal)}
        >
          <div className="form-check">
            <input
              class="form-check-input"
              type="radio"
              checked={!!(option == Options.payPal)}
            />
            <img
              alt="PayPal"
              title="PayPal"
              src={PayPalImage}
              className="type"
            />
            <span class="form-check-label">Paypal</span>
          </div>

          {option == Options.payPal && (
            <PrimaryButton
              value="Pay with PayPal"
              className={"payment-method-selection-item paypal-button"}
              svg={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-paypal"
                  viewBox="0 0 16 16"
                >
                  <path d="M14.06 3.713c.12-1.071-.093-1.832-.702-2.526C12.628.356 11.312 0 9.626 0H4.734a.7.7 0 0 0-.691.59L2.005 13.509a.42.42 0 0 0 .415.486h2.756l-.202 1.28a.628.628 0 0 0 .62.726H8.14c.429 0 .793-.31.862-.731l.025-.13.48-3.043.03-.164.001-.007a.351.351 0 0 1 .348-.297h.38c1.266 0 2.425-.256 3.345-.91.379-.27.712-.603.993-1.005a4.942 4.942 0 0 0 .88-2.195c.242-1.246.13-2.356-.57-3.154a2.687 2.687 0 0 0-.76-.59l-.094-.061ZM6.543 8.82a.695.695 0 0 1 .321-.079H8.3c2.82 0 5.027-1.144 5.672-4.456l.003-.016c.217.124.4.27.548.438.546.623.679 1.535.45 2.71-.272 1.397-.866 2.307-1.663 2.874-.802.57-1.842.815-3.043.815h-.38a.873.873 0 0 0-.863.734l-.03.164-.48 3.043-.024.13-.001.004a.352.352 0 0 1-.348.296H5.595a.106.106 0 0 1-.105-.123l.208-1.32.845-5.214Z"></path>
                </svg>
              }
              disabled={true}
              id={"submit-credit-card"}
            />
          )}
        </div>

        <div
          className={`form-wrapper ${
            option == Options.payPal ? "not-active" : ""
          }`}
          onClick={() => setOption(Options.creditCard)}
        >
          <div className="form-check">
            <input
              class="form-check-input"
              type="radio"
              checked={!!(option == Options.creditCard)}
            />
            <img
              alt="Credit Card"
              title="Credit Card"
              src={CreditCardImage}
              className="type"
            />
            <span class="form-check-label">Credit card</span>
            <div className="cards">
              <div className="cards-container">
                <img src={AmexImage} />
                <img src={MCImage} />
                <img src={VisaImage} />
              </div>
            </div>
          </div>
          <CreditCardForm
            visible={option == Options.creditCard}
            type={type}
            entityId={entityId}
            history={history}
          />
        </div>
      </div>
    </div>
  );
}
