import React, { useState, useEffect } from "react";

import "./Tabs.scss";

function Tabs(props) {
  const [currentTab, setCurrentTab] = useState(0);

  const tabs = props.tabs;
  const tabsCanBeChangedManually =
    props.tabsCanBeChangedManually != undefined
      ? props.tabsCanBeChangedManually
      : true;

  const changeTab = (key) => {
    if (tabsCanBeChangedManually) {
      setCurrentTab(key);
      if (props.onTabChanged) {
        props.onTabChanged(key);
      }
    }
  };

  useEffect(() => {
    if (props.currentTabNumber != undefined) {
      setCurrentTab(props.currentTabNumber);
    }
  }, [props.currentTabNumber]);

  return (
    <div className="tabs-container">
      {tabs.length > 1 && (
        <div className="tab-menus row justify-content-center">
          {tabs.map((tab, key) => (
            <div
              onClick={() => changeTab(key)}
              className={`menu-item ${!!(key === currentTab) && "active"}`}
            >
              <span className="tabs-title">{tab.title}</span>
            </div>
          ))}
        </div>
      )}
      <div
        className={"tabs-body" + (props.className ? ` ${props.className}` : "")}
      >
        {tabs[currentTab] && tabs[currentTab].body}
      </div>
    </div>
  );
}

export default Tabs;
