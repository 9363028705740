import React from "react";
import SecondaryInputField from "../../components/secondaryInputField/SecondaryInputField";

function ClientParkingLotsConfigurationForm(props) {
  const [parkingLotsSelected, onChanged, lotConfigErrors] = [
    props.parkingLotsSelected,
    props.onChanged,
    props.lotConfigErrors,
  ];

  return (
    <>
      {!!parkingLotsSelected.length && (
        <>
          <strong>Selected:</strong>
          <br />
          {parkingLotsSelected.map((parkingLot) => (
            <>
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <div style={{ color: "#7c9db1" }}>{parkingLot.name}</div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">
                      Specify number of parking spaces*:{" "}
                    </label>
                    <div class="col-sm-9">
                      <SecondaryInputField
                        type="number"
                        onChange={(event) => {
                          onChanged(parkingLot, event.target.value);
                        }}
                        value={parkingLot.parkingSpaces}
                        placeholder="Number of parking spaces..."
                      />
                    </div>
                  </div>
                  {lotConfigErrors && lotConfigErrors[parkingLot.id] && (
                    <div style={{ display: "block" }} class="invalid-feedback">
                      {lotConfigErrors[parkingLot.id]}
                    </div>
                  )}
                </div>
              </div>
              <br />
            </>
          ))}
        </>
      )}
    </>
  );
}

export default ClientParkingLotsConfigurationForm;
