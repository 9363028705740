import React, { useEffect, useState } from "react";
import { getParkingLotReservationData } from "../../utilities/apiUtils";
import {
  getUserName,
  getRegistrationTypeName,
  getUserRoleName,
  getAccessTypeName,
} from "../../utilities/itemUtils";
import "./ParkingLotsReservationsTab.scss";
import "../../styles/DataTable.scss";
import {
  formatDatabaseTime,
  formatDateTime,
  getDateFromDatabaseTime,
} from "../../utilities/timeUtils";
import Table from "../../components/table/Table";

function ParkingLotsReservationsTab(props) {
  const [reservationData, setReservationData] = useState([]);

  useEffect(() => {
    getParkingLotReservationData({
      parkingLotId: props.parkingLotId,
    }).then((result) => {
      if (result.data) {
        setReservationData(result.data);
      }
    });
  }, []);

  const getRow = (item) => {
    return (
      <tr>
        <th>{getUserName(item.user)}</th>
        <th>{getUserRoleName(item.user.userRole)}</th>
        <th>{item.licensePlateNumber != "" ? item.licensePlateNumber : "-"}</th>
        <th>{formatDatabaseTime(item.entryTime)}</th>
        <th>
          {item.durationMinutes > 0
            ? formatDateTime(
                new Date(
                  new Date(getDateFromDatabaseTime(item.entryTime)).getTime() +
                    item.durationMinutes * 60000
                )
              )
            : "-"}
        </th>
        <th>{getRegistrationTypeName(item.user)}</th>
        <th>
          {item.user.driverGroup.name != "" ? item.user.driverGroup.name : "-"}
        </th>
      </tr>
    );
  };

  return (
    <div>
      {reservationData.length == 0 ? (
        <p>
          There is no reservation data right now regarding this parking lot.
        </p>
      ) : (
        <Table
          headers={[
            <th>Name</th>,
            <th>Role</th>,
            <th>License plate number</th>,
            <th>Entry time</th>,
            <th>Exit time</th>,
            <th>Registration type</th>,
            <th>Driver group</th>,
          ]}
          generateRow={getRow}
          items={reservationData}
        />
      )}
    </div>
  );
}

export default ParkingLotsReservationsTab;
