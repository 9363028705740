import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import CommonModal from "../../components/commonModal/CommonModal";
import { Button } from "@material-ui/core";
import SpinnerImage from "../../assets/images/spinner.gif";

function GenericModifyPage(props) {
  const [item, setItem] = useState({});
  const [title, setTitle] = useState("");
  const [cancelModalShown, setCancelModalShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, getTitle, fetch, save, indexPage] = [
    props.id,
    props.getTitle,
    props.fetch,
    props.save,
    props.indexPage,
  ];

  useEffect(() => {
    setLoading(id != "new");
    fetch(id).then((res) => {
      if (res.data) {
        setItem(res.data);
        setTitle(getTitle(res.data));
      }
      
      if (id != "new") {
        setLoading(false);
      }
    });
  }, []);

  const triggerOnChanged = (item) => {
    setItem(item);
    console.log(item);
  };

  const triggerOnSave = (item) => {
    return save(item);
  };

  const processCancel = () => {
    setCancelModalShown(true);
  };

  const rejectCancel = () => {
    setCancelModalShown(false);
  };

  const cancelChanges = () => {
    props.history.push(indexPage(item, props.pageProps));
  };

  const ItemForm = React.cloneElement(props.itemForm, {
    item: item,
    onChanged: (item) => triggerOnChanged(item),
    onSave: (item) => triggerOnSave(item),
    onSave: (item) => triggerOnSave(item),
    onCancel: () => processCancel(),
    pageProps: props.pageProps,
  });

  return (
    <>
      <Header />
      <CommonModal
        show={cancelModalShown}
        onClose={rejectCancel}
        body="Are you sure you want to cancel changes?"
        title="Cancel changes?"
        footer={
          <div>
            <Button variant="secondary" onClick={() => rejectCancel()}>
              No
            </Button>
            <Button variant="primary" onClick={() => cancelChanges()}>
              Yes
            </Button>
          </div>
        }
      />

      <div className="content-page">
        <div class="container-fluid">
          <div class="row">
            <Sidebar />
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10">
              { loading && <div class="alert alert-primary"><img src={SpinnerImage} className="spinner-image" /> Loading...</div> }
              {!loading && ItemForm}
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(GenericModifyPage);
