import React, { useState, useEffect } from "react";
import { clientPaymentStrategies, paymentStrategies } from "../../utilities/constants";
import SecondaryButton from "../secondaryButton/SecondaryButton";
import SecondaryInputField from "../secondaryInputField/SecondaryInputField";
import { isNumeric, countDecimals } from "../../utilities/numberUtils";
import { priceIsValid, revenuePercentageValid } from "./PaymentsConfigUtil";

function ClientPaymentConfigurationForm(props) {
  const [onChanged, validationCounter] = [props.onChanged, props.validationCounter];
  const [settings, setSettings] = useState(props.settings || {});
  const [paymentPeriod, setPaymentPeriod] = useState(settings.strategy || paymentStrategies.Free);
  const [pricePerPeriodErrorText, setPricePerPeriodErrorText] = useState("");
  const [priceRevenuePercentErrorText, setPriceRevenuePercentErrorText] = useState("");
  const [price, setPrice] = useState(settings.price || {});
  const [revenuePercent, setRevenuePercent] = useState(settings.revenuePercent || "");

  useEffect(() => {
    if (validationCounter < 1) {
      return;
    }

    const priceValue = price.amount;
    if (!isNumeric(priceValue)) {
      setPricePerPeriodErrorText("Price per period value must be a number.");
    } else if (parseFloat(priceValue) <= 0) {
      setPricePerPeriodErrorText("Price per period value must be greater than zero.");
    } else if (countDecimals(priceValue) > 2) {
      setPricePerPeriodErrorText("There can be no more than to digits after decimal.");
    } else {
      setPricePerPeriodErrorText("");
    }

    if (!isNumeric(revenuePercent)) {
      setPriceRevenuePercentErrorText("Percent value must be a number.");
    } else if (parseFloat(revenuePercent) <= 0) {
      setPriceRevenuePercentErrorText("Percent value must be greater than zero.");
    } else if (parseFloat(revenuePercent) > 100) {
      setPriceRevenuePercentErrorText("Percent value must be less or equal to 100.");
    } else {
      setPriceRevenuePercentErrorText("");
    }
  }, [validationCounter]);

  useEffect(() => {
    setSettings(props.settings);
  }, [props.settings]);

  const changePaymentPeriod = (value) => {
    const priceValid = priceIsValid(settings.price.amount) || value == paymentStrategies.Free;
    const revenueValid = revenuePercentageValid(settings.revenuePercent) || value != paymentStrategies.FlatFeePlusRevenue;
    const isValid = priceValid && revenueValid;

    settings.strategy = value;
    setSettings(settings);
    setPaymentPeriod(value);
    onChanged(settings, isValid);
  };

  const processPricePerPeriodChange = (value) => {
    const priceValid = priceIsValid(value);
    const revenueValid = revenuePercentageValid(settings.revenuePercent) || value != paymentStrategies.FlatFeePlusRevenue;
    const isValid = priceValid && revenueValid;
    
    const price = {
      amount: value,
      currency: 'USD',
    };
    settings.price = price;
    setSettings(settings);
    setPrice(price);
    onChanged(settings, isValid);
  };

  const processRevenuePercentChange = (value) => {
    const priceValid = priceIsValid(settings.price.amount) || settings.strategy == paymentStrategies.Free;
    const revenueValid = revenuePercentageValid(value) || settings.strategy != paymentStrategies.FlatFeePlusRevenue;
    const isValid = priceValid && revenueValid;
    
    settings.revenuePercent = value;
    setSettings(settings);
    setRevenuePercent(value);
    onChanged(settings, isValid);
  };

  return (
    <>
      <div className="form-group">
        <label>Monthly payment strategy</label>
        <div className="row">
          {
            clientPaymentStrategies.map((value) => <SecondaryButton
            onClick={() => {
              changePaymentPeriod(value.key);
            }}
            className={paymentPeriod === value.key && "active"}
            value={value.name}
          />)
          }
        </div>
      </div>
      {
        paymentPeriod != paymentStrategies.Free &&
        <div className="form-group">
          <label>Price ($)</label>
          <SecondaryInputField
            value={price.amount}
            onChange={(event) => processPricePerPeriodChange(event.target.value)}
            className={`form-control ${pricePerPeriodErrorText != "" ? "is-invalid" : ""}`}
          />
          {pricePerPeriodErrorText != "" && <div style={{ display: "block" }} className="invalid-feedback">{pricePerPeriodErrorText}</div>}
        </div>
      }
      {
        paymentPeriod == paymentStrategies.FlatFeePlusRevenue &&
        <div className="form-group">
          <label>Revenue percentage (from 0 to 100)</label>
          <SecondaryInputField
            value={revenuePercent}
            onChange={(event) => processRevenuePercentChange(event.target.value)}
            className={`form-control ${priceRevenuePercentErrorText != "" ? "is-invalid" : ""}`}
          />
          {priceRevenuePercentErrorText != "" && <div style={{ display: "block" }} className="invalid-feedback">{priceRevenuePercentErrorText}</div>}
        </div>
      }
    </>
  );
}

export default ClientPaymentConfigurationForm;
