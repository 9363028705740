import { getInvoice, saveInvoice } from "../../utilities/apiUtils";
import { paths } from "../../utilities/constants";
import InvoiceForm from "./InvoiceForm";

export const InvoiceCrudConfig = {
  getTitle: (item) => {
    if (!item || !item.id) {
      return "Create new invoice";
    }

    return `Edit invoice "${item.invoiceNUmber}"`;
  },

  fetch: (id) => {
    return getInvoice(id);
  },

  save: (item) => {
    return saveInvoice(item);
  },

  indexPage: (item, pageProps) => {
    if (pageProps != undefined) {
      const selectedCompanyId = pageProps.match.params.company;
      if (selectedCompanyId == undefined && item.clientId) {
        return `${paths.revenueanagementPath}/invoices/client/${item.clientId}`;
      }

      return `${paths.revenueanagementPath}/invoices/client/${selectedCompanyId}`;
    }

    if (item.clientId) {
      return `${paths.revenueanagementPath}/invoices/client/${item.clientId}`;
    }

    return `${paths.revenueanagementPath}/invoices`;
  },

  itemForm: <InvoiceForm />,
};
