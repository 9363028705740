import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { paths } from "../../utilities/constants";
import { deleteInvoice, saveInvoice } from "../../utilities/apiUtils";
import CommonModal from "../../components/commonModal/CommonModal";
import { Button } from "react-bootstrap";

import "./InvoicesPage.scss";
import { getInvoiceStatus, getMoney } from "../../utilities/itemUtils";
import { formatDatabaseCalendarDate } from "../../utilities/timeUtils";
import Table from "../../components/table/Table";
import ViewImage from "../../assets/images/view.svg";
import DeleteImage from "../../assets/images/delete.svg";
import EditImage from "../../assets/images/edit.svg";
import PayImage from "../../assets/images/pay.png";
import ActionButton from "../../components/actionButton/ActionButton";
import SecondaryButton from "../../components/secondaryButton/SecondaryButton";

function InvoicesContainer(props) {
  const [loadInvoices, invoices, isRestricted] = [
    props.invoicesLoader,
    props.invoices,
    props.isRestricted || false,
  ];
  const [invoiceToBeDeleted, setInvoiceToBeDeleted] = useState(null);
  const [invoiceToBePaid, setInvoiceToBePaid] = useState(null);

  const handleInvoiceDelete = () => {
    deleteInvoice(invoiceToBeDeleted).then(() => {
      toggleDeleteModal();
      loadInvoices();
    });
  };

  const toggleDeleteModal = (id = null) => {
    setInvoiceToBeDeleted(id);
  };

  const toggleViewDetails = (id = null) => {
    props.history.push(`${paths.revenueanagementPath}/invoices/details/${id}`);
  };

  const toggleEdit = (id = null) => {
    props.history.push(`${paths.revenueanagementPath}/invoices/modify/${id}`);
  };

  const changePaidStatus = (invoice) => {
    invoice.paid = !invoice.paid;
    saveInvoice(invoice).then((res) => {
      if (res.data) {
        loadInvoices();
      }
    });
  };

  const payInvoice = (invoice) => {
    setInvoiceToBePaid(invoice);
  };

  const togglePaymentModal = () => {
    setInvoiceToBePaid(null);
  };

  const getRestrictedInvoiceRow = (invoice) => {
    return getInvoiceRow(invoice, true);
  };

  const getFullInvoiceRow = (invoice) => {
    return getInvoiceRow(invoice, false);
  };

  const handleInvoicePayment = () => {
    if (invoiceToBePaid == null) {
      return;
    }

    props.history.push(
      `${paths.revenueanagementPath}/invoices/pay/${invoiceToBePaid.id}`
    );
  };

  const getInvoiceRow = (invoice, restricted) => {
    return (
      <tr>
        <td>{invoice.invoiceNumber}</td>
        <td>{getMoney(invoice.total)}</td>
        <td>{formatDatabaseCalendarDate(invoice.date)}</td>
        <td>{formatDatabaseCalendarDate(invoice.deadline)}</td>
        <td>
          {getInvoiceStatus(invoice)}
          <br />
          {!invoice.managed && !restricted && (
            <button
              onClick={() => {
                changePaidStatus(invoice);
              }}
              type="button"
              class="btn btn-success"
            >
              {!invoice.paid ? "Set paid" : "Set not paid"}
            </button>
          )}
        </td>
        <td>
          {restricted ? (
            <>
              <img
                alt="View"
                title="View"
                onClick={() => {
                  toggleViewDetails(invoice.id);
                }}
                src={ViewImage}
                className="action"
              />
              {!invoice.paid && (
                <img
                  alt="Pay"
                  title="Pay"
                  onClick={() => {
                    payInvoice(invoice);
                  }}
                  src={PayImage}
                  className="action"
                />
              )}
            </>
          ) : (
            <>
              <img
                alt="View"
                title="View"
                onClick={() => {
                  toggleViewDetails(invoice.id);
                }}
                src={ViewImage}
                className="action"
              />
              <img
                alt="Edit"
                title="Edit"
                onClick={() => {
                  toggleEdit(invoice.id);
                }}
                src={EditImage}
                className="action"
              />
              <img
                alt="Delete"
                title="Delete"
                onClick={() => {
                  toggleDeleteModal(invoice.id);
                }}
                src={DeleteImage}
                className="action"
              />
            </>
          )}
        </td>
      </tr>
    );
  };

  return (
    <>
      <CommonModal
        show={!!invoiceToBeDeleted}
        onClose={toggleDeleteModal}
        body="Are you sure you want to delete this invoice?"
        title="Invoice removal"
        footer={
          <div>
            <SecondaryButton
              value="Cancel"
              onClick={() => toggleDeleteModal()}
            />
            <ActionButton
              value="Delete"
              onClick={() => handleInvoiceDelete()}
            />
          </div>
        }
      />
      <CommonModal
        show={!!invoiceToBePaid}
        onClose={togglePaymentModal}
        body={
          <>
            {invoiceToBePaid?.managed ? (
              <p>Are you sure you want to pay this invoice?</p>
            ) : (
              <>
                <p>
                  Creator of the invoice added the following details for
                  payment:
                </p>
                <p>{invoiceToBePaid?.paymentDetails}</p>
              </>
            )}
          </>
        }
        title={<>Pay the invoice {invoiceToBePaid?.invoiceNumber}</>}
        footer={
          <div>
            <SecondaryButton
              value="Cancel"
              onClick={() => togglePaymentModal()}
            />
            {invoiceToBePaid?.managed && (
              <ActionButton
                value="Pay"
                onClick={() => handleInvoicePayment()}
              />
            )}
          </div>
        }
      />
      {invoices && !invoices.length && (
        <div class="alert alert-primary">
          Currently there are no invoices in the category specified.
        </div>
      )}

      {invoices && !!invoices.length && (
        <Table
          headers={[
            <th>Invoice number</th>,
            <th>Grand total</th>,
            <th>Invoice date</th>,
            <th>Deadline</th>,
            <th>Status</th>,
            <th>Actions</th>,
          ]}
          generateRow={
            isRestricted ? getRestrictedInvoiceRow : getFullInvoiceRow
          }
          items={invoices}
        />
      )}
    </>
  );
}

export default withRouter(InvoicesContainer);
