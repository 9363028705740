import { getDriverGroup, saveDriverGroup } from "../../utilities/apiUtils";
import { paths } from "../../utilities/constants";
import DriverGroupForm from "./DriverGroupForm";

export const DriverGroupCrudConfig = {
  getTitle: (item) => {
    if (!item.id) {
      return "Create new driver group";
    }

    return `Edit driver group "${item.name}"`;
  },

  fetch: (id) => {
    return getDriverGroup(id);
  },

  save: (item) => {
    return saveDriverGroup(item);
  },

  indexPage: (item) => {
    return `${paths.multiUserManagementPath}/driver-groups`;
  },

  itemForm: <DriverGroupForm />,
};
