import React, { useState, useEffect } from "react";
import SecondaryInputField from "../../components/secondaryInputField/SecondaryInputField";
import PrimaryInputField from "../../components/primaryInputField/PrimaryInputField";
import "./TextField.scss";

function TextField(props) {
  const [
    placeholder,
    validationController,
    onValueChanged,
    label,
    disabled,
    // EditForm-specific
    validationCounter,
    onValidated,
    typeOfInput,
  ] = [
    props.placeholder,
    props.validationController,
    props.onValueChanged,
    props.label,
    props.disabled,
    // EditForm-specific
    props.validationCounter,
    props.onValidated,
    props.typeOfInput,
  ];

  const [value, setValue] = useState(props.value || "");
  const [errorVisible, setErrorVisible] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    setValue(props.value || "");
  }, [props.value]);

  useEffect(() => {
    setErrorVisible(false);
    setErrorText("");

    if (!props.validationCounter || props.validationCounter <= 0) {
      return;
    }

    const result = validationController(value);
    setErrorVisible(!result.success);
    setErrorText(result.text);
    onValidated(result);
  }, [validationCounter]);

  const inputProps = {
    value: value,
    type: "text",
    className: `form-control ${!!errorVisible && "is-invalid"}`,
    onChange: (event) => {
      setValue(event.target.value);
      onValueChanged(event.target.value);
    },
    placeholder: placeholder,
  };

  return (
    <div class={`form-group ${disabled ? " disabled" : ""}`}>
      {label && <label>{label}</label>}
      {typeOfInput === "primary" ? (
        <PrimaryInputField {...inputProps} />
      ) : (
        <SecondaryInputField {...inputProps} />
      )}
      {errorVisible && <div class="invalid-feedback">{errorText}</div>}
    </div>
  );
}

export default TextField;
