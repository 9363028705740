import React, { useState } from "react";
import { paths } from "../../utilities/constants";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import { processSaveResult } from "../../utilities/crudUtils";
import { parseClientPaymentSettings } from "../../components/payments/PaymentsConfigUtil";

function ClientFinalStepForm(props) {
  const [item, onCancel, onBack, onSave, additionalProps] = [
    props.item,
    props.onCancel,
    props.onBack,
    props.onSave,
    props.additionalProps,
  ];

  const [errors, setErrors] = useState([]);
  const listPage =
    additionalProps.type == "sub-admin"
      ? `${paths.multiUserManagementPath}/sub-admins`
      : `${paths.multiUserManagementPath}/admins`;

  const handleSaveAction = () => {
    setErrors([]);
    item.shares = item.shares.map((x) => {
      x.parkingSpaces = parseInt(x.parkingSpaces);

      return x;
    });
    item.client.pricing = parseClientPaymentSettings(item.client.pricing);
    
    onSave(item).then((result) => {
      processSaveResult(result, props.history, listPage, setErrors);
    });
  };

  return (
    <div>
      {errors.length > 0 && (
        <div class="alert alert-danger" role="alert">
          <p>
            Server has returned the following errors that you must fix first!
          </p>
          <ul>
            {errors.map((x) => (
              <li>{x}</li>
            ))}
          </ul>
        </div>
      )}
      <p>
        {additionalProps.type == "sub-admin" &&
          `You have filled in all of the data that is required for the sub-admin to be saved. Now you can save the data.`}
        {additionalProps.type == "admin" &&
          `You have filled in all of the data that is required for the administrator to be saved. Now you can save the data.`}
      </p>
      <div class="action-buttons">
        <div class="row">
          <PrimaryButton onClick={() => onCancel()} value="Cancel" />
          <PrimaryButton onClick={() => onBack()} value="Back" />
          <PrimaryButton onClick={() => handleSaveAction()} value="Save" />
        </div>
      </div>
    </div>
  );
}

export default ClientFinalStepForm;
