import React, { useState } from "react";
import { set } from "lodash";
import {
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import EditForm from "../../components/field/EditForm";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";

const setPaymentData = (item) => {
  item.paymentData = {
    mobileAppPaymentsAllowed: true,
    thirdPartyAppPaymentsAllowed: true,
    invoicePaymentsAllowed: true,
  };
};

function DriverGroupPricingForm(props) {
  const [item, onChanged, onCancel, onNext, onBack] = [
    props.item,
    props.onChanged,
    props.onCancel,
    props.onNext,
    props.onBack,
  ];

  if (item.paymentData == undefined) {
    setPaymentData(item);
    onChanged(item);
  }

  const [mobileAppPaymentsAllowed, setMobileAppPaymentsAllowed] = useState(
    item.paymentData.mobileAppPaymentsAllowed
  );
  const [thirdPartyAppPaymentsAllowed, setThirdPartyAppPaymentsAllowed] =
    useState(item.paymentData.thirdPartyAppPaymentsAllowed);
  const [invoicePaymentsAllowed, setInvoicePaymentsAllowed] = useState(
    item.paymentData.invoicePaymentsAllowed
  );

  const setPaymentDataValue = (path, setter, value) => {
    setter(value);
    set(item, `paymentData.${path}`, value);
    onChanged(item);
  };

  const formComponents = [
    <FormControlLabel
      key={2}
      control={
        <Checkbox
          checked={mobileAppPaymentsAllowed}
          onChange={(event) =>
            setPaymentDataValue(
              "mobileAppPaymentsAllowed",
              setMobileAppPaymentsAllowed,
              event.target.checked
            )
          }
          color="primary"
        />
      }
      label="Charging via mobile app enabled"
    />,
    <FormControlLabel
      key={3}
      control={
        <Checkbox
          checked={thirdPartyAppPaymentsAllowed}
          onChange={(event) =>
            setPaymentDataValue(
              "thirdPartyAppPaymentsAllowed",
              setThirdPartyAppPaymentsAllowed,
              event.target.checked
            )
          }
          color="primary"
        />
      }
      label="Charging via third-party apps enabled"
    />,
    <FormControlLabel
      key={4}
      control={
        <Checkbox
          checked={invoicePaymentsAllowed}
          onChange={(event) =>
            setPaymentDataValue(
              "invoicePaymentsAllowed",
              setInvoicePaymentsAllowed,
              event.target.checked
            )
          }
          color="primary"
        />
      }
      label="Charging via invoice enabled"
    />,
  ];

  const form = (
    <EditForm
      components={formComponents}
      continueAction={<PrimaryButton value="Next" />}
      cancelAction={<PrimaryButton onClick={() => onCancel()} value="Cancel" />}
      backAction={<PrimaryButton onClick={() => onBack()} value="Back" />}
      onValidated={(result) => {
        if (result) {
          onNext();
        }
      }}
    />
  );

  return <div>{form}</div>;
}

export default DriverGroupPricingForm;
