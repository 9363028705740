import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pageTitle/PageTitle";
import Sidebar from "../../components/sidebar/Sidebar";
import { getClient, getInvoice } from "../../utilities/apiUtils";
import BraintreeDropIn from "../payments/BraintreeDropIn";

import "./InvoicePaymentActionPage.scss";

function InvoicePaymentActionPage(props) {
  const { id } = props.match.params;
  const [invoice, setInvoice] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getInvoice(id).then((res) => {
      if (res.data) {
        setInvoice(res.data);
        console.log(res.data);

        getClient(res.data.clientId).then((res) => {
          if (res.data) {
            if (res.data.paid) {
              props.history.push("/revenue-management/payments");
              return;
            }

            setLoaded(true);
          }
        });
      }
    });
  }, [id]);

  return (
    <>
      <Header />

      <div className="content-page invoice-details-page">
        <div class="container-fluid">
          <div class="row">
            <Sidebar />
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10">
              <PageTitle value={"Invoice - " + invoice?.invoiceNumber} />
              <div class="card">
                {!loaded && <div class="card-body">Loading.</div>}
                {loaded && (
                  <BraintreeDropIn
                    show={true}
                    type={"invoice"}
                    entityId={id}
                    history={props.history}
                  />
                )}
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(InvoicePaymentActionPage);
