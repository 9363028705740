import React, { useContext, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { getToken } from "../../utilities/localStorageUtils";
import { login, verifyJwt } from "../../utilities/authUtils";
import { getUserInfo } from "../../utilities/userUtils";
import GlobalContext from "../../utilities/context/GlobalContext";
import { get } from "lodash";
import { sha512 } from "js-sha512";
import Tabs from "../../components/tabs/Tabs";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import PrimaryInputField from "../../components/primaryInputField/PrimaryInputField";
import LogoSvg from "../../assets/images/LogoLogin.svg";

import "./LoginPage.scss";

function LoginPage(props) {
  const context = useContext(GlobalContext);
  const token = getToken();
  if (token && verifyJwt(token)) {
    props.history.push("/dashboard");
  }

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    const userObject = {
      email,
      loginHash: sha512(password),
    };
    login(userObject).then((response) => {
      setLoading(false);
      if (response.items && response.items.length === 1) {
        setError(response.items[0].details.info);
      } else if (response.token) {
        getUserInfo().then((res) => {
          const user = get(res, "data", "");
          if (user) {
            context.updateStateValue("user", user);
            props.history.push("/dashboard");
          }
        });
      }
    });
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  const loginBody = (
    <div className="table-body">
      <div class="card-body">
        <div class="text-center">
          {error && (
            <div class="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <label>Email address</label>
          <form>
            <PrimaryInputField
              type="email"
              placeholder="Email address"
              value={email}
              onChange={(event) => {
                const trimmedEmail = (get(event, "target.value") || "").trim();
                setEmail(trimmedEmail);
              }}
              required="true"
              autofocus="true"
              onKeyPress={handleKeyPress}
              className="login-field"
            />

            <label>Password</label>

            <PrimaryInputField
              type="password"
              placeholder="Password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required="true"
              onKeyPress={handleKeyPress}
              className="login-field"
            />

            <br />

            <PrimaryButton onClick={handleLogin} value="Log in" />
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div class="login-page content-page">
        <main role="main">
          <div class="login-logo">
            <Link to="/">
              <img className="logo" alt="" src={LogoSvg} />
            </Link>
          </div>
          <Tabs
            tabs={[
              {
                title: "Log in",
                body: loginBody,
              },
            ]}
          />
        </main>
      </div>
    </>
  );
}

export default withRouter(LoginPage);
