import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Tabs from "../../components/tabs/Tabs";
import {
  getParkingLot,
  getParkingLotOccupancyCount,
} from "../../utilities/apiUtils";
import ParkingLotsBasicDetailsTab from "./ParkingLotBasicDetailsTab";

import "./ParkingLotsDetailsPage.scss";
import ParkingLotsOccupancyTab from "./ParkingLotsOccupancyTab";
import ParkingLotsPartitioningTab from "./ParkingLotsPartitioningTab";
import ParkingLotsReservationsTab from "./ParkingLotsReservationsTab";

function ParkingLotsDetailsPage(props) {
  const { id } = props.match.params;
  const [parkingLotData, setParkingLotData] = useState(null);
  const [occupancyCount, setOccupancyCount] = useState(0);

  useEffect(() => {
    getParkingLot(id)
      .then((result) => {
        if (result.data) {
          setParkingLotData(result.data);
        } else {
          props.history.goBack();
        }
      })
      .catch((_) => {
        props.history.goBack();
      });
  }, []);

  useEffect(() => {
    getParkingLotOccupancyCount(id)
      .then((result) => {
        if (result.data && result.data.count) {
          setOccupancyCount(result.data.count);
        }
      })
      .catch((_) => {});
  }, [occupancyCount]);

  return (
    <>
      <Header />

      <div className="content-page">
        <div class="container-fluid">
          <div class="row">
            <Sidebar />
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10">
              {parkingLotData && (
                <>
                  <h4>{parkingLotData.name}</h4>
                  <Tabs
                    className="parking-lots-tabs"
                    tabs={[
                      {
                        title: "Details",
                        body: (
                          <ParkingLotsBasicDetailsTab
                            parkingLotData={parkingLotData}
                            occupancyCount={occupancyCount}
                          />
                        ),
                      },
                      {
                        title: "Occupancy",
                        body: <ParkingLotsOccupancyTab parkingLotId={id} />,
                      },
                      {
                        title: "Reservations",
                        body: <ParkingLotsReservationsTab parkingLotId={id} />,
                      },
                      {
                        title: "Partitioning",
                        body: (
                          <ParkingLotsPartitioningTab
                            parkingLotId={id}
                            history={props.history}
                          />
                        ),
                      },
                    ]}
                  />
                </>
              )}
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(ParkingLotsDetailsPage);
