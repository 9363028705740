import React from "react";
import { withRouter } from "react-router-dom";
import ReservationMapForm from "./ReservationMapForm";
import ReservationDetailsForm from "./ReservationDetailsForm";
import ReservationFinalStepForm from "./ReservationFinalStepForm";
import FormTabs from "../genericModifyPage/FormTabs";

function ReservationForm(props) {
  const [item, onChanged, onSave, onCancel] = [
    props.item,
    props.onChanged,
    props.onSave,
    props.onCancel,
  ];

  return (
    <FormTabs
      item={item}
      onChanged={onChanged}
      onSave={onSave}
      onCancel={onCancel}
      history={props.history}
      pageProps={props.pageProps}
      tabs={[
        {
          title: "Parking lot",
          body: <ReservationMapForm />,
        },
        {
          title: "Details",
          body: <ReservationDetailsForm />,
        },
        {
          title: "Final step",
          body: <ReservationFinalStepForm />,
        },
      ]}
    />
  );
}

export default withRouter(ReservationForm);
