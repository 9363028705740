import "./PageTitle.scss";

export default function PageTitle(props) {
  const { value } = props;

  return (
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
      <div class="page-title-container">
        <span className="page-title">{value}</span>
      </div>
    </div>
  );
}
