import React, { useState, useEffect } from "react";
import {
  Button,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { find, remove } from "lodash";
import EditForm from "../../components/field/EditForm";
import TextField from "../../components/field/TextField";
import TextArea from "../../components/field/TextArea";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import "./DriverDetailsForm.scss";
import {
  expectNotEmpty,
  passAllways,
} from "../../components/field/validation/TextFieldUtil";
import { expectValueSet } from "../../components/field/validation/ComboBoxUtil";
import MultiTextField from "../../components/field/MultiTextField";
import { expectAtLeastOneOption } from "../../components/field/validation/MultiTextFieldUtil";
import CommonModal from "../../components/commonModal/CommonModal";
import FieldsContainer from "../../components/field/FieldsContainer";
import ComboBox from "../../components/field/ComboBox";
import SimpleDatePicker from "../../components/field/SimpleDatePicker";
import { getDriverGroupHeaders } from "../../utilities/apiUtils";
import SecondaryButton from "../../components/secondaryButton/SecondaryButton";
import ActionButton from "../../components/actionButton/ActionButton";

function DriverDetailsForm(props) {
  const [item, onChanged, onCancel, onNext] = [
    props.item,
    props.onChanged,
    props.onCancel,
    props.onNext,
  ];

  if (item.driver == undefined) {
    item.driver = {};
  }

  if (item.driver.licensePlates == undefined) {
    item.driver.licensePlates = [];
  }

  if (item.driver.company == undefined) {
    item.driver.company = {};
  }

  if (item.driver.comments == undefined) {
    item.driver.comments = "";
  }

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [licensePlateNumbers, setLicensePlateNumbers] = useState([]);
  const [licensePlateToRemove, setLicensePlateToRemove] = useState("");
  const [lpnConfirmationShown, setLpnConfirmationShown] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [taxId, setTaxId] = useState("");
  const [vatId, setVatId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [driverGroups, setDriverGroups] = useState([]);
  const [driverGroupSelected, setDriverGroupSelected] = useState({});
  const [validFrom, setValidFrom] = useState({});
  const [validTo, setValidTo] = useState({});
  const [comments, setComments] = useState("");
  const [emailFieldDisabled, setEmailFieldDisabled] = useState(false);

  useEffect(() => {
    loadDriverGroups();
  }, []);

  useEffect(() => {
    setFirstName(item.driver.firstName);
    setLastName(item.driver.lastName);
    setEmail(item.email);
    setEmailFieldDisabled(
      item.email != undefined && item.email != "" && item.driver.id != undefined
    );
    setLicensePlateNumbers(item.driver.licensePlates.map((x) => x.number));
    setCompanyName(item.driver.company.name);
    setTaxId(item.driver.company.taxId);
    setVatId(item.driver.company.vatId);
    setPhoneNumber(item.driver.company.phoneNumber);
    setAddress(item.driver.company.address);
    setPaymentDetails(item.driver.company.paymentDetails);
    setValidFrom(item.driver.validFrom || {});
    setValidTo(item.driver.validTo || {});
    setComments(item.driver.comments);

    const driverGroup = find(
      driverGroups,
      (x) => x.id == item.driver.driverGroupId
    );
    if (driverGroup) {
      setDriverGroupSelected(driverGroup);
    }
  }, [item]);

  const loadDriverGroups = () => {
    getDriverGroupHeaders().then((res) => {
      if (res.data) {
        setDriverGroups(res.data);

        const driverGroup = find(
          res.data,
          (x) => x.id == item.driver.driverGroupId
        );
        if (driverGroup) {
          setDriverGroupSelected(driverGroup);
        }
      }
    });
  };

  const changeFirstName = (value) => {
    item.driver.firstName = value;
    setFirstName(value);
    onChanged(item);
  };

  const changeLastName = (value) => {
    item.driver.lastName = value;
    setLastName(value);
    onChanged(item);
  };

  const changeEmail = (value) => {
    item.email = value;
    setEmail(value);
    onChanged(item);
  };

  const confirmLicensePlateRemove = (licensePlate) => {
    const plate = find(
      item.driver.licensePlates,
      (x) => x.number == licensePlate
    );
    if (!plate) {
      return;
    }

    if (plate.aliases.length == 0) {
      remove(item.driver.licensePlates, (x) => x.number == licensePlate);
      setLicensePlateNumbers(item.driver.licensePlates.map((x) => x.number));
      return;
    }

    setLicensePlateToRemove(licensePlate);
    setLpnConfirmationShown(true);
  };

  const addLicensePlate = (licensePlate) => {
    item.driver.licensePlates.push({
      number: licensePlate,
      aliases: [],
    });
    setLicensePlateNumbers(item.driver.licensePlates.map((x) => x.number));
    onChanged(item);
  };

  const cancelLPNRemoval = () => {
    setLicensePlateNumbers(item.driver.licensePlates.map((x) => x.number));

    setLicensePlateToRemove("");
    setLpnConfirmationShown(false);
  };

  const removeLPN = () => {
    remove(item.driver.licensePlates, (x) => x.number == licensePlateToRemove);
    setLicensePlateNumbers(item.driver.licensePlates.map((x) => x.number));

    setLicensePlateToRemove("");
    setLpnConfirmationShown(false);
    onChanged(item);
  };

  const changeCompanyName = (value) => {
    item.driver.company.name = value;
    setCompanyName(value);
    onChanged(item);
  };

  const changeTaxId = (value) => {
    item.driver.company.taxId = value;
    setTaxId(value);
    onChanged(item);
  };

  const changeVatId = (value) => {
    item.driver.company.vatId = value;
    setVatId(value);
    onChanged(item);
  };

  const changePhoneNumber = (value) => {
    item.driver.company.phoneNumber = value;
    setPhoneNumber(value);
    onChanged(item);
  };

  const changeAddress = (value) => {
    item.driver.company.address = value;
    setAddress(value);
    onChanged(item);
  };

  const changePaymentDetails = (value) => {
    item.driver.company.paymentDetails = value;
    setPaymentDetails(value);
    onChanged(item);
  };

  const changeDriverGroup = (value) => {
    item.driver.driverGroupId = value.id;
    setDriverGroupSelected(value);
    onChanged(item);
  };

  const changeValidFrom = (value) => {
    item.driver.validFrom = value;
    setValidFrom(value);
    onChanged(item);
  };

  const changeValidTo = (value) => {
    item.driver.validTo = value;
    setValidTo(value);
    onChanged(item);
  };

  const changeComments = (value) => {
    item.driver.comments = value;
    setComments(value);
    onChanged(item);
  };

  const companyFields = [
    <TextField
      key={1}
      value={companyName}
      placeholder="Enter company name"
      validationController={(value) =>
        expectNotEmpty(value, "Company name cannot be empty.")
      }
      onValueChanged={(value) => changeCompanyName(value)}
      label="Company name"
    />,
    <TextField
      key={2}
      value={taxId}
      placeholder="Enter tax ID"
      validationController={(value) =>
        expectNotEmpty(value, "Tax ID cannot be empty.")
      }
      onValueChanged={(value) => changeTaxId(value)}
      label="Tax ID"
    />,
    <TextField
      key={3}
      value={vatId}
      placeholder="Enter Vat ID"
      validationController={(value) => passAllways(value)}
      onValueChanged={(value) => changeVatId(value)}
      label="Vat ID (optional)"
    />,
    <TextField
      key={4}
      value={phoneNumber}
      placeholder="Phone number"
      validationController={(value) =>
        expectNotEmpty(value, "Phone number cannot be empty.")
      }
      onValueChanged={(value) => changePhoneNumber(value)}
      label="Enter phone number"
    />,
    <TextField
      key={5}
      value={address}
      placeholder="Enter address"
      validationController={(value) =>
        expectNotEmpty(value, "Address cannot be empty.")
      }
      onValueChanged={(value) => changeAddress(value)}
      label="Address"
    />,
    <TextField
      key={6}
      value={paymentDetails}
      placeholder="Enter payment details"
      validationController={(value) =>
        expectNotEmpty(value, "Payment details cannot be empty.")
      }
      onValueChanged={(value) => changePaymentDetails(value)}
      label="Payment details"
    />,
  ];

  const formComponents = [
    <TextField
      key={1}
      value={firstName}
      placeholder="Enter first name"
      validationController={(value) =>
        expectNotEmpty(value, "First name cannot be empty.")
      }
      onValueChanged={(value) => changeFirstName(value)}
      label="First name"
    />,
    <TextField
      key={2}
      value={lastName}
      placeholder="Enter last name"
      validationController={(value) =>
        expectNotEmpty(value, "Last name cannot be empty.")
      }
      onValueChanged={(value) => changeLastName(value)}
      label="Last name"
    />,
    <TextField
      key={3}
      value={email}
      placeholder="Enter email"
      validationController={(value) =>
        expectNotEmpty(value, "Email cannot be empty.")
      }
      onValueChanged={(value) => changeEmail(value)}
      label="Email"
      disabled={emailFieldDisabled}
    />,
    <MultiTextField
      key={4}
      options={licensePlateNumbers}
      placeholder="Enter license plate number and click add"
      validationController={(options) =>
        expectAtLeastOneOption(
          options,
          "Your must add at least one license plate number."
        )
      }
      onRemoveTriggered={(option) => confirmLicensePlateRemove(option)}
      onOptionAdded={(option) => addLicensePlate(option)}
      label="License plates"
      selectionLabel="License plates:"
      preProcessOptionAction={(value) => {
        return value.trim().replace(new RegExp(" ", "g"), "").toUpperCase();
      }}
    />,
    <FieldsContainer
      key={5}
      components={companyFields}
      label="Company details"
    />,
    <ComboBox
      key={6}
      label="Driver group"
      options={driverGroups}
      value={driverGroupSelected}
      validationController={(value) =>
        expectValueSet(value, "You must select a driver group.")
      }
      onValueChanged={(value) => changeDriverGroup(value)}
      selectionPlaceholder="None"
    />,
    <SimpleDatePicker
      key={7}
      label="Valid from"
      value={validFrom}
      validationController={(value) => {
        if (value == undefined || value.year == undefined || !value.year) {
          return {
            success: false,
            text: "You must select valid from value.",
          };
        }

        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(value) => changeValidFrom(value)}
      placeholder="Select valid from value"
    />,
    <SimpleDatePicker
      key={8}
      label="Valid to"
      value={validTo}
      validationController={(value) => {
        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(value) => changeValidTo(value)}
      placeholder="Select valid to value"
    />,
    <TextArea
      key={9}
      value={comments}
      placeholder="Enter your comments"
      validationController={(value) => {
        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(value) => changeComments(value)}
      label="Comments (optional)"
    />,
  ];

  const form = (
    <EditForm
      components={formComponents}
      continueAction={<PrimaryButton value="Next" />}
      cancelAction={<PrimaryButton onClick={() => onCancel()} value="Cancel" />}
      onValidated={(result) => {
        if (result) {
          onNext();
        }
      }}
    />
  );

  return (
    <div>
      <CommonModal
        show={!!lpnConfirmationShown}
        onClose={cancelLPNRemoval}
        body="Are you sure you want to delete this license plate number? It has aliases configured. If you will remove this LPN, then all aliases you have setup are going to be lost."
        title="License plate number removal"
        footer={
          <div>
            <SecondaryButton
              value="Cancel"
              onClick={() => cancelLPNRemoval()}
            />
            <ActionButton value="Delete" onClick={() => removeLPN()} />
          </div>
        }
      />
      {form}
    </div>
  );
}

export default DriverDetailsForm;
