import React from "react";
import { withRouter } from "react-router-dom";
import GenericModifyPage from "../genericModifyPage/GenericModifyPage";
import { InvoiceCrudConfig } from "./InvoiceCrudConfig";

import "./ModifyInvoicePage.scss";

function ModifyInvoicePage(props) {
  const { id } = props.match.params;

  return (
    <GenericModifyPage
      id={id}
      getTitle={InvoiceCrudConfig.getTitle}
      fetch={InvoiceCrudConfig.fetch}
      save={InvoiceCrudConfig.save}
      indexPage={InvoiceCrudConfig.indexPage}
      itemForm={InvoiceCrudConfig.itemForm}
      validate={InvoiceCrudConfig.validate}
      pageProps={props}
    />
  );
}

export default withRouter(ModifyInvoicePage);
