import React, { useContext, useState, useEffect } from "react";
import { get } from "lodash";
import GlobalContext from "../../utilities/context/GlobalContext";
import EditForm from "../../components/field/EditForm";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import SimpleDatePicker from "../../components/field/SimpleDatePicker";
import { getDateFromDatabaseTime } from "../../utilities/timeUtils";
import {
  getCurrentUserDriverData,
  getCurrentUserReservationSettings,
} from "../../utilities/apiUtils";
import ComboBox from "../../components/field/ComboBox";
import { expectValueSet } from "../../components/field/validation/ComboBoxUtil";

function ReservationDetailsForm(props) {
  const context = useContext(GlobalContext);
  const currentUser = get(context, "state.user", "");

  const [item, onChanged, onCancel, onBack, onNext] = [
    props.item,
    props.onChanged,
    props.onCancel,
    props.onBack,
    props.onNext,
  ];

  if (item.userId == undefined) {
    item.userId = currentUser.user.id;
  }

  if (item.entryTime == undefined) {
    item.entryTime = {};
  }

  if (item.durationMinutes == undefined) {
    item.durationMinutes = -1;
  }

  const [entryTime, setEntryTime] = useState({});
  const [exitTime, setExitTime] = useState({});
  const [licensePlates, setLicensePlateNumbers] = useState([]);
  const [licensePlateSelected, setLicensePlateSelected] = useState({});
  const [reservationSettingsFetched, setReservationSettingsFetched] =
    useState(false);
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const [minHours, setMinHours] = useState(0);
  const [maxHours, setMaxHours] = useState(0);

  useEffect(() => {
    getCurrentUserDriverData().then((res) => {
      if (res && res.data && res.data.driver) {
        setLicensePlateNumbers(
          res.data.driver.licensePlates.map((item) => {
            return {
              id: item.number,
              name: item.number,
            };
          })
        );

        getCurrentUserReservationSettings().then((res) => {
          if (res && res.data) {
            const now = new Date();
            setMinDate(
              new Date(
                now.getTime() +
                  res.data.minReservationDays * 24 * 60 * 60 * 1000
              )
            );
            setMaxDate(
              new Date(
                now.getTime() +
                  res.data.maxReservationDays * 24 * 60 * 60 * 1000
              )
            );
            setMinHours(res.data.minReservationHoursPeriod);
            setMaxHours(res.data.maxReservationHoursPeriod);
            setReservationSettingsFetched(true);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    processEntryAndExitTimes();
    if (item.licensePlateNumber != undefined && item.licensePlateNumber != "") {
      setLicensePlateSelected({
        id: item.licensePlateNumber,
        name: item.licensePlateNumber,
      });
    }
  }, [item]);

  const processEntryAndExitTimes = () => {
    setEntryTime(item.entryTime || {});
    if (item.durationMinutes > 0) {
      const entryDate = getDateFromDatabaseTime(item.entryTime);
      const exitDate = new Date(
        entryDate.getTime() + item.durationMinutes * 60000
      );
      const year = exitDate.getYear() + 1900;
      const month = exitDate.getMonth() + 1;
      const day = exitDate.getDate(); // The getDate() method returns the day of the month for the specified date according to local time. I.e. it should be getDay(), but JS sucks.
      const hour = exitDate.getHours();
      const minute = exitDate.getMinutes();
      const second = 0;
      setExitTime({
        year: `${year}`,
        month: `${month < 10 ? "0" : ""}${month}`,
        day: `${day < 10 ? "0" : ""}${day}`,
        hour: `${hour < 10 ? "0" : ""}${hour}`,
        minute: `${minute < 10 ? "0" : ""}${minute}`,
        second: `${second < 10 ? "0" : ""}${second}`,
      });
    } else {
      setExitTime(null);
    }
  };

  const changeEntryTime = (value) => {
    item.entryTime = value;
    setEntryTime(value);
    onChanged(item);
  };

  const changeExitTime = (value) => {
    setExitTime(value);

    if (item.entryTime == undefined || item.entryTime.year == undefined) {
      return;
    }
    const entryDate = getDateFromDatabaseTime(item.entryTime);
    const exitDate = getDateFromDatabaseTime(value);
    const diff = exitDate.getTime() - entryDate.getTime();
    if (diff < 0) {
      return;
    }
    const minutes = parseInt(Math.abs(diff) / (1000 * 60));
    item.durationMinutes = minutes;
    onChanged(item);
  };

  const changeLicensePlateNumber = (value) => {
    item.licensePlateNumber = value.id;
    setLicensePlateSelected(value);
    onChanged(item);
  };

  const formComponents = [
    <SimpleDatePicker
      key={1}
      label="Entry time"
      value={entryTime}
      validationController={(value) => {
        if (value == undefined || value.year == undefined || !value.year) {
          return {
            success: false,
            text: "You must select valid entry time value.",
          };
        }

        const entryDate = getDateFromDatabaseTime(value);
        if (entryDate < minDate) {
          return {
            success: false,
            text: `Minimum allowed time for reservation is ${minDate}.`,
          };
        }

        if (entryDate > maxDate) {
          return {
            success: false,
            text: `Maximum allowed time for reservation is ${maxDate}.`,
          };
        }

        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(value) => changeEntryTime(value)}
      placeholder="Select valid entry time value"
      setTime={true}
    />,
    <SimpleDatePicker
      key={2}
      label="Exit time"
      value={exitTime}
      validationController={(value) => {
        if (value == undefined || value.year == undefined || !value.year) {
          return {
            success: false,
            text: "You must select valid exit time value.",
          };
        }

        const entryDate = getDateFromDatabaseTime(item.entryTime);
        const exitDate = getDateFromDatabaseTime(value);
        const diff = exitDate.getTime() - entryDate.getTime();
        if (diff <= 0) {
          return {
            success: false,
            text: "Exit time must be greater than entry time.",
          };
        }

        if (diff < minHours * 60 * 60 * 1000) {
          return {
            success: false,
            text: `Minimum time for parking must be at least ${minHours} hour(s).`,
          };
        }

        if (diff > maxHours * 60 * 60 * 1000) {
          return {
            success: false,
            text: `Maximum time for parking must be less than ${maxHours} hour(s).`,
          };
        }

        if (exitDate < minDate) {
          return {
            success: false,
            text: `Minimum allowed time for reservation is ${minDate}.`,
          };
        }

        if (exitDate > maxDate) {
          return {
            success: false,
            text: `Maximum allowed time for reservation is ${maxDate}.`,
          };
        }

        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(value) => changeExitTime(value)}
      placeholder="Select valid exit time value"
      setTime={true}
    />,
    <ComboBox
      key={3}
      label="License plate number"
      options={licensePlates}
      value={licensePlateSelected}
      validationController={(value) =>
        expectValueSet(value, "You must select a license plate number.")
      }
      onValueChanged={(value) => changeLicensePlateNumber(value)}
      selectionPlaceholder="None"
    />,
  ];

  const form = (
    <EditForm
      components={formComponents}
      continueAction={<PrimaryButton value="Next" />}
      backAction={<PrimaryButton onClick={() => onBack()} value="Back" />}
      cancelAction={<PrimaryButton onClick={() => onCancel()} value="Cancel" />}
      onValidated={(result) => {
        if (result) {
          onNext();
        }
      }}
    />
  );

  return reservationSettingsFetched ? (
    <div>{form}</div>
  ) : (
    <div class="alert alert-primary">Loading.</div>
  );
}

export default ReservationDetailsForm;
