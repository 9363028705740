import React, { useEffect, useState } from "react";
import braintree from "braintree-web";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import { processInvoice } from "../../utilities/apiUtils";
import { processPayment } from "./Util";

export default function CreditCardForm(props) {
  const [visible, type, entityId] = [
    props.visible || false,
    props.type,
    props.entityId,
  ];
  const [error, setError] = useState("");

  useEffect(() => {
    if (!visible) {
      setError("");
      return;
    }

    braintree.client.create(
      {
        authorization: "sandbox_6m2cjghn_7s4bt2qwq9pjyqxx",
      },
      clientDidCreate
    );
  }, [visible]);

  const clientDidCreate = (err, client) => {
    braintree.hostedFields.create(
      {
        client: client,
        styles: {
          // Style all elements
          input: {
            "font-size": "16px",
            color: "#3A3A3A",
          },

          // Styling a specific field
          // Custom web fonts are not supported. Only use system installed fonts.
          ".number": {
            "font-family": "monospace",
          },

          // Styling element state
          ":focus": {
            color: "blue",
          },
          ".valid": {
            color: "green",
          },
          ".invalid": {
            color: "red",
          },
        },
        fields: {
          cardholderName: {
            selector: "#card-name",
            placeholder: "Name as it appears on your card",
          },
          number: {
            selector: "#card-number",
            placeholder: "4111 1111 1111 1111",
          },
          cvv: {
            selector: "#cvv",
            placeholder: "123",
          },
          expirationDate: {
            selector: "#expiration-date",
            placeholder: "MM / YY",
          },
        },
      },
      hostedFieldsDidCreate
    );
  };

  const hostedFieldsDidCreate = (err, hostedFields) => {
    if (err) {
      console.error(err);
      return;
    }

    hostedFields.on("validityChange", function (event) {
      var field = event.fields[event.emittedBy];

      // Remove any previously applied error or warning classes
      const errorClass = `.${field.container.id}-error`;
      document.querySelector(errorClass).style.display = "none";
      field.container.classList.remove("is-valid");
      field.container.classList.remove("is-invalid");

      if (field.isValid) {
        field.container.classList.add("is-valid");
      } else if (field.isPotentiallyValid) {
        // skip adding classes if the field is
        // not valid, but is potentially valid
      } else {
        field.container.classList.add("is-invalid");
        document.querySelector(errorClass).style.display = "block";
      }
    });

    const submitBtn = document.getElementById("submit-credit-card");
    submitBtn.addEventListener("click", submitHandler.bind(null, hostedFields));
    submitBtn.removeAttribute("disabled");
  };

  const submitHandler = (hostedFields, event) => {
    const submitBtn = document.getElementById("submit-credit-card");
    event.preventDefault();
    submitBtn.setAttribute("disabled", "disabled");
    setError("");

    const state = hostedFields.getState();
    let formIsInvalid = false;

    // Loop through the Hosted Fields and check
    // for validity, apply the is-invalid class
    // to the field container if invalid
    Object.keys(state.fields).forEach(function (field) {
      const errorClass = `.${state.fields[field].container.id}-error`;
      if (state.fields[field].isValid) {
        document.querySelector(errorClass).style.display = "none";
        state.fields[field].container.classList.remove("is-invalid");
        state.fields[field].container.classList.add("is-valid");
      } else {
        document.querySelector(errorClass).style.display = "block";
        state.fields[field].container.classList.add("is-invalid");
        formIsInvalid = true;
      }
    });

    /*if (formIsInvalid) {
      // skip tokenization request if any fields are invalid
      submitBtn.removeAttribute('disabled');
      return;
    }*/

    hostedFields.tokenize(function (err, payload) {
      if (err) {
        console.log(err);
        submitBtn.removeAttribute("disabled");
        setError(err.message);
      } else {
        console.log(payload);
        processPayment(type, entityId, payload.nonce, props.history, setError);
      }
    });
  };

  return (
    visible && (
      <>
        <form className={"braintree-card-form"} id="credit-card-payment-form">
          {error != "" && (
            <div class="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          <input type="hidden" name="payment_method_nonce" />

          <div class="alot-credit-card-input-field">
            <label for="card-name">Cardholder Name</label>
            <div class="credit-card-input" id="card-name"></div>
            <div class="card-name-error invalid-feedback">
              Valid name on card is required
            </div>
          </div>

          <div class="alot-credit-card-input-field">
            <label for="card-number">Card Number</label>
            <div class="credit-card-input" id="card-number"></div>
            <div class="card-number-error invalid-feedback">
              Valid credit card number is required
            </div>
          </div>

          <div class="alot-credit-card-input-field">
            <label for="cvv">CVV / CVC</label>
            <div class="credit-card-input" id="cvv"></div>
            <div class="cvv-error invalid-feedback">
              Valid security code required
            </div>
          </div>

          <div class="alot-credit-card-input-field">
            <label for="expiration-date">Expiration Date</label>
            <div class="credit-card-input" id="expiration-date"></div>
            <div class="expiration-date-error invalid-feedback">
              Valid expiration date required
            </div>
          </div>

          <PrimaryButton
            value="Pay"
            disabled={true}
            id={"submit-credit-card"}
          />
        </form>
      </>
    )
  );
}
