import { getUserData } from "./apiUtils";
import { get } from "lodash";
import { getToken } from "./localStorageUtils";
import jwt from "jsonwebtoken";
import { tokenConstants } from "./constants";

export const getUserInfo = async () => {
  const token = getToken();
  if (token) {
    return jwt.verify(
      token,
      tokenConstants.TOKEN_SECRET_VALUE,
      async (err, decoded) => {
        if (decoded) {
          const data = await getUserData(get(decoded, "id"));
          return data;
        }
        return null;
      }
    );
  }
};
