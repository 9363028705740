import React, { useState } from "react";
import { paths } from "../../utilities/constants";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import { processSaveResult } from "../../utilities/crudUtils";

function DriverFinalStepForm(props) {
  const [item, onCancel, onBack, onSave] = [
    props.item,
    props.onCancel,
    props.onBack,
    props.onSave,
  ];

  const [errors, setErrors] = useState([]);

  const handleSaveAction = () => {
    setErrors([]);
    onSave(item).then((result) => {
      processSaveResult(
        result,
        props.history,
        `${paths.accessManagementPath}/drivers`,
        setErrors
      );
    });
  };

  return (
    <div>
      {errors.length > 0 && (
        <div class="alert alert-danger" role="alert">
          <p>
            Server has returned the following errors that you must fix first!
          </p>
          <ul>
            {errors.map((x) => (
              <li>{x}</li>
            ))}
          </ul>
        </div>
      )}
      <p>
        You have filled in all of the data that is required for the driver to be
        saved. Now you can save the data.
      </p>
      <div class="action-buttons">
        <div class="row">
          <PrimaryButton onClick={() => onCancel()} value="Cancel" />
          <PrimaryButton onClick={() => onBack()} value="Back" />
          <PrimaryButton onClick={() => handleSaveAction()} value="Save" />
        </div>
      </div>
    </div>
  );
}

export default DriverFinalStepForm;
