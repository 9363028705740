import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EditForm from "../../components/field/EditForm";
import TextField from "../../components/field/TextField";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import { getReservationSettings } from "../../utilities/apiUtils";
import { isPositiveInteger } from "../../utilities/numberUtils";

function ParkingLotReservationSettingsForm(props) {
  const [item, onChanged, onCancel, onNext, onBack] = [
    props.item,
    props.onChanged,
    props.onCancel,
    props.onNext,
    props.onBack,
  ];

  const [reservationsEnabled, setReservationsEnabled] = useState(true);
  const [minDays, setMinDays] = useState(0);
  const [maxDays, setMaxDays] = useState(0);
  const [minHours, setMinHours] = useState(0);
  const [maxHours, setMaxHours] = useState(0);
  const [settingsFetched, setSettingsFetched] = useState(false);
  const [settings, setSettings] = useState({});

  useEffect(() => {
    if (item.reservationSettings == undefined) {
      item.reservationSettings = {
        reservationsEnabled: true,
        minReservationDays: 0,
        maxReservationDays: 7,
        minReservationHoursPeriod: 0,
        maxReservationHoursPeriod: 24,
      };

      getReservationSettings().then((res) => {
        if (res.data) {
          item.reservationSettings = {
            reservationsEnabled: res.data.reservationsEnabled,
            minReservationDays: res.data.minReservationDays,
            maxReservationDays: res.data.maxReservationDays,
            minReservationHoursPeriod: res.data.minReservationHoursPeriod,
            maxReservationHoursPeriod: res.data.maxReservationHoursPeriod,
          };

          setReservationsEnabled(res.data.reservationsEnabled);
          setMinDays(res.data.minReservationDays.toString());
          setMaxDays(res.data.maxReservationDays.toString());
          setMinHours(res.data.minReservationHoursPeriod.toString());
          setMaxHours(res.data.maxReservationHoursPeriod.toString());
          setSettings(res.data);
          setSettingsFetched(true);
        }
      });
    } else {
      const settings = item.reservationSettings;
      setReservationsEnabled(settings.reservationsEnabled);
      setMinDays(settings.minReservationDays.toString());
      setMaxDays(settings.maxReservationDays.toString());
      setMinHours(settings.minReservationHoursPeriod.toString());
      setMaxHours(settings.maxReservationHoursPeriod.toString());
      setSettings(settings);
      setSettingsFetched(true);
    }
  }, []);

  const changeReservationsEnabled = (value) => {
    settings.reservationsEnabled = value;
    item.reservationSettings = settings;
    setReservationsEnabled(value);
    onChanged(item);
  };

  const changeMinDays = (value) => {
    settings.minReservationDays = parseInt(value);
    item.reservationSettings = settings;
    setMinDays(value);
    onChanged(item);
  };

  const changeMaxDays = (value) => {
    settings.maxReservationDays = parseInt(value);
    item.reservationSettings = settings;
    setMaxDays(value);
    onChanged(item);
  };

  const changeMinHours = (value) => {
    settings.minReservationHoursPeriod = parseInt(value);
    item.reservationSettings = settings;
    setMinHours(value);
    onChanged(item);
  };

  const changeMaxHours = (value) => {
    settings.maxReservationHoursPeriod = parseInt(value);
    item.reservationSettings = settings;
    setMaxHours(value);
    onChanged(item);
  };

  const formComponents = [
    <FormControlLabel
      key={1}
      control={
        <Checkbox
          checked={reservationsEnabled}
          onChange={(event) => changeReservationsEnabled(event.target.checked)}
          color="primary"
        />
      }
      label="Reservations enabled"
    />,
    <h3>Reservation frame settings</h3>,
    <TextField
      disabled={!reservationsEnabled}
      key={2}
      value={minDays}
      placeholder="Enter minimum number of days"
      validationController={(value) => {
        const valueAsString = value.toString();
        if (!isPositiveInteger(valueAsString)) {
          return {
            success: false,
            text: "Minimum number of days must be a positive integer.",
          };
        }

        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(value) => changeMinDays(value)}
      label="Minimum number of days before reservation"
    />,
    <TextField
      disabled={!reservationsEnabled}
      key={3}
      value={maxDays}
      placeholder="Enter maximum number of days"
      validationController={(value) => {
        const valueAsString = value.toString();
        if (!isPositiveInteger(valueAsString)) {
          return {
            success: false,
            text: "Maximum number of days must be a positive integer.",
          };
        }

        if (parseInt(minDays) > parseInt(maxDays)) {
          return {
            success: false,
            text: "Maximum number of days must be greater or equal to minimum number of days.",
          };
        }

        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(value) => changeMaxDays(value)}
      label="Maximum number of days before reservation"
    />,
    <hr />,
    <h3>Reservation period settings</h3>,
    <TextField
      disabled={!reservationsEnabled}
      key={4}
      value={minHours}
      placeholder="Enter minimum reservation period in hours"
      validationController={(value) => {
        const valueAsString = value.toString();
        if (!isPositiveInteger(valueAsString)) {
          return {
            success: false,
            text: "Minimum reservation period in hours must be a positive integer.",
          };
        }

        if (parseInt(value) < 1) {
          return {
            success: false,
            text: "Minimum reservation period must be at least one hour.",
          };
        }

        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(value) => changeMinHours(value)}
      label="Minimum reservation period in hours"
    />,
    <TextField
      disabled={!reservationsEnabled}
      key={4}
      value={maxHours}
      placeholder="Enter maximum reservation period in hours"
      validationController={(value) => {
        const valueAsString = value.toString();
        if (!isPositiveInteger(valueAsString)) {
          return {
            success: false,
            text: "Maximum reservation period in hours must be a positive integer.",
          };
        }

        if (parseInt(minHours) > parseInt(maxHours)) {
          return {
            success: false,
            text: "Maximum number of hours must be greater or equal to minimum number of hours.",
          };
        }

        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(value) => changeMaxHours(value)}
      label="Maximum reservation period in hours"
    />,
  ];

  const form = (
    <EditForm
      components={formComponents}
      continueAction={<PrimaryButton value="Next" />}
      cancelAction={<PrimaryButton onClick={() => onCancel()} value="Cancel" />}
      backAction={<PrimaryButton onClick={() => onBack()} value="Back" />}
      onValidated={(result) => {
        if (result) {
          onNext();
        }
      }}
    />
  );

  return (
    <>
      {settingsFetched ? (
        <div>{form}</div>
      ) : (
        <div class="alert alert-primary">Loading.</div>
      )}
    </>
  );
}

export default ParkingLotReservationSettingsForm;
