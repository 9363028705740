import React from "react";
import Table from "../../components/table/Table";
import "./ParkingLotsBasicDetailsTab.scss";

function getAccessControlName(type) {
  switch (type) {
    case "lpr":
      return "License plate recognition";
    case "bluetooth":
      return "Bluetooth";
    case "manual":
      return "Manual";
  }

  return `${type} resolves to unknown`;
}

function ParkingLotsBasicDetailsTab(props) {
  const [parkingLotData, occupancyCount] = [
    props.parkingLotData,
    props.occupancyCount,
  ];

  const getSettingsRow = (settings) => {
    return (
      <tr>
        <td>{settings.title}</td>
        <td>{settings.value}</td>
      </tr>
    );
  };

  return (
    <div>
      <div class="container">
        <div class="row">
          <div class="col">
            <dl>
              <dt>Occupancy</dt>
              <dd>
                {occupancyCount} of {parkingLotData.parkingSpaces}
              </dd>
            </dl>
          </div>
          <div class="col">
            <dl>
              <dt>Access control mechanism(s)</dt>
              <dd>
                {parkingLotData.accessControl
                  .map((x) => getAccessControlName(x))
                  .join(", ")}
              </dd>
            </dl>
          </div>
          <div class="col">
            <dl>
              <dt>Maximum number of cars allowed</dt>
              <dd>{parkingLotData.parkingSpaces}</dd>
            </dl>
          </div>
        </div>
      </div>
      <Table
        headers={[<th>Feature</th>, <th>Enabled</th>]}
        generateRow={getSettingsRow}
        items={[
          {
            title: "Cars allowed when lot is full",
            value: parkingLotData.accessData.allowCarsWhenLotFull
              ? "Yes"
              : "No",
          },
          {
            title: "Unknown drivers allowed",
            value: parkingLotData.accessData.allowUnknownDrivers ? "Yes" : "No",
          },
          {
            title: "Information displays",
            value: parkingLotData.accessData.infoDisplays ? "Yes" : "No",
          },
          {
            title: "Reservations enabled",
            value: parkingLotData.reservationSettings.reservationsEnabled
              ? "Yes"
              : "No",
          },
        ]}
      />
    </div>
  );
}

export default ParkingLotsBasicDetailsTab;
