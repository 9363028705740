import React, { useContext, useState, useEffect } from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { find, get } from "lodash";
import EditForm from "../../components/field/EditForm";
import TextField from "../../components/field/TextField";
import TextArea from "../../components/field/TextArea";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import "./InvoiceDetailsForm.scss";
import {
  expectNotEmpty,
  passAllways,
} from "../../components/field/validation/TextFieldUtil";
import { expectValueSet } from "../../components/field/validation/ComboBoxUtil";
import FieldsContainer from "../../components/field/FieldsContainer";
import ComboBox from "../../components/field/ComboBox";
import SimpleDatePicker from "../../components/field/SimpleDatePicker";
import { getClientHeaders } from "../../utilities/apiUtils";
import { getUserName } from "../../utilities/itemUtils";
import InvoiceItemsForm from "./InvoiceItemsForm";
import { getDateFromDatabaseTime } from "../../utilities/timeUtils";
import GlobalContext from "../../utilities/context/GlobalContext";

function InvoiceDetailsForm(props) {
  const selectedCompanyId = props.pageProps.match.params.company;
  const context = useContext(GlobalContext);
  const currentUser = get(context, "state.user", "");
  const userCompany = get(currentUser, "company", "");

  const [item, onChanged, onCancel, onNext] = [
    props.item,
    props.onChanged,
    props.onCancel,
    props.onNext,
  ];

  if (item.company == undefined) {
    item.company = {};
  }

  if (item.items == undefined) {
    item.items = [];
  }

  if (selectedCompanyId != undefined) {
    item.clientId = selectedCompanyId;
  }

  if (item.managed == undefined) {
    item.managed = false;
  }

  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [date, setDate] = useState({});
  const [deadline, setDeadline] = useState({});

  const [companyName, setCompanyName] = useState("");
  const [taxId, setTaxId] = useState("");
  const [vatId, setVatId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [clients, setClients] = useState([]);
  const [clientSelected, setClientSelected] = useState({});
  const [invoiceItems, setInvoiceItems] = useState([]);

  const [managed, setManaged] = useState(false);
  const [invoicePaymentDetails, setInvoicePaymentDetails] = useState("");

  useEffect(() => {
    loadClients();
  }, []);

  useEffect(() => {
    if (item.invoiceNumber) {
      return;
    }

    changeCompanyName(userCompany.name);
    changeTaxId(userCompany.taxId);
    changeVatId(userCompany.vatId);
    changePhoneNumber(userCompany.phoneNumber);
    changeAddress(userCompany.address);
    changePaymentDetails(userCompany.paymentDetails);
  }, [userCompany]);

  useEffect(() => {
    setInvoiceNumber(item.invoiceNumber);
    setDate(item.date || {});
    setDeadline(item.deadline || {});
    setCompanyName(item.company.name);
    setTaxId(item.company.taxId);
    setVatId(item.company.vatId);
    setPhoneNumber(item.company.phoneNumber);
    setAddress(item.company.address);
    setPaymentDetails(item.company.paymentDetails);
    setManaged(item.managed);
    setInvoicePaymentDetails(item.paymentDetails);

    const client = find(clients, (x) => x.id == item.clientId);
    if (client) {
      setClientSelected(client);
    }

    setInvoiceItems(item.items);
    item.total = {
      amount: item.items.map((x) => x.price.amount).reduce((a, b) => a + b, 0),
      currency: "USD",
    };
  }, [item]);

  const loadClients = () => {
    getClientHeaders().then((res) => {
      if (res.data) {
        setClients(res.data);

        const client = find(
          res.data,
          (x) => x.id == item.clientId || x.id == selectedCompanyId
        );
        if (client) {
          setClientSelected(client);
        }
      }
    });
  };

  const changeInvoiceNumber = (value) => {
    item.invoiceNumber = value;
    setInvoiceNumber(value);
    onChanged(item);
  };

  const changeDate = (value) => {
    item.date = value;
    setDate(value);
    onChanged(item);
  };

  const changeDeadline = (value) => {
    item.deadline = value;
    setDeadline(value);
    onChanged(item);
  };

  const changeCompanyName = (value) => {
    item.company.name = value;
    setCompanyName(value);
    onChanged(item);
  };

  const changeTaxId = (value) => {
    item.company.taxId = value;
    setTaxId(value);
    onChanged(item);
  };

  const changeVatId = (value) => {
    item.company.vatId = value;
    setVatId(value);
    onChanged(item);
  };

  const changePhoneNumber = (value) => {
    item.company.phoneNumber = value;
    setPhoneNumber(value);
    onChanged(item);
  };

  const changeAddress = (value) => {
    item.company.address = value;
    setAddress(value);
    onChanged(item);
  };

  const changePaymentDetails = (value) => {
    item.company.paymentDetails = value;
    setPaymentDetails(value);
    onChanged(item);
  };

  const changeClient = (value) => {
    item.clientId = value.id;
    setClientSelected(value);
    onChanged(item);
  };

  const changeInvoiceItems = (items) => {
    item.items = items;
    item.total = {
      amount: item.items.map((x) => x.price.amount).reduce((a, b) => a + b, 0),
      currency: "USD",
    };

    setInvoiceItems(items);
    onChanged(item);
  };

  const changeManagedValue = (value) => {
    item.managed = value;
    setManaged(value);
    onChanged(item);
  };

  const changeInvoicePaymentDetails = (value) => {
    item.paymentDetails = value;
    setInvoicePaymentDetails(value);
    onChanged(item);
  };

  const companyFields = [
    <TextField
      key={1}
      value={companyName}
      placeholder="Enter company name"
      validationController={(value) =>
        expectNotEmpty(value, "Company name cannot be empty.")
      }
      onValueChanged={(value) => changeCompanyName(value)}
      label="Company name"
    />,
    <TextField
      key={2}
      value={taxId}
      placeholder="Enter tax ID"
      validationController={(value) =>
        expectNotEmpty(value, "Tax ID cannot be empty.")
      }
      onValueChanged={(value) => changeTaxId(value)}
      label="Tax ID"
    />,
    <TextField
      key={3}
      value={vatId}
      placeholder="Enter Vat ID"
      validationController={(value) => passAllways(value)}
      onValueChanged={(value) => changeVatId(value)}
      label="Vat ID (optional)"
    />,
    <TextField
      key={4}
      value={phoneNumber}
      placeholder="Phone number"
      validationController={(value) =>
        expectNotEmpty(value, "Phone number cannot be empty.")
      }
      onValueChanged={(value) => changePhoneNumber(value)}
      label="Enter phone number"
    />,
    <TextField
      key={5}
      value={address}
      placeholder="Enter address"
      validationController={(value) =>
        expectNotEmpty(value, "Address cannot be empty.")
      }
      onValueChanged={(value) => changeAddress(value)}
      label="Address"
    />,
    <TextField
      key={6}
      value={paymentDetails}
      placeholder="Enter payment details"
      validationController={(value) =>
        expectNotEmpty(value, "Payment details cannot be empty.")
      }
      onValueChanged={(value) => changePaymentDetails(value)}
      label="Payment details"
    />,
  ];

  const formComponents = [
    <TextField
      key={1}
      value={invoiceNumber}
      placeholder="Invoice number"
      validationController={(value) =>
        expectNotEmpty(value, "Invoice number cannot be empty.")
      }
      onValueChanged={(value) => changeInvoiceNumber(value)}
      label="Invoice number"
    />,
    <SimpleDatePicker
      key={2}
      label="Date"
      value={date}
      validationController={(value) => {
        if (value == undefined || value.year == undefined || !value.year) {
          return {
            success: false,
            text: "You must select valid invoice date value.",
          };
        }

        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(value) => changeDate(value)}
      placeholder="Select valid invoice date value"
    />,
    <SimpleDatePicker
      key={3}
      label="Deadline"
      value={deadline}
      validationController={(value) => {
        if (value == undefined || value.year == undefined || !value.year) {
          return {
            success: false,
            text: "You must select valid invoice deadline value.",
          };
        }

        if (date != undefined && date.year != undefined) {
          const dateTime = getDateFromDatabaseTime(date);
          const deadlineTime = getDateFromDatabaseTime(value);
          if (deadlineTime.getTime() < dateTime.getTime()) {
            return {
              success: false,
              text: "Invoice deadline date must be greater or equal to the invoice date.",
            };
          }
        }

        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(value) => changeDeadline(value)}
      placeholder="Select valid invoice deadline value"
    />,
    <FieldsContainer
      key={4}
      components={companyFields}
      label="Your company details"
    />,
    <ComboBox
      key={5}
      label="Invoice to"
      options={clients}
      value={clientSelected}
      validationController={(value) =>
        expectValueSet(
          value,
          "You must select a client for who you want the invoice to be sent."
        )
      }
      onValueChanged={(value) => changeClient(value)}
      selectionPlaceholder="None"
      nameGetter={(item) => {
        return getUserName(item);
      }}
    />,
    <InvoiceItemsForm
      key={6}
      label="Invoice items"
      items={invoiceItems}
      validationController={(items) => {
        if (items.length == 0) {
          return {
            success: false,
            text: "You must add at least one item.",
          };
        }

        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(items) => changeInvoiceItems(items)}
    />,
    <FormControlLabel
      key={7}
      control={
        <Checkbox
          checked={managed}
          onChange={(event) => changeManagedValue(event.target.checked)}
          color="primary"
        />
      }
      label="Payments are managed by Mobility Robotics"
    />,
    <TextArea
      key={8}
      value={invoicePaymentDetails}
      placeholder="Payment details"
      validationController={(value) => {
        if (managed) {
          return {
            success: true,
            text: "",
          };
        }

        return expectNotEmpty(
          value,
          "When invoice is not managed by Mobility Robotics, you must provide all the payment details of how your invoice is going to be paid by the client."
        );
      }}
      onValueChanged={(value) => changeInvoicePaymentDetails(value)}
      label="Enter details or how the customer should pay to you."
      disabled={managed}
    />,
  ];

  const form = (
    <EditForm
      components={formComponents}
      continueAction={<PrimaryButton value="Next" />}
      cancelAction={<PrimaryButton onClick={() => onCancel()} value="Cancel" />}
      onValidated={(result) => {
        if (result) {
          onNext();
        }
      }}
    />
  );

  return <div>{form}</div>;
}

export default InvoiceDetailsForm;
