import React, { useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { paths } from "../../utilities/constants";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import { getInvoiceClients, getInvoices } from "../../utilities/apiUtils";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import "./InvoicesPage.scss";
import InvoicesContainer from "./InvoicesContainer";
import InvoiceClientsContainer from "./InvoiceClientsContainer";
import { getCompany } from "../../utilities/itemUtils";
import PageTitle from "../../components/pageTitle/PageTitle";
import CreateItemButton from "../../components/createItemButton/CreateItemButton";
import SecondaryButton from "../../components/secondaryButton/SecondaryButton";

function InvoicesPage(props) {
  const { id } = props.match.params;

  const [invoiceClients, setInvoiceClients] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [company, setCompany] = useState("");

  useEffect(() => {
    if (id) {
      loadInvoices();
    }
    loadInvoiceClients();
  }, [id]);

  const loadInvoices = () => {
    getInvoices(id).then((res) => {
      if (res.data) {
        setInvoices(res.data);
      }
    });
  };

  const loadInvoiceClients = () => {
    getInvoiceClients().then((res) => {
      if (res.data) {
        setInvoiceClients(res.data);

        if (id) {
          const companies = res.data.filter((x) => x.client.id == id);
          if (companies.length > 0) {
            setCompany(getCompany(companies[0].clientCompany));
          }
        }
      }
    });
  };

  return (
    <>
      <Header />
      <div className="content-page">
        <div class="container-fluid">
          <div class="row">
            <Sidebar />
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10">
              <PageTitle
                value={
                  "Invoices" + (id && company != "" ? ` > ${company}` : "")
                }
              />
              <CreateItemButton
                path={
                  `${paths.revenueanagementPath}/invoices/modify/new` +
                  (id ? `/company/${id}` : "")
                }
                value="Create new invoice"
                containsLineBreak={false}
              />
              {id && (
                <div class="row">
                  <div class="col">
                    <div class="float-right">
                      <br />
                      <Link to={`${paths.revenueanagementPath}/invoices`}>
                        <SecondaryButton value="Back" />
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              <br />
              <br />
              {id ? (
                <InvoicesContainer
                  invoicesLoader={loadInvoices}
                  invoices={invoices}
                />
              ) : (
                <InvoiceClientsContainer clients={invoiceClients} />
              )}
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(InvoicesPage);
