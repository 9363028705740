import {
  getClient,
  getDriver,
  saveClient,
  saveDriver,
} from "../../utilities/apiUtils";
import { paths } from "../../utilities/constants";
import { getUserName } from "../../utilities/itemUtils";
import ClientForm from "./ClientForm";

export const ClientCrudConfig = {
  getAdminTitle: (item) => {
    if (!item.driver || !item.driver.id) {
      return "Create new administrator";
    }

    return `Edit administrator "${getUserName(item.client)}"`;
  },

  getSubAdminTitle: (item) => {
    if (!item.driver || !item.driver.id) {
      return "Create new sub-admin";
    }

    return `Edit sub-admin "${getUserName(item.client)}"`;
  },

  fetch: (id) => {
    return getClient(id);
  },

  save: (item) => {
    return saveClient(item);
  },

  adminIndexPage: (item) => {
    return `${paths.multiUserManagementPath}/admins`;
  },

  subAdminIndexPage: (item) => {
    return `${paths.multiUserManagementPath}/sub-admins`;
  },

  adminItemForm: <ClientForm type={"admin"} />,

  subAdminItemForm: <ClientForm type={"sub-admin"} />,
};
