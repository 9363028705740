import React from "react";
import { withRouter } from "react-router-dom";
import GenericModifyPage from "../genericModifyPage/GenericModifyPage";
import { ReservationCrudConfig } from "./ReservationCrudConfig";

function ModifyReservationPage(props) {
  const { id } = props.match.params;

  return (
    <GenericModifyPage
      id={id}
      getTitle={ReservationCrudConfig.getTitle}
      fetch={ReservationCrudConfig.fetch}
      save={ReservationCrudConfig.save}
      indexPage={ReservationCrudConfig.indexPage}
      itemForm={ReservationCrudConfig.itemForm}
      validate={ReservationCrudConfig.validate}
    />
  );
}

export default withRouter(ModifyReservationPage);
