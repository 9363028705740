import "./SecondaryButton.scss";

export default function SecondaryButton(props) {
  const { onClick, value, className } = props;
  return (
    <button
      onClick={() => (onClick ? onClick() : "")}
      className={`alot-secondary-button ${className}`}
    >
      {value}
    </button>
  );
}
