import React, { useState, useEffect } from "react";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import "./SimpleDatePicker.scss";

function SimpleDatePicker(props) {
  const [
    placeholder,
    validationController,
    onValueChanged,
    label,
    setTime,
    // EditForm-specific
    validationCounter,
    onValidated,
  ] = [
    props.placeholder,
    props.validationController,
    props.onValueChanged,
    props.label,
    props.setTime || false,
    // EditForm-specific
    props.validationCounter,
    props.onValidated,
  ];

  const [value, setValue] = useState(props.value || {});
  const [selectedDate, setSelectedDate] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    const value = props.value || {};
    setValue(value);
    if (value.year == undefined) {
      setSelectedDate("");
    } else if (
      value.year != undefined &&
      value.month != undefined &&
      value.day != undefined
    ) {
      setSelectedDate(
        new Date(
          parseInt(value.year),
          parseInt(value.month) - 1,
          parseInt(value.day),
          parseInt(value.hour || "0"),
          parseInt(value.minute || "0"),
          parseInt(value.second || "0")
        )
      );
    }
  }, [props.value]);

  useEffect(() => {
    setErrorVisible(false);
    setErrorText("");

    if (!props.validationCounter || props.validationCounter <= 0) {
      return;
    }

    const result = validationController(value);
    setErrorVisible(!result.success);
    setErrorText(result.text);
    onValidated(result);
  }, [validationCounter]);

  const handleDateChange = (date) => {
    const year = date.getYear() + 1900;
    const month = date.getMonth() + 1;
    const day = date.getDate(); // The getDate() method returns the day of the month for the specified date according to local time. I.e. it should be getDay(), but JS sucks.
    const hour = setTime ? date.getHours() : 0;
    const minute = setTime ? date.getMinutes() : 0;
    const second = setTime ? date.getSeconds() : 0;

    let newValue;
    if (setTime) {
      newValue = {
        year: `${year}`,
        month: `${month < 10 ? "0" : ""}${month}`,
        day: `${day < 10 ? "0" : ""}${day}`,
        hour: `${hour < 10 ? "0" : ""}${hour}`,
        minute: `${minute < 10 ? "0" : ""}${minute}`,
        second: `${second < 10 ? "0" : ""}${second}`,
      };
    } else {
      newValue = {
        year: `${year}`,
        month: `${month < 10 ? "0" : ""}${month}`,
        day: `${day < 10 ? "0" : ""}${day}`,
      };
    }

    setValue(newValue);
    setSelectedDate(date);
    onValueChanged(newValue);
  };

  const TimeComponent = setTime ? KeyboardDateTimePicker : KeyboardDatePicker;

  return (
    <div class="form-group">
      {label && (
        <>
          <label>{label}</label>
          <br />
        </>
      )}
      <TimeComponent
        value={selectedDate}
        placeholder={placeholder}
        onChange={(date) => handleDateChange(date)}
        format={setTime ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"}
        error={false}
        helperText={null}
        style={{ width: "100%" }}
      />
      {errorVisible && (
        <div style={{ display: "block" }} class="invalid-feedback">
          {errorText}
        </div>
      )}
    </div>
  );
}

export default SimpleDatePicker;
