import React, { useState } from "react";
import { paths } from "../../utilities/constants";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import { processSaveResult } from "../../utilities/crudUtils";

function ParkingLotFinalStepForm(props) {
  const [item, onCancel, onBack, onSave] = [
    props.item,
    props.onCancel,
    props.onBack,
    props.onSave,
  ];

  const [errors, setErrors] = useState([]);

  const handleSaveAction = () => {
    setErrors([]);
    if (item.sharingEntity && item.sharingEntity.id !== undefined) {
      delete item.sharingEntity.id;
    }

    if (item.location && item.location.id !== undefined) {
      delete item.location.id;
    }

    if (item.accessData && item.accessData.id !== undefined) {
      delete item.accessData.id;
    }

    if (item.paymentData && item.paymentData.id !== undefined) {
      delete item.paymentData.id;
    }

    if (item.reservationSettings && item.reservationSettings.id !== undefined) {
      delete item.reservationSettings.id;
    }

    onSave(item).then((result) => {
      processSaveResult(
        result,
        props.history,
        `${paths.accessManagementPath}/parking-lots`,
        setErrors
      );
    });
  };

  return (
    <div>
      {errors.length > 0 && (
        <div class="alert alert-danger" role="alert">
          <p>
            Server has returned the following errors that you must fix first!
          </p>
          <ul>
            {errors.map((x) => (
              <li>{x}</li>
            ))}
          </ul>
        </div>
      )}
      <p>
        You have filled in all of the data that is required for the parking lot
        to be save. Now you can save the data.
      </p>
      <div class="action-buttons">
        <div class="row">
          <PrimaryButton onClick={() => onCancel()} value="Cancel" />
          <PrimaryButton onClick={() => onBack()} value="Back" />
          <PrimaryButton onClick={() => handleSaveAction()} value="Save" />
        </div>
      </div>
    </div>
  );
}

export default ParkingLotFinalStepForm;
