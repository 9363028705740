import React from "react";
import { withRouter } from "react-router-dom";
import GenericModifyPage from "../genericModifyPage/GenericModifyPage";
import { ParkingLotCrudConfig } from "./ParkingLotCrudConfig";

import "./ModifyParkingLotPage.scss";

function ModifyParkingLotPage(props) {
  const { id } = props.match.params;

  return (
    <GenericModifyPage
      id={id}
      getTitle={ParkingLotCrudConfig.getTitle}
      fetch={ParkingLotCrudConfig.fetch}
      save={ParkingLotCrudConfig.save}
      indexPage={ParkingLotCrudConfig.indexPage}
      itemForm={ParkingLotCrudConfig.itemForm}
      validate={ParkingLotCrudConfig.validate}
    />
  );
}

export default withRouter(ModifyParkingLotPage);
