import React, { useEffect, useState } from "react";
import { getParkingLotOccupancyData, getParkingLotOccupancyDataCount } from "../../utilities/apiUtils";
import {
  getUserName,
  getAccessTypeName,
  getRegistrationTypeName,
  getUserRoleName,
} from "../../utilities/itemUtils";
import "./ParkingLotsOccupancyTab.scss";
import "../../styles/DataTable.scss";
import PaginatedTable from "../../components/table/PaginatedTable";
import { formatUnixTimeStampSeconds } from "../../utilities/timeUtils";

function ParkingLotsOccupancyTab(props) {
  const [occupancyData, setOccupancyData] = useState([]);

  const countLoader = () => {
    return getParkingLotOccupancyDataCount({
      parkingLotId: props.parkingLotId,
    });
  };

  const itemsLoader = (skip, limit) => {
    return getParkingLotOccupancyData({
      parkingLotId: props.parkingLotId,
    }, skip, limit);
  };

  const getRow = (item) => {
    return (
      <tr>
        <th>{getUserName(item.user)}</th>
        <th>{getUserRoleName(item.user.userRole)}</th>
        <th>{item.licensePlateNumber}</th>
        <th>{formatUnixTimeStampSeconds(item.entryTimeUtcUnixTimestamp)}</th>
        <th>
          {item.exitTimeUtcUnixTimestamp > 0
            ? formatUnixTimeStampSeconds(item.exitTimeUtcUnixTimestamp)
            : "-"}
        </th>
        <th>
          {item.expiryTimeUtcUnixTimestamp > 0
            ? formatUnixTimeStampSeconds(item.expiryTimeUtcUnixTimestamp)
            : "-"}
        </th>
        <th>{getAccessTypeName(item.entryType)}</th>
        <th>{getAccessTypeName(item.exitType)}</th>
        <th>{getRegistrationTypeName(item.user)}</th>
        <th>
          {item.user.driverGroup.name != "" ? item.user.driverGroup.name : "-"}
        </th>
      </tr>
    );
  };

  return <PaginatedTable
          headers={[
            <th>Name</th>,
            <th>Role</th>,
            <th>License plate number</th>,
            <th>Entry time</th>,
            <th>Exit time</th>,
            <th>Expiration time</th>,
            <th>Entry type</th>,
            <th>Exit type</th>,
            <th>Registration type</th>,
            <th>Driver group</th>,
          ]}
          generateRow={getRow}
          items={occupancyData}
          countLoader={countLoader}
          itemsLoader={itemsLoader}
          noItemsText={"There is no occupancy data right now regarding this parking lot."}
        />;
}

export default ParkingLotsOccupancyTab;
