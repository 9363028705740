import React from "react";
import { withRouter } from "react-router-dom";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pageTitle/PageTitle";
import Sidebar from "../../components/sidebar/Sidebar";

import "./BillingPage.scss";

function BillingPage(props) {
  return (
    <>
      <Header />

      <div className="content-page">
        <div class="container-fluid">
          <div class="row">
            <Sidebar />
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10">
              <PageTitle value="Billing" />
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(BillingPage);
