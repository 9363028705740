import React, { useState, useEffect } from "react";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import EditForm from "../../components/field/EditForm";
import DriverPaymentConfigurationForm from "../../components/payments/DriverPaymentsConfigurationForm";
import { getDriverPaymentSettings } from "../../utilities/apiUtils";
import SpinnerImage from "../../assets/images/spinner.gif";
import { paymentStrategies } from "../../utilities/constants";

function DriverGroupPaymentSettingsForm(props) {
  const [item, onChanged, onCancel, onNext, onBack] = [
    props.item,
    props.onChanged,
    props.onCancel,
    props.onNext,
    props.onBack,
  ];

  const [settings, setSettings] = useState({});
  const [isValid, setIsValid] = useState(true);
  const [validationCounter, setValidationCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (item.pricing == undefined) {
      setLoading(true);
      getDriverPaymentSettings().then((res) => {
        if (res.data) {
          item.pricing = res.data;
        } else {
          item.pricing = {
            price: {
              amount: 0.0,
              currency: 'USD',
            },
            strategy: paymentStrategies.Free,
            priceSpecified: [],
          };
        }
        setSettings(item.pricing);
        onChanged(item);
        setLoading(false);
      });
    } else {
      setSettings(item.pricing);
    }
    
  }, [item.pricing]);

  const processSettingsChanged = (newSettings, isValid) => {
    setSettings(newSettings);
    setIsValid(isValid);
  };

  const handleOnNext = () => {
    setValidationCounter(validationCounter + 1);
    return isValid;
  };

  const formComponents = [
    <DriverPaymentConfigurationForm
      settings={settings}
      onChanged={processSettingsChanged}
      validationCounter={validationCounter}
    />
  ];

  const form = (
    <EditForm
      components={formComponents}
      continueAction={<PrimaryButton value="Next" />}
      cancelAction={<PrimaryButton onClick={() => onCancel()} value="Cancel" />}
      backAction={<PrimaryButton onClick={() => onBack()} value="Back" />}
      onValidated={(result) => {
        const handleResult = handleOnNext();
        if (result && handleResult) {
          onNext();
        }
      }}
    />
  );

  return <>
    { loading && <div class="alert alert-primary"><img src={SpinnerImage} className="spinner-image" /> Loading...</div> }
    { !loading && <div>{form}</div> }
  </>;
}

export default DriverGroupPaymentSettingsForm;
