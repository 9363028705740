import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pageTitle/PageTitle";
import Sidebar from "../../components/sidebar/Sidebar";
import { getPayments } from "../../utilities/apiUtils";
import InvoicesContainer from "../revenueManagementInvoices/InvoicesContainer";

import "./PaymentsPage.scss";

function PaymentsPage(props) {
  const [invoices, setInvoices] = useState(null);

  useEffect(() => {
    loadInvoices();
  }, []);

  const loadInvoices = () => {
    getPayments().then((res) => {
      if (res.data) {
        setInvoices(res.data);
      }
    });
  };

  return (
    <>
      <Header />
      <div className="content-page">
        <div class="container-fluid">
          <div class="row">
            <Sidebar />
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10">
              <PageTitle value="Payments" />
              <InvoicesContainer
                invoicesLoader={loadInvoices}
                invoices={invoices}
                isRestricted={true}
              />
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(PaymentsPage);
