import "./PrimaryInputField.scss";

export default function PrimaryInputField(props) {
  const {
    type,
    placeholder,
    value,
    onChange,
    required,
    autofocus,
    style,
    className,
    onKeyPress,
  } = props;

  return (
    <input
      type={type}
      value={value}
      style={style}
      placeholder={placeholder}
      onChange={(event) => onChange(event)}
      required={required}
      autofocus={autofocus}
      className={`alot-primary-input ${className}`}
      onKeyPress={onKeyPress}
    />
  );
}
