import React, { useState, useEffect } from "react";
import EditImage from "../../assets/images/edit.svg";
import DeleteImage from "../../assets/images/delete.svg";
import { withRouter, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { paths } from "../../utilities/constants";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import {
  deleteDriver,
  getDriverGroupHeaders,
  getDrivers,
} from "../../utilities/apiUtils";
import CommonModal from "../../components/commonModal/CommonModal";
import Table from "../../components/table/Table";
import { Button } from "react-bootstrap";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import {
  getDriverName,
  getDriverLicensePlates,
  getDriverCompany,
  getDriverGroup,
} from "../../utilities/itemUtils";
import "./DriversPage.scss";
import CreateItemButton from "../../components/createItemButton/CreateItemButton";
import PageTitle from "../../components/pageTitle/PageTitle";
import SecondaryButton from "../../components/secondaryButton/SecondaryButton";
import ActionButton from "../../components/actionButton/ActionButton";

function DriversPage(props) {
  const [drivers, setDrivers] = useState(null);
  const [driverToBeDeleted, setDriverToBeDeleted] = useState(null);
  const [driverGroups, setDriverGroups] = useState(null);

  useEffect(() => {
    loadDrivers();
    loadDriverGroups();
  }, []);

  const loadDrivers = () => {
    getDrivers().then((res) => {
      if (res.data) {
        setDrivers(res.data);
      }
    });
  };

  const loadDriverGroups = () => {
    getDriverGroupHeaders().then((res) => {
      if (res.data) {
        setDriverGroups(res.data);
      }
    });
  };

  const handleDriverDelete = () => {
    deleteDriver(driverToBeDeleted).then(() => {
      toggleDeleteModal();
      loadDrivers();
    });
  };

  const toggleDeleteModal = (id = null) => {
    setDriverToBeDeleted(id);
  };

  const toggleEdit = (id = null) => {
    props.history.push(`${paths.accessManagementPath}/drivers/${id}`);
  };

  const getDriversRow = (driver) => {
    return (
      <tr>
        <td>{`${getDriverName(driver)}`}</td>
        <td className="license-plate-number-cell">
          {getDriverLicensePlates(driver).map((number) => (
            <span className="license-plate-number">{number}</span>
          ))}
        </td>
        <td>{`${getDriverGroup(driver, driverGroups)}`}</td>
        <td>{`${getDriverCompany(driver)}`}</td>
        <td>
          <img
            alt="Edit"
            title="Edit"
            onClick={() => {
              toggleEdit(driver.driver.id);
            }}
            src={EditImage}
            className="action"
          />

          <img
            alt="Delete"
            title="Delete"
            onClick={() => {
              toggleDeleteModal(driver.driver.id);
            }}
            src={DeleteImage}
            className="action"
          />
        </td>
      </tr>
    );
  };

  return (
    <>
      <Header />
      <CommonModal
        show={!!driverToBeDeleted}
        onClose={toggleDeleteModal}
        body="Are you sure you want to delete this driver?"
        title="Driver removal"
        footer={
          <div>
            <SecondaryButton
              value="Cancel"
              onClick={() => toggleDeleteModal()}
            />
            <ActionButton value="Delete" onClick={() => handleDriverDelete()} />
          </div>
        }
      />

      <div className="content-page">
        <div class="container-fluid">
          <div class="row">
            <Sidebar />
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10">
              <PageTitle value="Drivers" />
              <CreateItemButton
                path={`${paths.accessManagementPath}/drivers/new`}
                value="Create new driver"
              />
              {drivers && !drivers.length && (
                <div class="alert alert-primary">
                  Currently there are no drivers created.
                </div>
              )}
              {drivers && !!drivers.length && (
                <>
                  <Table
                    headers={[
                      <th>Driver</th>,
                      <th>License plate number(s)</th>,
                      <th>Driver group</th>,
                      <th>Company</th>,
                      <th>Actions</th>,
                    ]}
                    generateRow={getDriversRow}
                    items={drivers}
                  />
                </>
              )}
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(DriversPage);
