import React, { useContext, useState } from "react";
import GlobalContext from "../../utilities/context/GlobalContext";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { logout } from "../../utilities/authUtils";
import Logo from "../../components/logo/Logo";
import { Dropdown } from "react-bootstrap";

import "./Header.scss";
import { getToken } from "../../utilities/localStorageUtils";
import { logoutUser } from "../../utilities/apiUtils";
import { getUserRoleName } from "../../utilities/itemUtils";

function Header(props) {
  const context = useContext(GlobalContext);
  const currentUser = get(context, "state.user", "");
  const currentUserEmail = get(context, "state.user.user.email", "");
  const isMobileMenuOpen = get(context, "state.isMobileMenuOpen", false);
  const handleLogout = () => {
    const token = getToken();
    context.updateStateValue("user", null);
    logout();
    logoutUser(token);
  };

  const toggleMobileMenu = () => {
    context.updateStateValue("isMobileMenuOpen", !isMobileMenuOpen);
  };

  return (
    <>
      <header className="header">
        <div class="align-items-center bg-white header-row">
          <Link to="/">
            <Logo />
          </Link>
          <div className="menus">
            {!!currentUser && (
              <>
                <div
                  className={`mobile-menu ${
                    !!isMobileMenuOpen && "active-toggle"
                  }`}
                  onClick={() => toggleMobileMenu()}
                >
                  <div class="menu_toggle">
                    <h3 class="menu-toggle-main">
                      <div class="menu-toggle-main-ins">
                        <div class="bar bar1"></div>
                        <div class="bar bar2"></div>
                        <div class="bar bar3"></div>
                      </div>
                    </h3>
                  </div>
                </div>

                <Dropdown>
                  <Dropdown.Toggle>
                    {currentUserEmail ? currentUserEmail[0] : ""}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item disabled={true}>
                      <strong>
                        {getUserRoleName(currentUser.roleData.role)}
                      </strong>
                      <br />
                      {currentUserEmail}
                      <hr />
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleLogout()}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
