import GlobalContext from "./GlobalContext";
import React, { Component } from "react";

class GlobalProvider extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      user: "",
      openedMenus: [],
      activeLink: "",
      isMobileMenuOpen: false,
    };
    this.state = { ...this.initialState };
  }

  updateValue = (key, val) => {
    this.setState({ [key]: val });
  };

  resetState = () => {
    this.setState({ ...this.initialState });
  };

  render() {
    return (
      <GlobalContext.Provider
        value={{
          state: this.state,
          updateStateValue: this.updateValue,
          resetState: this.resetState,
        }}
      >
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}

export default GlobalProvider;
