import React, { useState, useEffect } from "react";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import GoogleMap from "../../components/map/GoogleMap";
import Marker from "../../components/map/Marker";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { getParkingLotsAvailableForReservation } from "../../utilities/apiUtils";
import { find, remove } from "lodash";

function ReservationMapForm(props) {
  const [itemEdited, onChanged, onCancel, onNext] = [
    props.item,
    props.onChanged,
    props.onCancel,
    props.onNext,
  ];
  const [parkingLots, setParkingLots] = useState([]);
  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [positionSelected, setPositionSelected] = useState({ selected: false });
  const [mapCenter, setMapCenter] = useState([34.0522, -118.2437]);
  const [zoom, setZoom] = useState(10);
  const [parkingLotSelectionError, setParkingLotSelectionError] =
    useState(false);

  useEffect(() => {
    loadParkingLots();
  }, [itemEdited]);

  const loadParkingLots = () => {
    getParkingLotsAvailableForReservation().then((res) => {
      if (res.data) {
        setParkingLots(res.data);
        markSelectedParkingLot(res.data);
      }
    });
  };

  const markSelectedParkingLot = (items) => {
    if (itemEdited.parkingLotId == undefined || itemEdited.parkingLotId == "") {
      return;
    }

    const item = find(items, (x) => x.id == itemEdited.parkingLotId);
    if (item) {
      handleOnSelect(item);
    }
  };

  const handleOnSelect = (item) => {
    setPositionSelected({
      selected: true,
      key: item.id,
      text: item.name.trim().charAt(0).toUpperCase(),
      lat: item.latitude,
      lng: item.longitude,
      name: item.name,
      address: item.address,
    });
    setMapCenter([item.latitude, item.longitude]);
    setZoom(14);

    itemEdited.parkingLotId = item.id;
    onChanged(itemEdited);
  };

  const formatResult = (item) => {
    return item;
  };

  const apiHasLoaded = (map, maps) => {
    setMapApiLoaded(true);
  };

  const handleNext = () => {
    if (itemEdited.parkingLotId == undefined || itemEdited.parkingLotId == "") {
      setParkingLotSelectionError(true);
    } else {
      setParkingLotSelectionError(false);
      onNext();
    }
  };

  return (
    <div>
      <div class="form-group">
        {parkingLotSelectionError && (
          <div class="alert alert-danger" role="alert">
            You must select the parking lot first!
          </div>
        )}
        <div style={{ height: "50vh", width: "100%" }}>
          <GoogleMap
            defaultZoom={10}
            defaultCenter={[34.0522, -118.2437]}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
            center={mapCenter}
            zoom={zoom}
          >
            {positionSelected.selected && (
              <Marker
                key={positionSelected.key}
                text={positionSelected.text}
                lat={positionSelected.lat}
                lng={positionSelected.lng}
              />
            )}
          </GoogleMap>
        </div>
        <hr />
        {mapApiLoaded && (
          <ReactSearchAutocomplete
            items={parkingLots}
            onSelect={handleOnSelect}
            autoFocus
            formatResult={formatResult}
          />
        )}
        {positionSelected.selected && (
          <div>
            <strong>Selected</strong>
            <br />
            {positionSelected.name}, {positionSelected.address}
          </div>
        )}
      </div>
      <div class="action-buttons">
        <div class="row">
          <PrimaryButton onClick={() => onCancel()} value="Cancel" />
          <PrimaryButton onClick={() => handleNext()} value="Next" />
        </div>
      </div>
    </div>
  );
}

export default ReservationMapForm;
