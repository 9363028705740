import React, { useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { paths } from "../../utilities/constants";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import { deleteDriverGroup, getDriverGroups } from "../../utilities/apiUtils";
import CommonModal from "../../components/commonModal/CommonModal";
import { Button } from "react-bootstrap";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import Table from "../../components/table/Table";
import EditImage from "../../assets/images/edit.svg";
import DeleteImage from "../../assets/images/delete.svg";
import "./DriverGroupsPage.scss";
import CreateItemButton from "../../components/createItemButton/CreateItemButton";
import PageTitle from "../../components/pageTitle/PageTitle";
import SecondaryButton from "../../components/secondaryButton/SecondaryButton";
import ActionButton from "../../components/actionButton/ActionButton";

function DriverGroupsPage(props) {
  const [driverGroups, setDriverGroups] = useState(null);
  const [driverGroupToDeleteId, setDriverGroupToDeleteId] = useState(null);

  useEffect(() => {
    loadDriverGroups();
  }, []);

  const loadDriverGroups = () => {
    getDriverGroups().then((res) => {
      if (res.data) {
        setDriverGroups(res.data);
      }
    });
  };

  const handleDriverGroupDelete = () => {
    deleteDriverGroup(driverGroupToDeleteId).then(() => {
      toggleDeleteModal();
      loadDriverGroups();
    });
  };

  const toggleDeleteModal = (id = null) => {
    setDriverGroupToDeleteId(id);
  };

  const toggleEdit = (id = null) => {
    props.history.push(`${paths.multiUserManagementPath}/driver-groups/${id}`);
  };

  const getDriverGroupRow = (driverGroup) => {
    let accessControl = driverGroup.accessControl || [];
    accessControl = accessControl.join(", ");
    return (
      <tr>
        <td>{driverGroup.name}</td>
        <td>{accessControl || "-"}</td>

        <td>
          <img
            alt="Edit"
            title="Edit"
            onClick={() => {
              toggleEdit(driverGroup.id);
            }}
            src={EditImage}
            className="action"
          />

          <img
            alt="Delete"
            title="Delete"
            onClick={() => {
              toggleDeleteModal(driverGroup.id);
            }}
            src={DeleteImage}
            className="action"
          />
        </td>
      </tr>
    );
  };

  return (
    <>
      <Header />
      <CommonModal
        show={!!driverGroupToDeleteId}
        onClose={toggleDeleteModal}
        body="Are you sure you want to delete this driver group?"
        title="Driver group deletion"
        footer={
          <div>
            <SecondaryButton
              value="Cancel"
              onClick={() => toggleDeleteModal()}
            />
            <ActionButton
              value="Delete"
              onClick={() => handleDriverGroupDelete()}
            />
          </div>
        }
      />

      <div className="content-page">
        <div class="container-fluid">
          <div class="row">
            <Sidebar />
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10">
              <PageTitle value="Driver groups" />
              <CreateItemButton
                path={`${paths.multiUserManagementPath}/driver-groups/new`}
                value="Create new driver group"
              />
              {driverGroups && !driverGroups.length && (
                <div class="alert alert-primary">
                  Currently there are no driver groups created.
                </div>
              )}
              {driverGroups && !!driverGroups.length && (
                <Table
                  headers={[
                    <th>Driver group</th>,
                    <th>Access control</th>,
                    <th>Actions</th>,
                  ]}
                  generateRow={getDriverGroupRow}
                  items={driverGroups}
                />
              )}
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(DriverGroupsPage);
