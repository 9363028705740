import { getReservation, saveReservation } from "../../utilities/apiUtils";
import { paths } from "../../utilities/constants";
import ReservationForm from "./ReservationForm";

export const ReservationCrudConfig = {
  getTitle: (item) => {
    if (!item.id) {
      return "Create new reservation";
    }

    return `Edit reservation`;
  },

  fetch: (id) => {
    return getReservation(id);
  },

  save: (item) => {
    return saveReservation(item);
  },

  indexPage: (item) => {
    return `${paths.accessManagementPath}/reservations`;
  },

  itemForm: <ReservationForm />,
};
