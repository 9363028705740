export const expectValueSet = (value, errorText) => {
  if (value == undefined || value.id == undefined || !value.id) {
    return {
      success: false,
      text: errorText,
    };
  }

  return {
    success: true,
    text: "",
  };
};
