import { find } from "lodash";
import { getDateFromDatabaseTime, isDateBeforeToday } from "./timeUtils";

export const getDriverName = (driver) => {
  return getUserName(driver.driver);
};

export const getUserName = (item) => {
  if (item.middleName) {
    return item.firstName + " " + item.middleName + " " + item.lastName;
  }

  return item.firstName + " " + item.lastName;
};

export const getDriverLicensePlates = (driver) => {
  const driverItem = driver.driver;

  return driverItem.licensePlates.map((x) => x.number);
};

export const getCompany = (company) => {
  return `${company.name} (${company.address})`;
};

export const getDriverCompany = (driver) => {
  return getCompany(driver.driver.company);
};

export const getDriverGroup = (driver, driverGroups) => {
  const id = driver.driver.driverGroupId;
  const group = find(driverGroups, (x) => x.id == id);

  if (group) {
    return group.name;
  }

  return "-";
};

export const getAccessTypeName = (type) => {
  switch (type) {
    case "app":
      return "Backend";
    case "lpr":
      return "License plate recognition";
    case "bluetooth":
      return "Bluetooth";
    case "manual":
      return "Manual";
  }

  return "-";
};

export const getRegistrationTypeName = (user) => {
  if (!user.registrationOrigin) {
    return "Website";
  }

  switch (user.registrationOrigin) {
    case "web":
      return "Website";
    case "mobile":
      return "Mobile App";
  }

  return "Unknown";
};

export const getUserRoleName = (role) => {
  switch (role) {
    case "master-user":
      return "Master user";
    case "super-admin":
      return "Super admin";
    case "admin":
      return "Admin";
    case "sub-admin":
      return "Sub-admin";
    case "driver":
      return "Driver";
  }

  return "-";
};

export const getMoney = (value) => {
  const amountValue = value.amount.toFixed(2);
  if (value.currency == "USD") {
    return `\$${amountValue}`;
  }

  return `NaN ${amountValue}`;
};

export const getInvoiceStatus = (invoice) => {
  if (invoice.paid) {
    return "Paid";
  }

  const date = getDateFromDatabaseTime(invoice.deadline);
  if (isDateBeforeToday(date)) {
    return "Overdue";
  }

  return "Not paid";
};

const _prepareItemsById = (elements, idFieldValueSelector) => {
  const length = elements.length;
  const itemsById = {};
  for (let i = 0; i < length; i++) {
    const element = elements[i];
    const record = {
      element: JSON.parse(JSON.stringify(element)),
      children: [],
    };

    itemsById[idFieldValueSelector(element)] = record;
  }

  return itemsById;
};

export const wrapInHierarchy = (
  elements,
  idFieldValueSelector,
  parentFieldValueSelector
) => {
  const itemsById = _prepareItemsById(elements, idFieldValueSelector);
  while (true) {
    let treeUpdated = false;
    const ids = [];
    for (const [_, value] of Object.entries(itemsById)) {
      const parentId = parentFieldValueSelector(value.element);
      const id = idFieldValueSelector(value.element);
      if (parentId != "") {
        if (parentId in itemsById) {
          itemsById[parentId].children.push(value);
          ids.push(id);

          treeUpdated = true;
        }
      }
    }

    const length = ids.length;
    for (let i = 0; i < length; i++) {
      const id = ids[i];
      delete itemsById[id];
    }

    if (!treeUpdated) {
      break;
    }
  }

  const result = [];
  for (const [_, value] of Object.entries(itemsById)) {
    result.push(value);
  }

  return result;
};
