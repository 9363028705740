import { getDriver, saveDriver } from "../../utilities/apiUtils";
import { paths } from "../../utilities/constants";
import { getDriverName } from "../../utilities/itemUtils";
import DriverForm from "./DriverForm";

export const DriverCrudConfig = {
  getTitle: (item) => {
    if (!item.driver || !item.driver.id) {
      return "Create new driver";
    }

    return `Edit driver "${getDriverName(item)}"`;
  },

  fetch: (id) => {
    return getDriver(id);
  },

  save: (item) => {
    return saveDriver(item);
  },

  indexPage: (item) => {
    return `${paths.accessManagementPath}/drivers`;
  },

  itemForm: <DriverForm />,
};
