import React from "react";
import { withRouter } from "react-router-dom";
import FormTabs from "../genericModifyPage/FormTabs";
import DriverGroupAccessPermissionsForm from "./DriverGroupAccessPermissionsForm";
import DriverGroupDetailsForm from "./DriverGroupDetailsForm";
import DriverGroupFinalStepForm from "./DriverGroupFinalStepForm";
import DriverGroupPaymentSettingsForm from "./DriverGroupPaymentSettingsForm";
import DriverGroupPricingForm from "./DriverGroupPricingForm";

function DriverGroupForm(props) {
  const [item, onChanged, onSave, onCancel] = [
    props.item,
    props.onChanged,
    props.onSave,
    props.onCancel,
  ];

  return (
    <FormTabs
      item={item}
      onChanged={onChanged}
      onSave={onSave}
      onCancel={onCancel}
      history={props.history}
      pageProps={props.pageProps}
      tabs={[
        {
          title: "Details",
          body: <DriverGroupDetailsForm />,
        },
        {
          title: "Access permissions",
          body: <DriverGroupAccessPermissionsForm />,
        },
        {
          title: "Charging methods",
          body: <DriverGroupPricingForm />,
        },
        {
          title: "Payment settings",
          body: <DriverGroupPaymentSettingsForm />,
        },
        {
          title: "Final step",
          body: <DriverGroupFinalStepForm />,
        },
      ]}
    />
  );
}

export default withRouter(DriverGroupForm);
