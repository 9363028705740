import React, { useState, useEffect, useContext } from "react";
import { get, find, remove } from "lodash";
import EditForm from "../../components/field/EditForm";
import TextField from "../../components/field/TextField";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import {
  expectNotEmpty,
  passAllways,
} from "../../components/field/validation/TextFieldUtil";
import FieldsContainer from "../../components/field/FieldsContainer";
import { getParkingLotsForSharing } from "../../utilities/apiUtils";
import MultiComboBox from "../../components/field/MultiComboBox";
import ClientParkingLotsConfigurationForm from "./ClientParkingLotsConfigurationForm";
import { isPositiveNumber } from "../../utilities/numberUtils";
import GlobalContext from "../../utilities/context/GlobalContext";
import SecondaryButton from "../../components/secondaryButton/SecondaryButton";
import { roles, shareTypes } from "../../utilities/constants";

function ClientDetailsForm(props) {
  const context = useContext(GlobalContext);
  const currentUser = get(context, "state.user", "");
  const userRole = get(currentUser, "roleData.role", "");

  const [item, onChanged, onCancel, onNext, additionalProps] = [
    props.item,
    props.onChanged,
    props.onCancel,
    props.onNext,
    props.additionalProps,
  ];

  if (item.client == undefined) {
    item.client = {};
  }

  if (additionalProps.type == "sub-admin") {
    item.client.role = "sub-admin";
  } else {
    item.client.role = "admin";
  }

  if (item.client.company == undefined) {
    item.client.company = {};
  }

  if (item.shares == undefined) {
    item.shares = [];
  }

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [taxId, setTaxId] = useState("");
  const [vatId, setVatId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [parkingLots, setParkingLots] = useState([]);
  const [sharesInitial, setSharesInitial] = useState([]);
  const [parkingLotsSelected, setParkingLotsSelected] = useState([]);
  const [emailFieldDisabled, setEmailFieldDisabled] = useState(false);
  const [numberOfDrivers, setNumberOfDrivers] = useState(0);
  const [shareType, setShareType] = useState(
    additionalProps.type == "sub-admin"
      ? shareTypes.ViewOnly
      : shareTypes.Administrator
  );
  const [lotConfigErrors, setLotConfigErrors] = useState({});

  useEffect(() => {
    loadParkingLots();
  }, []);

  useEffect(() => {
    setFirstName(item.client.firstName);
    setLastName(item.client.lastName);
    setEmail(item.email);
    setEmailFieldDisabled(
      item.email != undefined && item.email != "" && item.client.id != undefined
    );
    if (sharesInitial.length == 0) {
      setSharesInitial(
        item.shares.map((x) => {
          return {
            id: x.id,
            name: x.name,
            parkingSpaces: x.parkingSpaces,
            entityType: x.entityType,
            sharingId: x.sharingId,
          };
        })
      );
    }
    setParkingLotsSelected(
      item.shares.map((x) => {
        return {
          id: x.id,
          name: x.name,
          parkingSpaces: x.parkingSpaces,
          entityType: x.entityType,
          sharingId: x.sharingId,
        };
      })
    );
    setCompanyName(item.client.company.name);
    setTaxId(item.client.company.taxId);
    setVatId(item.client.company.vatId);
    setPhoneNumber(item.client.company.phoneNumber);
    setAddress(item.client.company.address);
    setPaymentDetails(item.client.company.paymentDetails);
    setNumberOfDrivers(item.client.numDrivers);
    if (item.shares.length > 0) {
      setShareType(item.shares[0].entityType);
    } else {
      setShareType(
        additionalProps.type == "sub-admin"
          ? shareTypes.ViewOnly
          : shareTypes.Administrator
      );
    }
  }, [item]);

  const loadParkingLots = () => {
    getParkingLotsForSharing().then((res) => {
      if (res.data) {
        setParkingLots(
          res.data.map((x) => {
            return {
              id: x.id,
              name: x.name,
            };
          })
        );
      }
    });
  };

  const changeFirstName = (value) => {
    item.client.firstName = value;
    setFirstName(value);
    onChanged(item);
  };

  const changeLastName = (value) => {
    item.client.lastName = value;
    setLastName(value);
    onChanged(item);
  };

  const changeEmail = (value) => {
    item.email = value;
    setEmail(value);
    onChanged(item);
  };

  const changeCompanyName = (value) => {
    item.client.company.name = value;
    setCompanyName(value);
    onChanged(item);
  };

  const changeTaxId = (value) => {
    item.client.company.taxId = value;
    setTaxId(value);
    onChanged(item);
  };

  const changeVatId = (value) => {
    item.client.company.vatId = value;
    setVatId(value);
    onChanged(item);
  };

  const changePhoneNumber = (value) => {
    item.client.company.phoneNumber = value;
    setPhoneNumber(value);
    onChanged(item);
  };

  const changeAddress = (value) => {
    item.client.company.address = value;
    setAddress(value);
    onChanged(item);
  };

  const changePaymentDetails = (value) => {
    item.client.company.paymentDetails = value;
    setPaymentDetails(value);
    onChanged(item);
  };

  const changeNumDrivers = (value) => {
    item.client.numDrivers = parseInt(value);
    setNumberOfDrivers(value);
    onChanged(item);
  };

  const changeShareType = (value) => {
    const currentLots = parkingLotsSelected.map((x) => {
      x.entityType = value;
      return x;
    });

    item.shares = currentLots;
    setShareType(value);
    setParkingLotsSelected([...currentLots]);
    onChanged(item);
  };

  const handleOnNext = () => {
    const errors = {};
    let hasErrors = false;
    parkingLotsSelected.forEach((el) => {
      let errorText = "";
      const valueAsString = el.parkingSpaces.toString();
      if (!isPositiveNumber(valueAsString)) {
        errorText =
          "Number of parking spaces must be a positive greater than 0.";
      } else if (valueAsString.indexOf(".") !== -1) {
        errorText = "Number of parking spaces must be a natural number.";
      }

      if (errorText != "") {
        errors[el.id] = errorText;

        hasErrors = true;
      }
    });

    setLotConfigErrors(errors);

    if (!hasErrors) {
      item.shares = item.shares.map((x) => {
        x.parkingSpaces = parseInt(x.parkingSpaces);

        return x;
      });
      onChanged(item);
    }

    return !hasErrors;
  };

  const companyFields = [
    <TextField
      key={1}
      value={companyName}
      placeholder="Enter company name"
      validationController={(value) =>
        expectNotEmpty(value, "Company name cannot be empty.")
      }
      onValueChanged={(value) => changeCompanyName(value)}
      label="Company name"
    />,
    <TextField
      key={2}
      value={taxId}
      placeholder="Enter tax ID"
      validationController={(value) =>
        expectNotEmpty(value, "Tax ID cannot be empty.")
      }
      onValueChanged={(value) => changeTaxId(value)}
      label="Tax ID"
    />,
    <TextField
      key={3}
      value={vatId}
      placeholder="Enter Vat ID"
      validationController={(value) => passAllways(value)}
      onValueChanged={(value) => changeVatId(value)}
      label="Vat ID (optional)"
    />,
    <TextField
      key={4}
      value={phoneNumber}
      placeholder="Phone number"
      validationController={(value) =>
        expectNotEmpty(value, "Phone number cannot be empty.")
      }
      onValueChanged={(value) => changePhoneNumber(value)}
      label="Enter phone number"
    />,
    <TextField
      key={5}
      value={address}
      placeholder="Enter address"
      validationController={(value) =>
        expectNotEmpty(value, "Address cannot be empty.")
      }
      onValueChanged={(value) => changeAddress(value)}
      label="Address"
    />,
    <TextField
      key={6}
      value={paymentDetails}
      placeholder="Enter payment details"
      validationController={(value) =>
        expectNotEmpty(value, "Payment details cannot be empty.")
      }
      onValueChanged={(value) => changePaymentDetails(value)}
      label="Payment details"
    />,
  ];

  const formComponents = [
    <TextField
      key={1}
      value={firstName}
      placeholder="Enter first name"
      validationController={(value) =>
        expectNotEmpty(value, "First name cannot be empty.")
      }
      onValueChanged={(value) => changeFirstName(value)}
      label="First name"
    />,
    <TextField
      key={2}
      value={lastName}
      placeholder="Enter last name"
      validationController={(value) =>
        expectNotEmpty(value, "Last name cannot be empty.")
      }
      onValueChanged={(value) => changeLastName(value)}
      label="Last name"
    />,
    <TextField
      key={3}
      value={email}
      placeholder="Enter email"
      validationController={(value) =>
        expectNotEmpty(value, "Email cannot be empty.")
      }
      onValueChanged={(value) => changeEmail(value)}
      label="Email"
      disabled={emailFieldDisabled}
    />,
    <FieldsContainer
      key={4}
      components={companyFields}
      label="Company details"
    />,
    <MultiComboBox
      key={5}
      values={parkingLotsSelected}
      label="Select parking lots"
      options={parkingLots}
      validationController={(values) => {
        if (values.length === 0) {
          return {
            success: false,
            text: "You must select at least one parking lot",
          };
        }

        return {
          success: true,
          text: "",
        };
      }}
      onValueTriggered={(value, ref, action) => {
        let currentLots = parkingLotsSelected;
        if (action == "delete") {
          remove(currentLots, (x) => x.id == value.id);
        } else {
          const parent = find(sharesInitial, (x) => x.id == value.id);
          if (find(currentLots, (x) => x.id == value.id)) {
            find(currentLots, (x) => x.id == value.id).parkingSpaces = 0;
            find(currentLots, (x) => x.id == value.id).sharingId = parent
              ? parent.sharingId
              : "";
          } else {
            currentLots.push({
              id: value.id,
              name: value.name,
              parkingSpaces: 0,
              entityType: shareType,
              sharingId: parent ? parent.sharingId : "",
            });
          }
        }

        currentLots = currentLots.map((x) => {
          x.entityType = shareType;
          return x;
        });
        item.shares = currentLots;
        setParkingLotsSelected([...currentLots]);
        onChanged(item);
      }}
    />,
    <ClientParkingLotsConfigurationForm
      key={6}
      parkingLotsSelected={parkingLotsSelected}
      lotConfigErrors={lotConfigErrors}
      onChanged={(lot, value) => {
        const currentLots = parkingLotsSelected;
        if (find(currentLots, (x) => x.id == lot.id)) {
          find(currentLots, (x) => x.id == lot.id).parkingSpaces = value;

          item.shares = currentLots;
          setParkingLotsSelected([...currentLots]);
          onChanged(item);
        }
      }}
    />,
    <TextField
      key={7}
      value={numberOfDrivers}
      placeholder="Enter maximum number of drivers for this client"
      validationController={(value) => {
        const valueAsString = value.toString();
        if (!isPositiveNumber(valueAsString)) {
          return {
            success: false,
            text: "Number of drivers must be a positive number.",
          };
        }

        if (valueAsString.indexOf(".") !== -1) {
          return {
            success: false,
            text: "Number of drivers must be a natural number.",
          };
        }

        if (parseInt(valueAsString) <= 0) {
          return {
            success: false,
            text: "Number of drivers must be greater than 0.",
          };
        }

        const valueInt = parseInt(valueAsString);
        const sumOfSpaces = parkingLotsSelected
          .map((x) => x.parkingSpaces)
          .reduce((a, b) => parseInt(a) + parseInt(b), 0);
        if (valueInt < sumOfSpaces) {
          return {
            success: false,
            text: "Number of drivers must be greater or equal to the sum of parking spaces in all of the lots selected.",
          };
        }

        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(value) => changeNumDrivers(value)}
      label="Number of drivers"
    />,
    <>
      {additionalProps.type == "sub-admin" && (
        <div class="form-group">
          <label>Permissions</label>
          <div className="row">
            <SecondaryButton
              onClick={() => {
                changeShareType(shareTypes.ViewOnly);
              }}
              className={shareType === shareTypes.ViewOnly && "active"}
              value="View only"
            />

            <SecondaryButton
              onClick={() => {
                changeShareType(shareTypes.Manager);
              }}
              className={shareType === shareTypes.Manager && "active"}
              value="Manager"
            />

            {userRole === roles.MasterUser || userRole === roles.SuperAdmin ? (
              <SecondaryButton
                onClick={() => {
                  changeShareType(shareTypes.Administrator);
                }}
                className={shareType === shareTypes.Administrator && "active"}
                value="Administrator"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>,
  ];

  const form = (
    <EditForm
      components={formComponents}
      continueAction={<PrimaryButton value="Next" />}
      cancelAction={<PrimaryButton onClick={() => onCancel()} value="Cancel" />}
      onValidated={(result) => {
        const handleResult = handleOnNext();
        if (result && handleResult) {
          onNext();
        }
      }}
    />
  );

  return <div>{form}</div>;
}

export default ClientDetailsForm;
