import { addToken, getToken, removeToken } from "./localStorageUtils";
import { loginUser, logoutUser } from "./apiUtils";
import { get } from "lodash";
import jwt from "jsonwebtoken";
import { tokenConstants } from "./constants";

export function logout() {
  removeToken();
}

export function login(data) {
  return loginUser(data).then((res) => {
    if (res.error) {
      return res.error;
    } else {
      const data = get(res, "data");
      addToken(data.token);
      return data;
    }
  });
}

export function verifyJwt(token) {
  return jwt.verify(
    token,
    tokenConstants.TOKEN_SECRET_VALUE,
    (err, decoded) => !!decoded
  );
}
