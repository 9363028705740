import React, { useState, useEffect } from "react";
import { driverPaymentStrategies, paymentStrategies } from "../../utilities/constants";
import SecondaryButton from "../secondaryButton/SecondaryButton";
import SecondaryInputField from "../secondaryInputField/SecondaryInputField";
import HourlyConfigurationForm from "./HourlyConfigurationForm";
import { isNumeric, countDecimals, isPositiveInteger } from "../../utilities/numberUtils";
import { priceIsValid, hoursValid } from "./PaymentsConfigUtil";

function DriverPaymentConfigurationForm(props) {
  const [onChanged, validationCounter] = [props.onChanged, props.validationCounter];
  const [settings, setSettings] = useState(props.settings || {});
  const [paymentPeriod, setPaymentPeriod] = useState(settings.strategy || paymentStrategies.Free);
  const [pricePerPeriodErrorText, setPricePerPeriodErrorText] = useState("");
  const [gracePeriodErrorText, setGracePeriodErrorText] = useState("");
  const [price, setPrice] = useState(settings.price || {});
  const [gracePeriodMinutes, setGracePeriodMinutes] = useState(settings.gracePeriodMinutes != undefined ? settings.gracePeriodMinutes.toString() : "");

  useEffect(() => {
    if (validationCounter < 1) {
      return;
    }

    const priceValue = price.amount;
    if (!isNumeric(priceValue)) {
      setPricePerPeriodErrorText("Price per period value must be a number.");
    } else if (parseFloat(priceValue) <= 0) {
      setPricePerPeriodErrorText("Price per period value must be greater than zero.");
    } else if (countDecimals(priceValue) > 2) {
      setPricePerPeriodErrorText("There can be no more than to digits after decimal.");
    } else {
      setPricePerPeriodErrorText("");
    }
    
    if (!isPositiveInteger(gracePeriodMinutes)) {
      setGracePeriodErrorText("Grace period value must be a positive integer.");
    } else if (parseInt(gracePeriodMinutes) <= 0) {
      setGracePeriodErrorText("Grace period value must be greater than zero.");
    } else {
      setGracePeriodErrorText("");
    }
  }, [validationCounter]);
  
  useEffect(() => {
    setSettings(props.settings);
    setPaymentPeriod(props.settings.strategy || paymentStrategies.Free);
    setPrice(props.settings.price || paymentStrategies.Free);
    setGracePeriodMinutes(props.settings.gracePeriodMinutes != undefined ? props.settings.gracePeriodMinutes.toString() : "");
  }, [props.settings]);

  const changePaymentPeriod = (value) => {
    const configValidNew = hoursValid(settings.priceSpecified) || value != paymentStrategies.HourlySpecified;
    const priceValidNew = priceIsValid(settings.price.amount) || value == paymentStrategies.HourlySpecified;
    const gracePeriodValidNew = isPositiveInteger(settings.gracePeriodMinutes) || (value != paymentStrategies.HourlySpecified && value != paymentStrategies.Hourly);
    const isValid = (priceValidNew && configValidNew && gracePeriodValidNew) || value == paymentStrategies.Free;

    settings.strategy = value;
    setSettings(settings);
    setPaymentPeriod(value);
    onChanged(settings, isValid);
  };

  const processHourlyConfiguration = (config, configValidNew) => {
    const gracePeriodValidNew = isPositiveInteger(settings.gracePeriodMinutes) || (settings.strategy != paymentStrategies.HourlySpecified && settings.strategy != paymentStrategies.Hourly);
    settings.priceSpecified = config;
    setSettings(settings);
    onChanged(settings, configValidNew && gracePeriodValidNew);
  };

  const processPricePerPeriodChange = (value) => {
    const priceValidNew = priceIsValid(value);
    const configValidNew = hoursValid(settings.priceSpecified) || settings.strategy != paymentStrategies.HourlySpecified;
    const gracePeriodValidNew = isPositiveInteger(settings.gracePeriodMinutes) || (settings.strategy != paymentStrategies.HourlySpecified && settings.strategy != paymentStrategies.Hourly);
    
    const price = {
      amount: value,
      currency: 'USD',
    };
    settings.price = price;
    setSettings(settings);
    setPrice(price);
    onChanged(settings, priceValidNew && configValidNew && gracePeriodValidNew);
  };

  const processGracePeriodChange = (value) => {
    const priceValidNew = priceIsValid(settings.price.amount) || settings.strategy == paymentStrategies.HourlySpecified;
    const gracePeriodValidNew = isPositiveInteger(value);
    
    settings.gracePeriodMinutes = value;
    setSettings(settings);
    setGracePeriodMinutes(value);
    onChanged(settings, priceValidNew && gracePeriodValidNew);
  };

  return (
    <>
      <div className="form-group">
        <label>Payment period</label>
        <div className="row">
          {
            driverPaymentStrategies.map((value) => <SecondaryButton
            onClick={() => {
              changePaymentPeriod(value.key);
            }}
            className={paymentPeriod === value.key && "active"}
            value={value.name}
          />)
          }
        </div>
      </div>
      {
        paymentPeriod != paymentStrategies.Free && paymentPeriod != paymentStrategies.HourlySpecified &&
        <>
          <div className="form-group">
            <label>Price per period ($)</label>
            <SecondaryInputField
              value={price.amount}
              onChange={(event) => processPricePerPeriodChange(event.target.value)}
              className={`form-control ${pricePerPeriodErrorText != "" ? "is-invalid" : ""}`}
            />
            {pricePerPeriodErrorText != "" && <div style={{ display: "block" }} className="invalid-feedback">{pricePerPeriodErrorText}</div>}
          </div>
        </>
      }
      {
        (paymentPeriod == paymentStrategies.Hourly || paymentPeriod == paymentStrategies.HourlySpecified) &&
        <>
          <div className="form-group">
            <label>Grace period (minutes)</label>
            <SecondaryInputField
              value={gracePeriodMinutes}
              onChange={(event) => processGracePeriodChange(event.target.value)}
              className={`form-control ${gracePeriodErrorText != "" ? "is-invalid" : ""}`}
            />
            {gracePeriodErrorText != "" && <div style={{ display: "block" }} className="invalid-feedback">{gracePeriodErrorText}</div>}
          </div>
        </>
      }
      {
        paymentPeriod == paymentStrategies.HourlySpecified &&
        <HourlyConfigurationForm
          prices={settings.priceSpecified}
          validationCounter={validationCounter}
          onChanged={processHourlyConfiguration}
        />
      }
    </>
  );
}

export default DriverPaymentConfigurationForm;
