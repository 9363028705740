import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { paths } from "../../utilities/constants";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import { deleteParkingLot, getParkingLots } from "../../utilities/apiUtils";
import CommonModal from "../../components/commonModal/CommonModal";
import Table from "../../components/table/Table";
import EditImage from "../../assets/images/edit.svg";
import DeleteImage from "../../assets/images/delete.svg";
import ViewImage from "../../assets/images/view.svg";
import GlobalContext from "../../utilities/context/GlobalContext";
import { get } from "lodash";
import CreateItemButton from "../../components/createItemButton/CreateItemButton";
import PageTitle from "../../components/pageTitle/PageTitle";
import SecondaryButton from "../../components/secondaryButton/SecondaryButton";
import ActionButton from "../../components/actionButton/ActionButton";
import { wrapInHierarchy } from "../../utilities/itemUtils";

function ParkingLotsPage(props) {
  const context = useContext(GlobalContext);
  const currentUser = get(context, "state.user", "");
  const [parkingLots, setParkingLots] = useState(null);
  const [parkingLotToBeDeleted, setParkingLotToBeDeleted] = useState(null);

  useEffect(() => {
    loadParkingLots();
  }, []);

  const loadParkingLots = () => {
    getParkingLots().then((res) => {
      if (res.data) {
        setParkingLots(
          wrapInHierarchy(
            res.data,
            (el) => el.id,
            (el) => el.parentId
          )
        );
      }
    });
  };

  const handleParkingLotDelete = () => {
    deleteParkingLot(parkingLotToBeDeleted).then(() => {
      toggleDeleteModal();
      loadParkingLots();
    });
  };

  const toggleDeleteModal = (id = null) => {
    setParkingLotToBeDeleted(id);
  };

  const toggleEdit = (id = null) => {
    props.history.push(`${paths.accessManagementPath}/parking-lots/${id}`);
  };

  const toggleAdminEdit = (id = null) => {
    props.history.push(
      `${paths.accessManagementPath}/parking-lots-admin-edit/${id}`
    );
  };

  const toggleDetailsView = (id = null) => {
    props.history.push(
      `${paths.accessManagementPath}/parking-lots/details-view/${id}`
    );
  };

  const getItem = (record, nestingLevel) => {
    const parkingLot = record.element;
    let accessControl = parkingLot.accessControl || [];
    accessControl = accessControl.join(", ");
    return (
      <tr>
        <td className={"nesting-level-" + nestingLevel}>{parkingLot.name}</td>
        <td>{accessControl || "-"}</td>

        <td>{parkingLot.parkingSpaces}</td>
        <td>
          <img
            alt="View"
            title="View"
            onClick={() => {
              toggleDetailsView(parkingLot.id);
            }}
            src={ViewImage}
            className="action"
          />

          {parkingLot.sharingEntity.entityType != "view-only" && (
            <img
              alt="Edit"
              title="Edit"
              onClick={() => {
                toggleEdit(parkingLot.id);
              }}
              src={EditImage}
              className="action"
            />
          )}

          {(parkingLot.sharingEntity.entityType == "owner" ||
            (currentUser.user != undefined &&
              parkingLot.ownerId == currentUser.user.id)) && (
            <img
              alt="Delete"
              title="Delete"
              onClick={() => {
                toggleDeleteModal(parkingLot.id);
              }}
              src={DeleteImage}
              className="action"
            />
          )}
        </td>
      </tr>
    );
  };

  const getParkingLotRow = (record, nestingLevel = 0) => {
    if (record.children.length == 0) {
      return [getItem(record, nestingLevel)];
    }

    const rows = [getItem(record, nestingLevel)];

    record.children.forEach((record) => {
      getParkingLotRow(record, nestingLevel + 1).forEach((r) => {
        rows.push(r);
      });
    });

    return [...rows];
  };

  return (
    <>
      <Header />
      <CommonModal
        show={!!parkingLotToBeDeleted}
        onClose={toggleDeleteModal}
        body="Are you sure you want to delete this parking lot?"
        title="Parking lot deletion"
        footer={
          <div>
            <SecondaryButton
              value="Cancel"
              onClick={() => toggleDeleteModal()}
            />
            <ActionButton
              value="Delete"
              onClick={() => handleParkingLotDelete()}
            />
          </div>
        }
      />

      <div className="content-page">
        <div class="container-fluid">
          <div class="row">
            <Sidebar />
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10">
              <PageTitle value="Parking lots" />
              <CreateItemButton
                path={`${paths.accessManagementPath}/parking-lots/new`}
                value="Create new parking lot"
              />
              {parkingLots && !parkingLots.length && (
                <div class="alert alert-primary">
                  Currently there are no parking lots created.
                </div>
              )}
              {parkingLots && !!parkingLots.length && (
                <Table
                  headers={[
                    <th>Parking lot</th>,
                    <th>Access control</th>,
                    <th>Parking spaces</th>,
                    <th>Actions</th>,
                  ]}
                  generateRow={getParkingLotRow}
                  items={parkingLots}
                />
              )}
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(ParkingLotsPage);
