import { processInvoice } from "../../utilities/apiUtils";

export const processPayment = (
  type,
  entityId,
  nonce,
  history,
  errorSetter,
  setInProgress = undefined
) => {
  if (type == "invoice") {
    processInvoice(nonce, entityId).then((res) => {
      if (res.data) {
        if (res.data.success) {
          history.push("/revenue-management/payments");
        } else {
          const submitBtn = document.getElementById("submit-credit-card");
          submitBtn.removeAttribute("disabled");
          errorSetter(res.data.message);
          if (setInProgress) {
            setInProgress(false);
          }
        }
      }
    });
  }
};
