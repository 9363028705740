import React from "react";
import { withRouter } from "react-router-dom";
import GenericModifyPage from "../genericModifyPage/GenericModifyPage";
import { DriverCrudConfig } from "./DriverCrudConfig";

import "./ModifyDriverPage.scss";

function ModifyDriverPage(props) {
  const { id } = props.match.params;

  return (
    <GenericModifyPage
      id={id}
      getTitle={DriverCrudConfig.getTitle}
      fetch={DriverCrudConfig.fetch}
      save={DriverCrudConfig.save}
      indexPage={DriverCrudConfig.indexPage}
      itemForm={DriverCrudConfig.itemForm}
      validate={DriverCrudConfig.validate}
    />
  );
}

export default withRouter(ModifyDriverPage);
