import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { find, filter, remove, get } from "lodash";
import EditForm from "../../components/field/EditForm";
import TextField from "../../components/field/TextField";
import MultiComboBox from "../../components/field/MultiComboBox";
import CommonModal from "../../components/commonModal/CommonModal";
import { technologiesUsedOptions } from "../../utilities/constants";
import { getDriverGroupHeadersFull } from "../../utilities/apiUtils";
import { isPositiveInteger } from "../../utilities/numberUtils";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import GlobalContext from "../../utilities/context/GlobalContext";
import "./ParkingLotDetailsForm.scss";

function ParkingLotDetailsForm(props) {
  const context = useContext(GlobalContext);
  const currentUser = get(context, "state.user", "");

  const [item, onChanged, onCancel, onNext] = [
    props.item,
    props.onChanged,
    props.onCancel,
    props.onNext,
  ];

  if (item.accessControl == undefined) {
    item.accessControl = [];
  }

  if (item.driverGroups == undefined) {
    item.driverGroups = [];
  }

  if (item.location == undefined) {
    item.location = {
      address: "",
      latitude: 0.0,
      longitude: 0.0,
      elevation: 0.0,
    };
  }

  if (item.accessData == undefined) {
    item.accessData = {};
  }
  if (item.accessData.allowUnknownDrivers === undefined) {
    item.accessData.allowUnknownDrivers = false;
    item.accessData.allowRegisteredDriversOnly = true;
  }

  const [driverGroupHeadersFetched, setDriverGroupHeadersFetched] =
    useState(false);
  const [driverGroupOptions, setDriverGroupOptions] = useState([]);
  const [name, setName] = useState("");
  const [parkingSpaces, setParkingSpaces] = useState(0);
  const [technologiesUsed, setTechnologiesUsed] = useState([]);
  const [driverGroups, setDriverGroups] = useState([]);
  const [configureDriverGroupMaxDrivers, setConfigureDriverGroupMaxDrivers] =
    useState(false);
  const [lastAddedDriverGroup, setLastAddedDriverGroup] = useState({});
  const [driverGroupConfigErrorText, setDriverGroupConfigErrorText] =
    useState("");
  const [maxDriversInDriverGroup, setMaxDriversInDriverGroup] = useState(0);
  const [currentRef, setCurrentRef] = useState(null);
  const [driverGroupsConfigured, setDriverGroupsConfigured] = useState([]);
  const [informationDisplays, setInformationDisplays] = useState(false);
  const [availabilityWhenLotFull, setAvailabilityWhenLotFull] = useState(false);
  const [allowedDriverType, setAllowedDriverType] = useState("registered");

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    getDriverGroupHeadersFull().then((result) => {
      if (result.data) {
        setDriverGroupOptions(
          result.data.map((x) => {
            x.disabled = x.ownerId != currentUser.user.id;
            return x;
          })
        );
        setDriverGroupHeadersFetched(true);
      }
    });
  }, [driverGroupHeadersFetched, currentUser]);

  useEffect(() => {
    setName(item.name);
    setParkingSpaces(item.parkingSpaces);
    setInformationDisplays(item.accessData.infoDisplays);
    setAvailabilityWhenLotFull(item.accessData.allowCarsWhenLotFull);

    if (item.accessData.infoDisplays == undefined) {
      changeInformationDisplaysValue(informationDisplays);
    }

    if (item.accessData.allowCarsWhenLotFull == undefined) {
      changeAvailabilityWhenLotFull(availabilityWhenLotFull);
    }

    if (
      item.accessData.allowUnknownDrivers != undefined &&
      item.accessData.allowUnknownDrivers
    ) {
      setAllowedDriverType("transient");
    } else if (
      item.accessData.allowRegisteredDriversOnly != undefined &&
      item.accessData.allowRegisteredDriversOnly
    ) {
      setAllowedDriverType("registered");
    }

    if (item.accessControl != undefined) {
      setTechnologiesUsed(
        item.accessControl.map((x) => {
          return {
            name: find(technologiesUsedOptions, { id: x }).name,
            id: x,
          };
        })
      );
    }

    if (driverGroupOptions.length == 0) {
      return;
    }

    setDriverGroups(
      item.driverGroups.map((x) => {
        const selected = find(driverGroupOptions, { id: x.id });
        return {
          name: selected != undefined ? selected.name : "",
          id: x.id,
        };
      })
    );

    setDriverGroupsConfigured(
      item.driverGroups.map((x) => {
        const selected = find(driverGroupOptions, { id: x.id });
        return {
          name: selected != undefined ? selected.name : "",
          id: x.id,
          parkingSpaces: x.parkingSpaces,
        };
      })
    );
  }, [item, driverGroupOptions, driverGroupHeadersFetched]);

  const changeAllowedDriverType = (value) => {
    if (value == "transient") {
      item.accessData.allowUnknownDrivers = true;
      item.accessData.allowRegisteredDriversOnly = false;
    } else {
      item.accessData.allowUnknownDrivers = false;
      item.accessData.allowRegisteredDriversOnly = true;
    }
    setAllowedDriverType(value);
    onChanged(item);
  };

  const changeAvailabilityWhenLotFull = (value) => {
    item.accessData.allowCarsWhenLotFull = value;
    setAvailabilityWhenLotFull(value);
    onChanged(item);
  };

  const changeInformationDisplaysValue = (value) => {
    item.accessData.infoDisplays = value;
    setInformationDisplays(value);
    onChanged(item);
  };

  const changeName = (value) => {
    item.name = value;
    setName(value);
    onChanged(item);
  };

  const changeMaxCars = (value) => {
    item.parkingSpaces = value;
    setParkingSpaces(value);
    onChanged(item);
  };

  const cancelDriverGroup = () => {
    setDriverGroups(
      filter(driverGroups, (x) => x.id != lastAddedDriverGroup.id)
    );
    currentRef.current.resetSelectedValues();
    setConfigureDriverGroupMaxDrivers(false);
  };

  const saveMaxDrivers = () => {
    if (
      !isPositiveInteger(maxDriversInDriverGroup) ||
      parseInt(maxDriversInDriverGroup) <= 0
    ) {
      setDriverGroupConfigErrorText(
        "Maximum number of drivers in this group must be positive integer greater than 0."
      );
      return;
    }

    const currentSum = item.driverGroups
      .map((x) => x.parkingSpaces)
      .reduce((x, y) => x + y, 0);
    const sumOfDrivers = currentSum + parseInt(maxDriversInDriverGroup);

    if (sumOfDrivers > parkingSpaces) {
      setDriverGroupConfigErrorText(
        `Sum of all max cars per driver groups is too big (${sumOfDrivers}). Maximum sum allowed is max cars in the parking lot (${parkingSpaces}).`
      );
      return;
    }

    item.driverGroups.push({
      name: lastAddedDriverGroup.name,
      id: lastAddedDriverGroup.id,
      parkingSpaces: parseInt(maxDriversInDriverGroup),
    });

    setDriverGroupsConfigured((driverGroupsConfigured) => [
      ...driverGroupsConfigured,
      {
        id: lastAddedDriverGroup.id,
        parkingSpaces: maxDriversInDriverGroup,
        name: lastAddedDriverGroup.name,
      },
    ]);

    onChanged(item);
    setConfigureDriverGroupMaxDrivers(false);
  };

  const formComponents = [
    <TextField
      key={1}
      value={name}
      placeholder="Enter name"
      validationController={(value) => {
        const valueTrimmed = value.trim();
        if (valueTrimmed == "") {
          return {
            success: false,
            text: "Name cannot be  empty.",
          };
        }

        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(value) => changeName(value)}
    />,
    <MultiComboBox
      key={2}
      values={technologiesUsed}
      label="Access control mechanism"
      options={technologiesUsedOptions}
      validationController={(values) => {
        if (values.length === 0) {
          return {
            success: false,
            text: "You must select at least one access control mechanism",
          };
        }

        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(values) => {
        if (item.accessControl == undefined) {
          item.accessControl = [];
        }

        item.accessControl = values.map((x) => x.id);
        setTechnologiesUsed(values);
        onChanged(item);
      }}
    />,
    <TextField
      key={3}
      label="Maximum number of cars allowed"
      value={parkingSpaces}
      placeholder="Enter maximum number of cars allowed"
      validationController={(value) => {
        const valueForParsing = value.toString();
        if (
          !isPositiveInteger(valueForParsing) ||
          parseInt(valueForParsing) <= 0
        ) {
          return {
            success: false,
            text: "Maximum number of cars must be positive integer that is greater than 0.",
          };
        }

        const parkingSpaces = parseInt(valueForParsing);
        const driverGroupSpacesConfigured = driverGroupsConfigured
          .map((x) => parseInt(x.parkingSpaces))
          .reduce((a, b) => a + b, 0);
        if (driverGroupSpacesConfigured > parkingSpaces) {
          return {
            success: false,
            text: `Maximum number of cars (which now is ${parkingSpaces}) must be equal or less than the sum of spaces configured for driver groups which is ${driverGroupSpacesConfigured}.`,
          };
        }

        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(value) => changeMaxCars(parseInt(value))}
    />,
    <MultiComboBox
      key={4}
      disabled={
        parkingSpaces == "" || parkingSpaces < 1 || parkingSpaces == undefined
      }
      disabledMessage="You must set maximum number of cars in order to be able to set driver groups."
      values={driverGroups}
      label="Who can park"
      options={driverGroupOptions}
      validationController={(values) => {
        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(values) => {
        setDriverGroups(values);
      }}
      onValueTriggered={(value, ref, action) => {
        if (action != "select") {
          remove(item.driverGroups, (x) => x.id == value.id);

          setDriverGroupsConfigured(
            driverGroupsConfigured.filter((x) => x.id != value.id)
          );
          onChanged(item);

          return;
        }

        setLastAddedDriverGroup(value);
        setCurrentRef(ref);

        setMaxDriversInDriverGroup("");
        setDriverGroupConfigErrorText("");
        setConfigureDriverGroupMaxDrivers(true);
      }}
    />,
    <>
      {driverGroupsConfigured.map((group) => (
        <div className="form-group group-data">
          <p className="group-name">{group.name}</p>
          <p className="group-description">
            <strong>Max drivers</strong> {group.parkingSpaces}
          </p>
        </div>
      ))}
    </>,
    <FormControlLabel
      control={
        <Checkbox
          checked={informationDisplays}
          onChange={(event) =>
            changeInformationDisplaysValue(event.target.checked)
          }
          color="primary"
        />
      }
      label="Information displays"
    />,
    <div className="form-group">
      <h4>Access</h4>
      <div className="form-group">
        <FormControlLabel
          control={
            <Checkbox
              checked={availabilityWhenLotFull}
              onChange={(event) =>
                changeAvailabilityWhenLotFull(event.target.checked)
              }
              color="primary"
            />
          }
          label="Allow cars when parking lot is full"
        />
      </div>
      <div className="form-group">
        <RadioGroup
          value={allowedDriverType}
          onChange={(event) => changeAllowedDriverType(event.target.value)}
        >
          <FormControlLabel
            value="transient"
            control={<Radio />}
            label="Allow unknown/transient drivers"
          />
          <FormControlLabel
            value="registered"
            control={<Radio />}
            label="Allow only registered drivers"
          />
        </RadioGroup>
      </div>
    </div>,
  ];

  const form = (
    <EditForm
      components={formComponents}
      continueAction={<PrimaryButton value="Next" />}
      cancelAction={<PrimaryButton onClick={() => onCancel()} value="Cancel" />}
      onValidated={(result) => {
        if (result) {
          onNext();
        }
      }}
    />
  );

  return (
    <div>
      <CommonModal
        show={!!configureDriverGroupMaxDrivers}
        onClose={cancelDriverGroup}
        body={
          <>
            {driverGroupConfigErrorText != "" && (
              <div class="alert alert-danger" role="alert">
                {driverGroupConfigErrorText}
              </div>
            )}
            <div class="form-group">
              <label>Maximum number of drivers allowed</label>
              <input
                type="text"
                className="form-control"
                value={maxDriversInDriverGroup}
                onChange={(event) => {
                  setMaxDriversInDriverGroup(event.target.value);
                }}
                placeholder="Enter maximum number of drivers allowed"
              />
            </div>
          </>
        }
        title={`Configure driver group "${
          lastAddedDriverGroup.name != undefined
            ? lastAddedDriverGroup.name
            : ""
        }"`}
        footer={
          <div>
            <Button variant="secondary" onClick={() => saveMaxDrivers()}>
              Save
            </Button>
            <Button variant="primary" onClick={() => cancelDriverGroup()}>
              Cancel
            </Button>
          </div>
        }
      />
      {form}
    </div>
  );
}

export default ParkingLotDetailsForm;
