import React, { useState, useEffect } from "react";
import MobileTable from "./MobileTable";
import "./Table.scss";

export default function Table(props) {
  const { headers, generateRow, items } = props;

  return (
    <>
      <div class="mobile-table">
        <MobileTable
          headers={headers}
          generateRow={generateRow}
          items={items}
        />
      </div>
      <div class="desktop-table">
        <div className="table-body">
          <table className="table">
            <thead>
              <tr>{headers}</tr>
            </thead>
            <tbody>{items.map((record) => generateRow(record))}</tbody>
          </table>
        </div>
      </div>
    </>
  );
}
