export const isPositiveNumber = (value) => {
  return !isNaN(value) && Number(value) > 0;
};

export const isPositiveInteger = (value) => {
  if (value == "" || value == null || value == undefined) {
    return false;
  }

  return parseInt(value.toString(), 10).toString() == value.toString() && value.toString().indexOf("-") === -1;
};

export const isNumeric = (value) => {
  return !isNaN(parseFloat(value)) && isFinite(value);
};

export const countDecimals = (value) => {
  if (Math.floor(value.valueOf()) === value.valueOf()) return 0;

  var str = value.toString();
  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
      return str.split("-")[1] || 0;
  } else if (str.indexOf(".") !== -1) {
      return str.split(".")[1].length || 0;
  }
  
  return str.split("-")[1] || 0;
}