import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { paths } from "../../utilities/constants";
import { deleteInvoice } from "../../utilities/apiUtils";
import CommonModal from "../../components/commonModal/CommonModal";
import { Button } from "react-bootstrap";
import { getCompany, getUserName } from "../../utilities/itemUtils";
import ViewImage from "../../assets/images/view.svg";
import Table from "../../components/table/Table";

function InvoicesClientsContainer(props) {
  const [clients] = [props.clients];

  const toggleView = (clientId = null) => {
    props.history.push(
      `${paths.revenueanagementPath}/invoices/client/${clientId}`
    );
  };

  const getClientRow = (client) => {
    return (
      <tr>
        <td>{getCompany(client.clientCompany)}</td>
        <td>{getUserName(client.client)}</td>
        <td>{client.stats.totalInvoices}</td>
        <td>{client.stats.paidInvoices}</td>
        <td>{client.stats.lateInvoices}</td>
        <td>
          <img
            alt="View"
            title="View"
            onClick={() => {
              toggleView(client.client.id);
            }}
            src={ViewImage}
            className="action"
          />
        </td>
      </tr>
    );
  };

  return (
    <>
      {clients && !clients.length && (
        <div class="alert alert-primary">
          Currently there are no invoice clients you can create invoices to.
          Please create the client first.
        </div>
      )}
      {clients && !!clients.length && (
        <>
          {clients && !!clients.length && (
            <Table
              headers={[
                <th>Company</th>,
                <th>Administrator</th>,
                <th>Total invoices</th>,
                <th>Paid invoices</th>,
                <th>Late invoices</th>,
                <th>Actions</th>,
              ]}
              generateRow={getClientRow}
              items={clients}
            />
          )}
        </>
      )}
    </>
  );
}

export default withRouter(InvoicesClientsContainer);
