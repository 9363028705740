import { get } from "lodash";
import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import GlobalContext from "../../utilities/context/GlobalContext";
import { getToken, removeToken } from "../../utilities/localStorageUtils";
import { verifyJwt } from "../../utilities/authUtils";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const context = useContext(GlobalContext);
  const currentUser = get(context, "state.user") || {};
  const token = getToken();
  const isJwtInvalid = !verifyJwt(token);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!token || isJwtInvalid || (token && !currentUser)) {
          removeToken();

          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};
