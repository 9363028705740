import React, { useState, useEffect } from "react";
import SimpleDatePicker from "../../components/field/SimpleDatePicker";

function ReservationsFilterForm(props) {
  const onChanged = props.onChanged;

  const [entryTime, setEntryTime] = useState(null);
  const [exitTime, setExitTime] = useState(null);

  useEffect(() => {
    setEntryTime(props.entryTime);
    setExitTime(props.exitTime);
  }, [props.entryTime, props.exitTime]);

  const changeEntryTime = (value) => {
    setEntryTime(value);
    onChanged({
      entryTime: value,
      exitTime: exitTime,
    });
  };

  const changeExitTime = (value) => {
    setExitTime(value);
    onChanged({
      entryTime: entryTime,
      exitTime: value,
    });
  };

  return (
    <>
      <SimpleDatePicker
        label="Entry time (optional)"
        value={entryTime}
        onValueChanged={(value) => changeEntryTime(value)}
        placeholder="Select valid entry time value"
        setTime={true}
      />
      <SimpleDatePicker
        label="Exit time (optional)"
        value={exitTime}
        onValueChanged={(value) => changeExitTime(value)}
        placeholder="Select valid exit time value"
        setTime={true}
      />
    </>
  );
}

export default ReservationsFilterForm;
