import React, { useState, useEffect, useRef } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { remove } from "lodash";
import "./MultiComboBox.scss";

function MultiComboBox(props) {
  const [
    label,
    options,
    validationController,
    onValueChanged,
    onValueTriggered,
    disabled,
    disabledMessage,
    // EditForm-specific
    validationCounter,
    onValidated,
  ] = [
    props.label,
    props.options,
    props.validationController,
    props.onValueChanged,
    props.onValueTriggered,
    props.disabled,
    props.disabledMessage || "",
    // EditForm-specific
    props.validationCounter,
    props.onValidated,
  ];

  const [values, setValues] = useState(props.values || []);
  const itemRef = useRef(null);

  useEffect(() => {
    setValues(props.values || []);
  }, [props.values]);

  const onSelect = (_list, value) => {
    values.push(value);
    if (onValueChanged) {
      onValueChanged(values);
    }

    if (onValueTriggered) {
      onValueTriggered(value, itemRef, "select");
    }
  };

  const onRemove = (_list, value) => {
    remove(values, (x) => x == value);
    if (onValueChanged) {
      onValueChanged(values);
    }

    if (onValueTriggered) {
      onValueTriggered(value, itemRef, "delete");
    }
  };

  const [errorVisible, setErrorVisible] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    setErrorVisible(false);
    setErrorText("");

    if (!props.validationCounter || props.validationCounter <= 0) {
      return;
    }

    const result = validationController(values);
    setErrorVisible(!result.success);
    setErrorText(result.text);
    onValidated(result);
  }, [validationCounter]);

  const style = {
    multiselectContainer: {
      background: "#FFFFFF",
      color: "#515459",
      "font-size": "1rem",
      "font-weight": "500",
      border: "0.0625rem solid #D8E0E5",
      "border-radius": "2rem",
      "padding-left": "1.375rem",
      "padding-right": "1.375rem",
      "padding-top": "0.34375rem",
      "padding-bottom": "0.34375rem",
    },
    optionContainer: {
      background: "#FFFFFF",
      "margin-left": "-1.4375rem",
      "margin-right": "1.3125rem",
      "padding-left": "0.875rem",
      "padding-right": "0.875rem",
      "padding-top": "0.75rem",
      "padding-bottom": "0.75rem",
      "list-style-type": "none",
    },
    option: {
      "border-radius": "2rem",
    },
  };

  return (
    <>
      <div
        className={`form-group ${disabled ? "disabled" : "enabled"} ${
          errorVisible ? "error-select" : ""
        }`}
      >
        <label>{label}</label>
        {disabled && disabledMessage != "" && (
          <div class="alert alert-warning" role="warning">
            {disabledMessage}
          </div>
        )}
        <Multiselect
          avoidHighlightFirstOption
          ref={itemRef}
          options={options}
          selectedValues={values}
          onSelect={onSelect}
          onRemove={onRemove}
          displayValue="name"
          className={
            "alot-multi-select" + (errorVisible ? " error-select" : "")
          }
          style={style}
        />
        {errorVisible && (
          <div className="text-danger" style={{ fontSize: "80%" }}>
            {errorText}
          </div>
        )}
      </div>
    </>
  );
}

export default MultiComboBox;
