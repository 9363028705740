import React, { useState } from "react";
import { paths } from "../../utilities/constants";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";

function InvoiceFinalStepForm(props) {
  const [item, onCancel, onBack, onSave] = [
    props.item,
    props.onCancel,
    props.onBack,
    props.onSave,
  ];

  const [errors, setErrors] = useState([]);

  const handleSaveAction = () => {
    setErrors([]);
    onSave(item).then((result) => {
      if (result.error && result.error.details) {
        setErrors(result.error.details.map((x) => `${x.code}: ${x.message}`));
        return;
      }

      if (result.data && result.data.items && !result.data.clientId) {
        setErrors(result.data.items.map((x) => `${x.code}: ${x.details.info}`));
      } else if (result.status == 200 && result.data) {
        props.history.push(
          `${paths.revenueanagementPath}/invoices/client/${result.data.clientId}`
        );
      } else {
        setErrors([`${result.status}: ${result.statusText}`]);
      }
    });
  };

  return (
    <div>
      {errors.length > 0 && (
        <div class="alert alert-danger" role="alert">
          <p>
            Server has returned the following errors that you must fix first!
          </p>
          <ul>
            {errors.map((x) => (
              <li>{x}</li>
            ))}
          </ul>
        </div>
      )}
      <p>
        You have filled in all of the data that is required for the invoice to
        be saved. Now you can save the data.
      </p>
      <div class="action-buttons">
        <div class="row">
          <PrimaryButton onClick={() => onCancel()} value="Cancel" />
          <PrimaryButton onClick={() => onBack()} value="Back" />
          <PrimaryButton onClick={() => handleSaveAction()} value="Save" />
        </div>
      </div>
    </div>
  );
}

export default InvoiceFinalStepForm;
