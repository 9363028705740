import { Link } from "react-router-dom";
import PrimaryButton from "../primaryButton/PrimaryButton";
import "./CreateItemButton.scss";

export default function CreateItemButton(props) {
  const { path, value } = props;
  const containsLineBreak =
    props.containsLineBreak != undefined ? props.containsLineBreak : true;

  return (
    <>
      <div class="row item-button-aligned">
        <div>
          <Link to={path}>
            <PrimaryButton value={value} />
          </Link>
        </div>
      </div>
      {containsLineBreak && <br />}
    </>
  );
}
