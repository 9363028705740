import React, { useState, useEffect, useContext } from "react";
import { get } from "lodash";
import { Link, useLocation } from "react-router-dom";
import { paths, links, roles } from "../../utilities/constants";
import GlobalContext from "../../utilities/context/GlobalContext";
import ArrowDown from "../../components/arrowDown/ArrowDown";
import ArrowRight from "../../components/arrowRight/ArrowRight";
import "./Sidebar.scss";

function Sidebar(props) {
  const context = useContext(GlobalContext);
  const openedMenus = get(context, "state.openedMenus", []);
  let currentUrl = window.location.href.split("/").reverse()[0];
  const activeLink = get(context, "state.activeLink") || currentUrl;
  const isMobileMenuOpen = get(context, "state.isMobileMenuOpen", false);
  const currentUser = get(context, "state.user", "");
  const currentRole = get(currentUser, "roleData.role", "");
  const location = useLocation();

  const toggleMenu = (menu) => {
    let menus = [];
    if (openedMenus.includes(menu)) {
      const array = openedMenus;
      menus = array.filter((e) => e !== menu);
    } else {
      menus = [...openedMenus, menu];
    }
    context.updateStateValue("openedMenus", menus);
  };

  useEffect(() => {
    for (const key of Object.keys(links)) {
      const path = links[key];
      if (location.pathname.startsWith(path)) {
        context.updateStateValue("activeLink", path);
        const pathParts = path.split("/");
        if (pathParts.length == 3) {
          const menuItem = `/${pathParts[1]}`;
          if (!openedMenus.includes(menuItem)) {
            toggleMenu(menuItem);
          }
        }
        return;
      }
    }
  }, [location]);

  return (
    <nav
      class={`col-md-2 sidebar d-flex justify-content-center ${
        !!isMobileMenuOpen && "opened-menu"
      }`}
    >
      <ul class="nav flex-column">
        <div className="sidebar-title">Menu</div>
        {currentRole !== roles.Driver && (
          <li
            onClick={() =>
              context.updateStateValue("activeLink", links.Dashboard)
            }
            className={!!(activeLink === links.Dashboard) && "active"}
          >
            <Link to="/dashboard">Dashboard</Link>
          </li>
        )}
        {currentRole !== roles.Driver && (
          <li
            onClick={() =>
              context.updateStateValue("activeLink", links.Analytics)
            }
            className={!!(activeLink === links.Analytics) && "active"}
          >
            <Link to="/analytics">Analytics</Link>
          </li>
        )}
        <li>
          <span
            className="category"
            onClick={() => toggleMenu(paths.accessManagementPath)}
          >
            Access management
            {openedMenus.includes(paths.accessManagementPath) ? (
              <ArrowDown />
            ) : (
              <ArrowRight />
            )}
          </span>
          <ul
            className={`${
              !!openedMenus.includes(paths.accessManagementPath) &&
              "active-list"
            }`}
          >
            {currentRole !== roles.Driver && (
              <li
                onClick={() =>
                  context.updateStateValue("activeLink", links.ParkingLots)
                }
                className={!!(activeLink === links.ParkingLots) && "active"}
              >
                <Link to="/access-management/parking-lots">Parking lots</Link>
              </li>
            )}
            {currentRole !== roles.Driver && (
              <li
                onClick={() =>
                  context.updateStateValue("activeLink", links.Drivers)
                }
                className={!!(activeLink === links.Drivers) && "active"}
              >
                <Link to="/access-management/drivers">Drivers</Link>
              </li>
            )}
            {currentRole !== roles.Driver && (
              <li
                onClick={() =>
                  context.updateStateValue("activeLink", links.GateManagement)
                }
                className={!!(activeLink === links.GateManagement) && "active"}
              >
                <Link to="/access-management/gate-management">
                  Gate management
                </Link>
              </li>
            )}
            <li
              onClick={() =>
                context.updateStateValue("activeLink", links.Reservations)
              }
              className={!!(activeLink === links.Reservations) && "active"}
            >
              <Link to="/access-management/reservations">Reservations</Link>
            </li>
          </ul>
        </li>
        {currentRole !== roles.Driver && (
          <li>
            <span
              className="category"
              onClick={() => toggleMenu(paths.revenueanagementPath)}
            >
              Revenue management
              {openedMenus.includes(paths.revenueanagementPath) ? (
                <ArrowDown />
              ) : (
                <ArrowRight />
              )}
            </span>
            <ul
              className={`${
                !!openedMenus.includes(paths.revenueanagementPath) &&
                "active-list"
              }`}
            >
              {(currentRole === roles.Admin ||
                currentRole === roles.MasterUser ||
                currentRole === roles.SubAdmin) && (
                <li
                  onClick={() =>
                    context.updateStateValue("activeLink", links.Payments)
                  }
                  className={!!(activeLink === links.Payments) && "active"}
                >
                  <Link to="/revenue-management/payments">Payments</Link>
                </li>
              )}

              <li
                onClick={() =>
                  context.updateStateValue("activeLink", links.Invoices)
                }
                className={!!(activeLink === links.Invoices) && "active"}
              >
                <Link to="/revenue-management/invoices">Invoices</Link>
              </li>
              {(currentRole === roles.Admin ||
                currentRole === roles.MasterUser ||
                currentRole === roles.SubAdmin) && (
                <li
                  onClick={() =>
                    context.updateStateValue("activeLink", links.Billing)
                  }
                  className={!!(activeLink === links.Billing) && "active"}
                >
                  <Link to="/revenue-management/billing">Billing</Link>
                </li>
              )}
            </ul>
          </li>
        )}
        {currentRole !== roles.Driver && (
          <li>
            <span
              className="category"
              onClick={() => toggleMenu(paths.multiUserManagementPath)}
            >
              Multi-user management
              {openedMenus.includes(paths.multiUserManagementPath) ? (
                <ArrowDown />
              ) : (
                <ArrowRight />
              )}
            </span>
            <ul
              className={`${
                !!openedMenus.includes(paths.multiUserManagementPath) &&
                "active-list"
              }`}
            >
              {(currentRole === roles.SuperAdmin ||
                currentRole === roles.MasterUser) && (
                <li
                  onClick={() =>
                    context.updateStateValue("activeLink", links.Admins)
                  }
                  className={!!(activeLink === links.Admins) && "active"}
                >
                  <Link to="/multi-user-management/admins">Admins</Link>
                </li>
              )}

              {(currentRole === roles.SuperAdmin ||
                currentRole === roles.MasterUser ||
                currentRole === roles.Admin) && (
                <li
                  onClick={() =>
                    context.updateStateValue("activeLink", links.SubAdmins)
                  }
                  className={!!(activeLink === links.SubAdmins) && "active"}
                >
                  <Link to="/multi-user-management/sub-admins">Sub-admins</Link>
                </li>
              )}

              <li
                onClick={() =>
                  context.updateStateValue("activeLink", links.DriverGroups)
                }
                className={!!(activeLink === links.DriverGroups) && "active"}
              >
                <Link to="/multi-user-management/driver-groups">
                  Driver groups
                </Link>
              </li>
            </ul>
          </li>
        )}
        {currentRole !== roles.Driver && (
          <li>
            <span
              className="category"
              onClick={() => toggleMenu(paths.settings)}
            >
              System settings
              {openedMenus.includes(paths.settings) ? (
                <ArrowDown />
              ) : (
                <ArrowRight />
              )}
            </span>
            <ul
              className={`${
                !!openedMenus.includes(paths.settings) && "active-list"
              }`}
            >
              <li
                onClick={() =>
                  context.updateStateValue(
                    "activeLink",
                    links.ReservationSettings
                  )
                }
                className={
                  !!(activeLink === links.ReservationSettings) && " active"
                }
              >
                <Link to="/settings/reservation">Reservation</Link>
              </li>
              <li
                onClick={() =>
                  context.updateStateValue(
                    "activeLink",
                    links.DriverPaymentSettings
                  )
                }
                className={
                  !!(activeLink === links.DriverPaymentSettings) && " active"
                }
              >
                <Link to="/settings/driver-payments">Driver payments</Link>
              </li>
              <li
                onClick={() =>
                  context.updateStateValue(
                    "activeLink",
                    links.ClientPaymentSettings
                  )
                }
                className={
                  !!(activeLink === links.ClientPaymentSettings) && " active"
                }
              >
                <Link to="/settings/client-payments">Client payments</Link>
              </li>
            </ul>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default Sidebar;
