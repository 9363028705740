import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../utilities/context/GlobalContext";
import { get } from "lodash";
import { getToken, removeToken } from "../../utilities/localStorageUtils";
import { verifyJwt, logout } from "../../utilities/authUtils";
import { getUserInfo } from "../../utilities/userUtils";

function ContextUpdateWrapper(props) {
  const context = useContext(GlobalContext);
  const user = get(context, "state.user", "");

  const token = getToken();
  if (!user && !!token && verifyJwt(token)) {
    getUserInfo()
      .then((res) => {
        const user = get(res, "data", "");
        if (user) {
          context.updateStateValue("user", user);
        } else {
          logout();
          window.location.href = "/login";
        }
      })
      .catch((err) => {
        logout();
        window.location.href = "/login";
      });
  } else if (!!token && !verifyJwt(token)) {
    logout();
    window.location.href = "/login";
  }

  return <React.Fragment>{props.children}</React.Fragment>;
}

export default ContextUpdateWrapper;
