import React, { useState } from "react";
import { paths } from "../../utilities/constants";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import { processSaveResult } from "../../utilities/crudUtils";
import { parseDriverPaymentSettings } from "../../components/payments/PaymentsConfigUtil";

function DriverGroupFinalStepForm(props) {
  const [item, onCancel, onBack, onSave] = [
    props.item,
    props.onCancel,
    props.onBack,
    props.onSave,
  ];

  const [errors, setErrors] = useState([]);

  const handleSaveAction = () => {
    setErrors([]);
    item.pricing = parseDriverPaymentSettings(item.pricing);
    onSave(item).then((result) => {
      processSaveResult(
        result,
        props.history,
        `${paths.accessManagementPath}/parking-lots`,
        setErrors
      );
    });
  };

  return (
    <div>
      {errors.length > 0 && (
        <div class="alert alert-danger" role="alert">
          <p>
            Server has returned the following errors that you must fix first!
          </p>
          <ul>
            {errors.map((x) => (
              <li>{x}</li>
            ))}
          </ul>
        </div>
      )}
      <p>
        You have filled in all of the data that is required to save data for the
        driver group. Now you need to click just one button, and you will be
        able to create or edit parking lots for this driver group if there will
        be no errors on the server side.
      </p>
      <div class="action-buttons">
        <div class="row">
          <PrimaryButton onClick={() => onCancel()} value="Cancel" />
          <PrimaryButton onClick={() => onBack()} value="Back" />
          <PrimaryButton onClick={() => handleSaveAction()} value="Save" />
        </div>
      </div>
    </div>
  );
}

export default DriverGroupFinalStepForm;
