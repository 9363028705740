import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import { getParkingLotPartitioningData } from "../../utilities/apiUtils";
import { paths } from "../../utilities/constants";
import "./ParkingLotsPartitioningTab.scss";
import "../../styles/DataTable.scss";
import EditImage from "../../assets/images/edit.svg";

function ParkingLotsPartitioningTab(props) {
  const [partitioningData, setPartitioningData] = useState(null);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getParkingLotPartitioningData(props.parkingLotId).then((result) => {
      if (result.data) {
        setPartitioningData(result.data);
      }
    });
  }, []);

  useEffect(() => {
    if (partitioningData) getPartitioningRows(partitioningData);
  }, [partitioningData]);

  const editAdmin = (id) => {
    props.history.push(`${paths.multiUserManagementPath}/admins/${id}`);
  };

  const editSubAdmin = (id) => {
    props.history.push(`${paths.multiUserManagementPath}/sub-admins/${id}`);
  };

  const editDriverGroup = (id) => {
    props.history.push(`${paths.multiUserManagementPath}/driver-groups/${id}`);
  };

  const getPartitioningRows = (partitioningData) => {
    let data = [];
    if (partitioningData) {
      partitioningData.admins.forEach((admin) => {
        data.push({ ...admin, type: "Admin", edit: editAdmin });
      });
      partitioningData.subAdmins.forEach((subAdmin) => {
        data.push({ ...subAdmin, type: "Sub-admins", edit: editSubAdmin });
      });
      partitioningData.driverGroups.forEach((driverGroup) => {
        data.push({
          ...driverGroup,
          type: "Driver groups",
          edit: editDriverGroup,
        });
      });
      setTableData(data);
    }
  };

  const getPartitioningRow = (item) => {
    return (
      <tr>
        <td>{item.name}</td>
        <td>{item.type}</td>

        <td>{item.spacesShared}</td>
        <td>
          <img
            alt="Edit"
            title="Edit"
            onClick={() => {
              item.edit(item.itemId);
            }}
            src={EditImage}
            className="action"
          />
        </td>
      </tr>
    );
  };

  return (
    <div>
      {tableData.length ? (
        <>
          <Table
            headers={[
              <th>Client</th>,
              <th>Type</th>,
              <th>Spaces shared</th>,
              <th>Action</th>,
            ]}
            generateRow={getPartitioningRow}
            items={tableData}
          />
        </>
      ) : (
        <p>No partinioning data loaded.</p>
      )}
    </div>
  );
}

export default ParkingLotsPartitioningTab;
