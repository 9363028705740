import React, { useState, useEffect } from "react";
import "./MobileTable.scss";
import ArrowDownImage from "../../assets/images/arrow-down-gray.svg";
import ArrowSideImage from "../../assets/images/arrow-side-gray.svg";
import "./MobileTable.css";

export default function MobileTable(props) {
  const [activeIndices, setActiveIndices] = useState([]);
  const { headers, generateRow, items } = props;

  const expandToRows = (record) => {
    const rows = generateRow(record);
    return Array.isArray(rows) ? rows : [rows];
  };

  const itemsToRows = () => {
    const rows = [];
    items.forEach((record) => {
      rows.push(...expandToRows(record));
    });

    return rows;
  };

  const convertToMobileRow = (headers, row, rowIndex) => {
    return row.props.children.map((el, index) => {
      return (
        <tr
          className={
            index == 0 || activeIndices.includes(rowIndex)
              ? "element-visible"
              : "element-invisible"
          }
        >
          <td class="action-cell">
            {index == 0 && (
              <img
                alt="Show/hide"
                title="Show/hide"
                onClick={() => {
                  if (activeIndices.includes(rowIndex)) {
                    setActiveIndices([
                      ...activeIndices.filter((x) => x != rowIndex),
                    ]);
                  } else {
                    setActiveIndices([...activeIndices, rowIndex]);
                  }
                }}
                src={
                  activeIndices.includes(rowIndex)
                    ? ArrowDownImage
                    : ArrowSideImage
                }
                className="show-hide-action"
              />
            )}
          </td>
          <td class="label-cell">{headers[index].props.children}</td>
          <td class="content-cell">{el.props.children}</td>
        </tr>
      );
    });
  };

  const itemsAdjusted = itemsToRows();

  return (
    <div className="table-body">
      <table className="table">
        {itemsAdjusted.map((rowTable, rowIndex) => {
          const row = convertToMobileRow(headers, rowTable, rowIndex);
          return (
            <div
              className={
                ((rowIndex + 1) % 2 == 0 ? "even-row" : "odd-row") +
                (rowIndex == 0 ? " first-row" : "") +
                (rowIndex == itemsAdjusted.length - 1 &&
                !activeIndices.includes(rowIndex)
                  ? " last-row-inactive"
                  : "") +
                (rowIndex == itemsAdjusted.length - 1 &&
                activeIndices.includes(rowIndex)
                  ? " last-row-active"
                  : "") +
                " mobile-row"
              }
            >
              {row}
            </div>
          );
        })}
      </table>
    </div>
  );
}
