import React, { useState, useEffect } from "react";
import { find, filter, remove } from "lodash";
import { Button } from "@material-ui/core";
import "./MultiTextField.scss";
import SecondaryInputField from "../secondaryInputField/SecondaryInputField";
import AddImage from "../../assets/images/add.svg";
import CloseImage from "../../assets/images/close.svg";

function MultiTextField(props) {
  const [
    placeholder,
    validationController,
    onOptionAdded,
    onRemoveTriggered,
    label,
    options,
    selectionLabel,
    preProcessOptionAction,
    // EditForm-specific
    validationCounter,
    onValidated,
  ] = [
    props.placeholder,
    props.validationController,
    props.onOptionAdded,
    props.onRemoveTriggered,
    props.label,
    props.options,
    props.selectionLabel,
    props.preProcessOptionAction,
    // EditForm-specific
    props.validationCounter,
    props.onValidated,
  ];

  const [value, setValue] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [fieldOptions, setFieldOptions] = useState([]);

  useEffect(() => {
    setErrorVisible(false);
    setErrorText("");

    if (!props.validationCounter || props.validationCounter <= 0) {
      return;
    }

    const result = validationController(fieldOptions);
    setErrorVisible(!result.success);
    setErrorText(result.text);
    onValidated(result);
  }, [validationCounter]);

  useEffect(() => {
    setFieldOptions(options);
  }, [options]);

  const processCurrentValue = () => {
    const valueEntered =
      preProcessOptionAction != undefined
        ? preProcessOptionAction(value)
        : value.trim();
    if (valueEntered == "") {
      return;
    }

    const item = find(fieldOptions, (x) => x == valueEntered);
    if (item) {
      setValue("");
      return;
    }

    const newOptions = fieldOptions;
    newOptions.push(valueEntered);

    setFieldOptions(newOptions);
    setValue("");
    onOptionAdded(valueEntered);
  };

  const processRemoveOption = (option) => {
    const newOptions = fieldOptions.filter((x) => x != option);
    setFieldOptions(newOptions);
    onRemoveTriggered(option);
  };

  return (
    <div class="form-group">
      {label && <label>{label}</label>}
      <div class="row">
        <div class="col">
          <SecondaryInputField
            type="text"
            className={`form-control ${!!errorVisible && "is-invalid"}`}
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
            placeholder={placeholder}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                processCurrentValue();
              }
            }}
          />
          {errorVisible && <div class="invalid-feedback">{errorText}</div>}
        </div>
        <div class="col-md-auto image-centered">
          <img
            alt="Add"
            title="Add"
            onClick={() => processCurrentValue()}
            src={AddImage}
            className="action"
          />
        </div>
      </div>
      {fieldOptions.length > 0 && (
        <div class="form-inline">
          <strong>{selectionLabel}</strong>
          {fieldOptions.map((x) => (
            <>
              <div className="option-selected">
                {x}
                <img
                  alt="Remove"
                  title="Remove"
                  onClick={() => processRemoveOption(x)}
                  src={CloseImage}
                  className="remove-option"
                />
              </div>
            </>
          ))}
        </div>
      )}
    </div>
  );
}

export default MultiTextField;
