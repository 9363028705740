export const expectNotEmpty = (value, errorText) => {
  const valueTrimmed = value.trim();
  if (valueTrimmed == "") {
    return {
      success: false,
      text: errorText,
    };
  }

  return {
    success: true,
    text: "",
  };
};

export const passAllways = (value) => {
  return {
    success: true,
    text: "",
  };
};
