import React from "react";
import { InputLabel, MenuItem, Select } from "@material-ui/core";

function MonthPicker(props) {
  const [getter, setter] = [props.getter, props.setter];

  const handleMonthChange = (event) => {
    setter(event.target.value);
  };

  return (
    <div class="form-group">
      <InputLabel id="month-selection">Select month</InputLabel>
      <Select
        labelId="month-selection"
        value={getter}
        onChange={handleMonthChange}
      >
        <MenuItem value="01">January</MenuItem>
        <MenuItem value="02">February</MenuItem>
        <MenuItem value="03">March</MenuItem>
        <MenuItem value="04">April</MenuItem>
        <MenuItem value="05">May</MenuItem>
        <MenuItem value="06">June</MenuItem>
        <MenuItem value="07">July</MenuItem>
        <MenuItem value="08">August</MenuItem>
        <MenuItem value="09">September</MenuItem>
        <MenuItem value="10">October</MenuItem>
        <MenuItem value="11">November</MenuItem>
        <MenuItem value="12">December</MenuItem>
      </Select>
    </div>
  );
}

export default MonthPicker;
