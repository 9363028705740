import React, { useState, useEffect } from "react";
import "./InvoiceItemsForm.scss";
import SecondaryInputField from "../../components/secondaryInputField/SecondaryInputField";
import { isPositiveNumber } from "../../utilities/numberUtils";

function InvoiceItemsForm(props) {
  const [
    label,
    validationController,
    onValueChanged,
    // EditForm-specific
    validationCounter,
    onValidated,
  ] = [
    props.label,
    props.validationController,
    props.onValueChanged,
    // EditForm-specific
    props.validationCounter,
    props.onValidated,
  ];

  const [items, setItems] = useState(props.items || []);
  const [currentInvoiceItem, setCurrentInvoiceItem] = useState("");
  const [currentAmount, setCurrentAmount] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [amountError, setAmountError] = useState("");

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  const [errorVisible, setErrorVisible] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    setErrorVisible(false);
    setErrorText("");

    if (!props.validationCounter || props.validationCounter <= 0) {
      return;
    }

    const result = validationController(items);
    setErrorVisible(!result.success);
    setErrorText(result.text);
    onValidated(result);
  }, [validationCounter]);

  const changeCurrentInvoiceItem = (value) => {
    setCurrentInvoiceItem(value);
  };

  const changeCurrentAmount = (value) => {
    setCurrentAmount(value);
  };

  const processAddItem = () => {
    let hasError = false;
    const currentInvoiceItemProcessed = currentInvoiceItem.trim();
    const currentAmountProcessed = currentAmount.trim();
    if (currentInvoiceItemProcessed == "") {
      hasError = true;
      setDescriptionError("You must enter invoice item description.");
    } else {
      setDescriptionError("");
    }

    if (
      currentAmountProcessed == "" ||
      !isPositiveNumber(currentAmountProcessed) ||
      parseFloat(currentAmountProcessed) <= 0
    ) {
      hasError = true;
      setAmountError(
        "You must enter numeric current amount value greater than 0."
      );
    } else if (
      currentAmountProcessed.indexOf(".") !== -1 &&
      currentAmountProcessed.split(".")[1].length > 2
    ) {
      hasError = true;
      setAmountError(
        "Amount is not allowed to have more than 2 decimal digits."
      );
    } else {
      setAmountError("");
    }

    if (hasError) {
      return;
    }

    const item = {
      description: currentInvoiceItemProcessed,
      price: {
        amount: parseFloat(currentAmountProcessed) * 100,
        currency: "USD",
      },
    };

    const itemsCopy = [...items];
    itemsCopy.push(item);
    setItems((items) => [...itemsCopy]);
    onValueChanged(itemsCopy);
  };

  const processRemove = (index) => {
    let itemsCopy = [...items];
    delete itemsCopy[index];
    itemsCopy = itemsCopy.filter((x) => x);
    setItems([...itemsCopy]);
    onValueChanged(itemsCopy);
  };

  return (
    <>
      <div className={`form-group`}>
        <h5>{label}</h5>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Item</th>
              <th scope="col">Amount ($)</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <SecondaryInputField
                  type="text"
                  onChange={(event) => {
                    changeCurrentInvoiceItem(event.target.value);
                  }}
                  value={currentInvoiceItem}
                  placeholder="Enter item description"
                />
                <br />
                {descriptionError != "" && (
                  <div class="alert alert-danger" role="alert">
                    {descriptionError}
                  </div>
                )}
              </td>
              <td>
                <SecondaryInputField
                  type="number"
                  onChange={(event) => {
                    changeCurrentAmount(event.target.value);
                  }}
                  value={currentAmount}
                  placeholder="Enter amount"
                />
                <br />
                {amountError != "" && (
                  <div class="alert alert-danger" role="alert">
                    {amountError}
                  </div>
                )}
              </td>
              <td>
                <button
                  onClick={() => {
                    processAddItem();
                  }}
                  type="button"
                  class="btn btn-success"
                >
                  Add
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        {items && items.length ? (
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Item description</th>
                <th scope="col">Amount ($)</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {items.map((value, index) => (
                <tr class="item-row">
                  <td>{value.description}</td>
                  <td>${value.price.amount.toFixed(2)}</td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-warning"
                      onClick={() => processRemove(index)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
              <tr>
                <td colspan="2" className="grand-total-cell">
                  Grand total
                </td>
                <td>
                  $
                  {(
                    items
                      .map((x) => x.price.amount)
                      .reduce((a, b) => a + b, 0)
                  ).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <p>
            Currently, there are no invoice items. Please add some invoice
            items.
          </p>
        )}

        {errorVisible && (
          <div class="alert alert-danger" role="alert">
            {errorText}
          </div>
        )}
      </div>
    </>
  );
}

export default InvoiceItemsForm;
