import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { paths } from "../../utilities/constants";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import {
  cancelReservation,
  deleteReservation,
  getCurrentUserReservationSettings,
  getReservations,
} from "../../utilities/apiUtils";
import CommonModal from "../../components/commonModal/CommonModal";
import { Button } from "react-bootstrap";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import Table from "../../components/table/Table";
import { formatTime, getDateFromDatabaseTime } from "../../utilities/timeUtils";
import { getUserName } from "../../utilities/itemUtils";
import ReservationsFilterForm from "./ReservationsFilterForm";

function ReservationsListPage(props) {
  const [reservations, setReservations] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [filterReservations, setFilterReservations] = useState(false);
  const [entryTime, setEntryTime] = useState(null);
  const [exitTime, setExitTime] = useState(null);

  useEffect(() => {
    loadReservations();
  }, []);

  const loadReservations = (filter = null) => {
    setPageLoading(true);
    getReservations(filter).then((res) => {
      if (res.data) {
        setReservations(res.data);
        setPageLoading(false);
      }
    });
  };

  const getReservationRow = (reservation) => {
    const now = new Date();
    const entryDate = getDateFromDatabaseTime(reservation.entryTime);
    const entryDatFormatted = formatTime(entryDate);
    const exitDate = new Date(
      entryDate.getTime() + reservation.durationMinutes * 60000
    );
    const exitDateFormatted =
      reservation.durationMinutes > 0 ? formatTime(exitDate) : "Not specified";
    let state = "";
    if (entryDate.getTime() > now.getTime()) {
      state = "Planned";
    } else {
      if (
        reservation.durationMinutes > 0 &&
        exitDate.getTime() >= now.getTime()
      ) {
        state = "Active";
      } else {
        state = "Complete";
      }
    }

    return (
      <tr key={reservation.id}>
        <td>{getUserName(reservation.user)}</td>
        <td>
          {(reservation.user &&
            reservation.user.driverGroup &&
            reservation.user.driverGroup.name) ||
            "-"}
        </td>
        <td>{entryDatFormatted}</td>
        <td>{exitDateFormatted}</td>
        <td>{reservation.parkingLot.name}</td>
        <td>{reservation.licensePlateNumber}</td>
        <td>{state}</td>
      </tr>
    );
  };

  const handleFilter = () => {
    let entryTimeUtc = 0;
    let exitTimeUtc = 0;

    const query = {};
    if (entryTime != null) {
      entryTimeUtc = Math.floor(
        getDateFromDatabaseTime(entryTime).getTime() / 1000
      );
      query.entryTimeUTC = {
        gte: entryTimeUtc,
      };
    }

    if (exitTime != null) {
      exitTimeUtc = Math.floor(
        getDateFromDatabaseTime(exitTime).getTime() / 1000
      );
      query.exitTimeUTC = {
        lte: exitTimeUtc,
      };
    }

    loadReservations(query);
    setFilterReservations(false);
  };

  const toggleFilterModal = (value = true) => {
    setFilterReservations(value);
  };

  const handleOnChanged = (value) => {
    setEntryTime(value.entryTime);
    setExitTime(value.exitTime);
  };

  return (
    <>
      <CommonModal
        show={!!filterReservations}
        onClose={() => toggleFilterModal(false)}
        body={
          <ReservationsFilterForm
            entryTime={entryTime}
            exitTime={exitTime}
            onChanged={(value) => handleOnChanged(value)}
          />
        }
        title="Filter reservations"
        footer={
          <div class="action-buttons">
            <div class="row">
              <PrimaryButton
                value="Cancel"
                onClick={() => toggleFilterModal(false)}
              />
              <PrimaryButton
                value="Filter"
                onClick={() => handleFilter(false)}
              />
            </div>
          </div>
        }
      />
      {pageLoading ? (
        <div class="alert alert-primary">Loading.</div>
      ) : (
        <>
          <div class="row">
            <div class="col">
              <div class="float-right">
                <PrimaryButton
                  value="Filter reservations"
                  onClick={() => toggleFilterModal()}
                />
              </div>
            </div>
          </div>
          {reservations && !reservations.length && (
            <>
              <br />
              <div class="alert alert-primary">There are no reservations.</div>
            </>
          )}
          {reservations && !!reservations.length && (
            <>
              <Table
                headers={[
                  <th>Driver</th>,
                  <th>Driver group</th>,
                  <th>Entry time</th>,
                  <th>Exit time</th>,
                  <th>Parking lot</th>,
                  <th>License plate</th>,
                  <th>State</th>,
                ]}
                generateRow={getReservationRow}
                items={reservations}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export default withRouter(ReservationsListPage);
