export const processSaveResult = (
  result,
  history,
  successLocation,
  setErrors
) => {
  if (result.error && result.error.details) {
    setErrors(result.error.details.map((x) => `${x.code}: ${x.message}`));
    return;
  }

  if (result.data.items) {
    setErrors(result.data.items.map((x) => `${x.code}: ${x.details.info}`));
  } else if (result.status == 200 && result.data) {
    history.push(successLocation);
  } else {
    setErrors([`${result.status}: ${result.statusText}`]);
  }
};
