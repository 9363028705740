import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Tabs from "../../components/tabs/Tabs";

function FormTabs(props) {
  const [
    item,
    onChanged,
    onSave,
    onCancel,
    tabs,
    history,
    pageProps,
    additionalProps,
  ] = [
    props.item,
    props.onChanged,
    props.onSave,
    props.onCancel,
    props.tabs,
    props.history,
    props.pageProps,
    props.additionalProps || {},
  ];

  const [currentTab, setCurrentTab] = useState(0);

  const handleOnNext = () => {
    setCurrentTab(currentTab + 1);
  };

  const handleOnBack = () => {
    setCurrentTab(currentTab - 1);
  };

  const tabsConverted = tabs.map((tab) => {
    return {
      title: tab.title,
      body: React.cloneElement(tab.body, {
        item: item,
        onChanged: onChanged,
        onCancel: onCancel,
        onNext: () => handleOnNext(),
        onBack: () => handleOnBack(),
        onSave: onSave,
        history: history,
        pageProps: pageProps,
        additionalProps: additionalProps,
      }),
    };
  });

  return (
    <Tabs
      currentTabNumber={currentTab}
      tabsCanBeChangedManually={true}
      tabs={tabsConverted}
      onTabChanged={(tab) => setCurrentTab(tab)}
    />
  );
}

export default withRouter(FormTabs);
