import "./PrimaryButton.scss";

export default function PrimaryButton(props) {
  const { onClick, value, disabled, id, className, svg } = props;
  return (
    <button
      onClick={onClick}
      className={`${className} alot-primary-button`}
      disabled={disabled}
      id={id}
    >
      {svg}
      {value}
    </button>
  );
}
