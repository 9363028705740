export const formatUnixTimeStampSeconds = (seconds) => {
  return formatDateTime(new Date(seconds * 1000));
};

export const formatDatabaseTime = (time) => {
  const date = getDateFromDatabaseTime(time);
  return formatDateTime(date);
};

export const getDateFromDatabaseTime = (time) => {
  const year = parseInt(time.year);
  const month = parseInt(time.month);
  const day = parseInt(time.day);
  const hour = parseInt(time.hour || 0);
  const minute = parseInt(time.minute || 0);
  const second = parseInt(time.second || 0);
  return new Date(year, month - 1, day, hour, minute, second, 0);
};

export const formatDateTime = (date) => {
  return new Intl.DateTimeFormat(undefined, {
    dateStyle: "short",
    timeStyle: "medium",
  }).format(date);
};

export const formatDate = (date) => {
  return new Intl.DateTimeFormat(undefined, { dateStyle: "long" }).format(date);
};

export const formatTime = (date) => {
  return new Intl.DateTimeFormat(undefined, {
    dateStyle: "long",
    timeStyle: "long",
  }).format(date);
};

export const formatDatabaseCalendarDate = (time) => {
  const date = getDateFromDatabaseTime(time);
  return formatDate(date);
};

export const isDateBeforeToday = (date) => {
  return new Date(date.toDateString()) < new Date(new Date().toDateString());
};
