import React, { useState, useEffect } from "react";
import { find, remove } from "lodash";
import SecondaryInputField from "../secondaryInputField/SecondaryInputField";
import "./HourlyConfigurationForm.scss";
import { countDecimals, isNumeric } from "../../utilities/numberUtils";
import { hoursValid } from "./PaymentsConfigUtil";

function HourlyConfigurationForm(props) {
  const [validationCounter, onChanged] = [props.validationCounter || false, props.onChanged]
  const [prices, setPrices] = useState(props.prices || []);
  const [hourErrors, setHourErrors] = useState({});

  const hourError = (hour) => {
    const price = getPrice(hour);
    if (price == undefined) {
      return "Value is not defined.";
    } else if (!isNumeric(price)) {
      return "Value must be a number.";
    } else if (parseFloat(price) <= 0) {
      return "Value must be greater than zero.";
    } else if (countDecimals(price) > 2) {
      return "There can be no more than to digits after decimal.";
    }

    return "";
  };

  useEffect(() => {
    if (validationCounter < 1) {
      return;
    }

    const errors = {};
    for (let i = 0; i < 24; i++) {
      errors[i] = hourError(i);
    }
    setHourErrors(errors);
  }, [validationCounter]);

  const setHourlyPrice = (hour, value) => {
    const newPrices = prices;
    if (find(newPrices, (x) => x.hour == hour) != undefined) {
      remove(newPrices, (x) => x.hour == hour);
    }
    
    newPrices.push({
      hour: hour,
      price: {
        amount: value,
        currency: 'USD'
      }
    });
    setPrices([...newPrices]);
    console.log("hours-valid", hoursValid(newPrices));
    onChanged(newPrices, hoursValid(newPrices));
  };

  const amToHour = (amHour) => {
    return amHour == 12 ? 0 : amHour;
  };

  const pmToHour = (pmHour) => {
    return pmHour == 12 ? 12 : pmHour + 12;
  };

  const getPrice = (hour) => {
    const price = find(prices, (x) => x.hour == hour);
    return price != undefined ? price.price.amount : undefined;
  };

  return (
    <div className="form-group hourly-config-form">
      <label>Prices per hour ($)</label>
      <table>
        <thead>
          <tr>
            <th>Hour</th>
            <th>AM</th>
            <th>PM</th>
          </tr>
        </thead>
        <tbody>
      {
        [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((hour) => <>
          <tr>
            <td>{hour < 10 ? '0' : ''}{hour}:00/{hour < 10 ? '0' : ''}{hour}:59</td>
            <td><SecondaryInputField onChange={(event) => setHourlyPrice(amToHour(hour), event.target.value)} value={getPrice(amToHour(hour))} /></td>
            <td><SecondaryInputField onChange={(event) => setHourlyPrice(pmToHour(hour), event.target.value)} value={getPrice(pmToHour(hour))} /></td>
          </tr>
          <tr>
            <td></td>
            <td>{hourErrors[amToHour(hour)] != "" && <div style={{ display: "block" }} className="invalid-feedback">{hourErrors[amToHour(hour)]}</div>}</td>
            <td>{hourErrors[pmToHour(hour)] != "" && <div style={{ display: "block" }} className="invalid-feedback">{hourErrors[pmToHour(hour)]}</div>}</td>
          </tr>
        </>
        )
      }
        </tbody>
      </table>
    </div>
  );
}

export default HourlyConfigurationForm;
