import { paymentStrategies } from "../../utilities/constants";
import { countDecimals, isNumeric } from "../../utilities/numberUtils";

export const hoursValid = (hoursConfig) => {
  if (hoursConfig.length != 24) {
    return false;
  }

  for (let i = 0; i < 24; i++) {
    const price = hoursConfig[i].price.amount;
    const priceIsValid = isNumeric(price) && parseFloat(price) > 0 && countDecimals(price) <= 2;
    if (!priceIsValid) {
      return false;
    }
  }

  return true;
};

export const priceIsValid = (priceValue) => {
  return isNumeric(priceValue) && parseFloat(priceValue) > 0 && countDecimals(priceValue) <= 2;
};

export const parseDriverPaymentSettings = (settings) => {
  if (settings.strategy == paymentStrategies.Hourly || settings.strategy == paymentStrategies.HourlySpecified) {
    settings.gracePeriodMinutes = parseInt(settings.gracePeriodMinutes);
  } else {
    settings.gracePeriodMinutes = 0;
  }

  if (settings.strategy == paymentStrategies.HourlySpecified) {
    settings.price.amount = 0.0;
    settings.priceSpecified = settings.priceSpecified.map((value) => {
      return {
        hour: value.hour,
        price: {
          amount: parseFloat(value.price.amount),
          currency: value.price.currency,
        },
      };
    });
  } else {
    settings.price.amount = parseFloat(settings.price.amount);
    settings.priceSpecified = [];
  }
  
  return settings;
};

export const parseClientPaymentSettings = (settings) => {
  settings.price.amount = parseFloat(settings.price.amount);
  settings.priceSpecified = [];
  settings.revenuePercent = settings.revenuePercent != undefined ? parseFloat(settings.revenuePercent) : 0.0;
  settings.gracePeriodMinutes = 0;
  return settings;
};

export const revenuePercentageValid = (value) => {
  return isNumeric(value) && parseFloat(value) > 0 && parseFloat(value) <= 100;
};