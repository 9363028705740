import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import EditForm from "../../components/field/EditForm";
import { getGateDataItem, saveGateData } from "../../utilities/apiUtils";
import { withRouter } from "react-router-dom";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import "../accessManagementGateManagement/GateManagementListPage.scss";
import { paths } from "../../utilities/constants";
import TextArea from "../../components/field/TextArea";
import Tabs from "../../components/tabs/Tabs";

function ModifyGatePage(props) {
  const { id } = props.match.params;
  const [gateDataFetched, setGateDataFetched] = useState(false);
  const [gateData, setGateData] = useState({});
  const [comments, setComments] = useState("");
  const [keepOpen, setKeepOpen] = useState(false);

  useEffect(() => {
    getGateDataItem(id).then((res) => {
      if (res.data) {
        if (res.data.direction != "") {
          setComments(res.data.comments);
          setKeepOpen(true);
        }

        setGateData(res.data);
        setGateDataFetched(true);
      }
    });
  }, []);

  const cancel = () => {
    props.history.goBack();
  };

  const save = () => {
    saveGateData({
      controlUnitId: id,
      direction: "all",
      durationMinutes: 0,
      action: keepOpen ? "nothing" : "open",
      comments: comments,
    }).then((_) => {
      props.history.push(`${paths.accessManagementPath}/gate-management`);
    });
  };

  const changeComments = (value) => {
    setComments(value.trim());
  };

  const changeKeepOpenState = (value) => {
    setKeepOpen(value);
  };

  const formComponents = [
    <TextArea
      key={1}
      value={comments}
      placeholder="Enter your comments"
      validationController={(value) => {
        return {
          success: true,
          text: "",
        };
      }}
      onValueChanged={(value) => changeComments(value)}
      label="Comments (optional)"
    />,
    <FormControlLabel
      key={2}
      control={
        <Checkbox
          checked={keepOpen}
          onChange={(event) => changeKeepOpenState(event.target.checked)}
          color="primary"
        />
      }
      label="Leave the gate open"
    />,
  ];

  const form = (
    <EditForm
      components={formComponents}
      continueAction={<PrimaryButton value="Open the Gate" />}
      cancelAction={<PrimaryButton onClick={() => cancel()} value="Cancel" />}
      onValidated={(result) => {
        if (result) {
          save();
        }
      }}
    />
  );

  return (
    <>
      <Header />
      <div className="content-page">
        <div class="container-fluid">
          <div class="row">
            <Sidebar />
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10">
              {gateDataFetched ? (
                <Tabs
                  tabs={[
                    {
                      title: "Control gate",
                      body: (
                        <>
                          <h4>{gateData.controlUnit.name}</h4>
                          <br />
                          {form}
                        </>
                      ),
                    },
                  ]}
                />
              ) : (
                <div class="alert alert-primary">Loading.</div>
              )}
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(ModifyGatePage);
