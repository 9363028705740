import React, { useState, useEffect } from "react";
import PrimaryButton from "../../../components/primaryButton/PrimaryButton";
import EditForm from "../../../components/field/EditForm";
import { parseClientPaymentSettings } from "../../../components/payments/PaymentsConfigUtil";
import { getClientPaymentSettings, saveClientPaymentSettings } from "../../../utilities/apiUtils";
import SpinnerImage from "../../../assets/images/spinner.gif";
import ClientPaymentConfigurationForm from "../../../components/payments/ClientPaymentConfigurationForm";

function ClientPaymentSettingsForm(props) {
  const [settingsFetched, setSettingsFetched] = useState(false);
  const [settings, setSettings] = useState({});
  const [settingsSaved, setSettingsSaved] = useState(false);
  const [saveInProgress, setSettingsSaveInProgress] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [validationCounter, setValidationCounter] = useState(0);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    getClientPaymentSettings().then((res) => {
      if (res.data) {
        setSettings(res.data);
        setSettingsFetched(true);
      }
    });
  }, []);

  const cancel = () => {
    props.history.goBack();
  };

  const save = () => {
    setValidationCounter(validationCounter + 1);
    console.log("isValid", isValid);
    setSettingsSaved(false);
    if (!isValid) {
      return;
    }

    setSettingsSaveInProgress(true);
    const settingsParsed = parseClientPaymentSettings(settings);
    setSettings(settingsParsed);

    saveClientPaymentSettings(settingsParsed).then((res) => {
      setSettingsSaveInProgress(false);
      if (res.data) {
        setSettingsSaved(true);
      } else {
        setErrorText(`Settings save failed. Details: ${JSON.stringify(res)}.`);
      }
    });
  };

  const processSettingsChanged = (newSettings, isValid) => {
    setSettings(newSettings);
    setIsValid(isValid);
  };

  const formComponents = [
    <ClientPaymentConfigurationForm
      settings={settings}
      onChanged={processSettingsChanged}
      validationCounter={validationCounter}
    />
  ];

  const form = (
    <EditForm
      components={formComponents}
      continueAction={<PrimaryButton value="Save" />}
      cancelAction={<PrimaryButton onClick={() => cancel()} value="Cancel" />}
      onValidated={(result) => {
        if (result) {
          save();
        }
      }}
    />
  );

  return (
    <div className="clientPaymentSettingsForm">
      {settingsSaved && <div class="alert alert-success">Settings saved.</div>}
      {saveInProgress && <div class="alert alert-primary"><img src={SpinnerImage} /> Saving settings...</div>}
      {errorText != "" && <div className="alert alert-danger">{errorText}</div>}
      {settingsFetched ? (
        <div>{form}</div>
      ) : (
        <div class="alert alert-primary"><img src={SpinnerImage} /> Loading...</div>
      )}
    </div>
  );
}

export default ClientPaymentSettingsForm;
