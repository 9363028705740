import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { find } from "lodash";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function ComboBox(props) {
  const classes = useStyles();

  const [
    label,
    options,
    validationController,
    onValueChanged,
    selectionPlaceholder,
    // EditForm-specific
    validationCounter,
    onValidated,
    nameGetter,
  ] = [
    props.label,
    props.options,
    props.validationController,
    props.onValueChanged,
    props.selectionPlaceholder,
    // EditForm-specific
    props.validationCounter,
    props.onValidated,
    props.nameGetter ||
      ((item) => {
        return item.name;
      }),
  ];

  const [value, setValue] = useState(props.value || {});
  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    setSelectedId(props.value.id);
    setValue(props.value);
  }, [props.value]);

  const handleChange = (event) => {
    const id = event.target.value;
    const option = find(options, (x) => x.id == id);
    setValue(option == undefined ? {} : option);
    setSelectedId(id);
    onValueChanged(option == undefined ? {} : option);
  };

  const [errorVisible, setErrorVisible] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    setErrorVisible(false);
    setErrorText("");

    if (!props.validationCounter || props.validationCounter <= 0) {
      return;
    }

    const result = validationController(value);
    setErrorVisible(!result.success);
    setErrorText(result.text);
    onValidated(result);
  }, [validationCounter]);

  return (
    <>
      <div className={`form-group`}>
        <FormControl className={classes.formControl}>
          <InputLabel>{label}</InputLabel>
          <Select value={selectedId} onChange={handleChange}>
            <MenuItem value="">
              <em>{selectionPlaceholder}</em>
            </MenuItem>
            {options.map((value) => (
              <MenuItem value={value.id}>{nameGetter(value)}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {errorVisible && (
          <div style={{ display: "block" }} class="invalid-feedback">
            {errorText}
          </div>
        )}
      </div>
    </>
  );
}

export default ComboBox;
