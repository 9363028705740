import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.75rem;
  height: 1.75rem;
  background-color: rgb(254, 211, 9);
  border: 0.2rem solid #000000;
  color: #000000;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  &:hover {
    z-index: 1;
  }
`;

const Marker = ({ text, onClick }) => (
  <Wrapper alt={text} onClick={onClick}>
    {text}
  </Wrapper>
);

Marker.defaultProps = {
  onClick: null,
};

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default Marker;
