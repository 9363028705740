import React from "react";
import { withRouter } from "react-router-dom";
import GenericModifyPage from "../genericModifyPage/GenericModifyPage";
import { ClientCrudConfig } from "../multiUserManagementModifyClient/ClientCrudConfig";

function ModifySubAdminPage(props) {
  const { id } = props.match.params;

  return (
    <GenericModifyPage
      id={id}
      getTitle={ClientCrudConfig.getSubAdminTitle}
      fetch={ClientCrudConfig.fetch}
      save={ClientCrudConfig.save}
      indexPage={ClientCrudConfig.subAdminIndexPage}
      itemForm={ClientCrudConfig.subAdminItemForm}
      validate={ClientCrudConfig.validate}
    />
  );
}

export default withRouter(ModifySubAdminPage);
