import React, { useState, useEffect } from "react";
import EditImage from "../../assets/images/edit.svg";
import DeleteImage from "../../assets/images/delete.svg";
import { withRouter } from "react-router-dom";
import { paths } from "../../utilities/constants";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import {
  deleteClient,
  getAdmins,
  getSubAdmins,
} from "../../utilities/apiUtils";
import CommonModal from "../../components/commonModal/CommonModal";
import Table from "../../components/table/Table";
import { Button } from "react-bootstrap";
import { getUserName, getCompany } from "../../utilities/itemUtils";
import CreateItemButton from "../../components/createItemButton/CreateItemButton";
import PageTitle from "../../components/pageTitle/PageTitle";
import SecondaryButton from "../../components/secondaryButton/SecondaryButton";
import ActionButton from "../../components/actionButton/ActionButton";

function ClientsPage(props) {
  const type = props.type || "admin";
  const [clients, setClients] = useState(null);
  const [clientToBeDeleted, setClientToBeDeleted] = useState(null);

  useEffect(() => {
    loadClients();
  }, []);

  const loadClients = () => {
    const resource = type == "admin" ? getAdmins() : getSubAdmins();
    resource.then((res) => {
      if (res.data) {
        setClients(res.data);
      }
    });
  };

  const handleClientDelete = () => {
    deleteClient(clientToBeDeleted).then(() => {
      toggleDeleteModal();
      loadClients();
    });
  };

  const toggleDeleteModal = (id = null) => {
    setClientToBeDeleted(id);
  };

  const toggleEdit = (id = null) => {
    const listPage = type == "admin" ? `admins` : `sub-admins`;
    props.history.push(`${paths.multiUserManagementPath}/${listPage}/${id}`);
  };

  const getClientRow = (clientData) => {
    return (
      <tr>
        <td>{`${getUserName(clientData.client)}`}</td>
        <td>{`${getCompany(clientData.client.company)}`}</td>
        <td>
          <img
            alt="Edit"
            title="Edit"
            onClick={() => {
              toggleEdit(clientData.client.id);
            }}
            src={EditImage}
            className="action"
          />

          <img
            alt="Delete"
            title="Delete"
            onClick={() => {
              toggleDeleteModal(clientData.client.id);
            }}
            src={DeleteImage}
            className="action"
          />
        </td>
      </tr>
    );
  };

  return (
    <>
      <Header />
      <CommonModal
        show={!!clientToBeDeleted}
        onClose={toggleDeleteModal}
        body="Are you sure you want to delete this client?"
        title="Client removal"
        footer={
          <div>
            <SecondaryButton
              value="Cancel"
              onClick={() => toggleDeleteModal()}
            />
            <ActionButton value="Delete" onClick={() => handleClientDelete()} />
          </div>
        }
      />

      <div className="content-page">
        <div class="container-fluid">
          <div class="row">
            <Sidebar />
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10">
              <PageTitle value={type == "admin" ? "Admins" : "Sub-admins"} />
              <CreateItemButton
                path={`${paths.multiUserManagementPath}/${
                  type == "admin" ? "admins" : "sub-admins"
                }/new`}
                value={
                  type == "admin" ? "Create new admin" : "Create new sub-admin"
                }
              />
              {clients && !clients.length && (
                <div class="alert alert-primary">
                  Currently there are no clients created.
                </div>
              )}
              {clients && !!clients.length && (
                <>
                  <Table
                    headers={[
                      <th>{type == "admin" ? "Admin" : "Sub-admin"}</th>,
                      <th>Company</th>,
                      <th>Actions</th>,
                    ]}
                    generateRow={getClientRow}
                    items={clients}
                  />
                </>
              )}
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(ClientsPage);
