import "./SecondaryInputField.scss";

export default function SecondaryInputField(props) {
  const {
    type,
    placeholder,
    value,
    onChange,
    required,
    autofocus,
    style,
    className,
  } = props;

  return (
    <input
      type={type}
      value={value}
      style={style}
      placeholder={placeholder}
      onChange={(event) => onChange(event)}
      required={required}
      autofocus={autofocus}
      className={`alot-secondary-input ${className}`}
    />
  );
}
