import React from "react";
import "./FieldsContainer.scss";

function FieldsContainer(props) {
  const { components, label } = props;

  return (
    <>
      <div class="form-group fields-container">
        <p class="fields-container-label">{label}</p>
        <div class="row fields-container-details">
          {!!components &&
            components.map((el) => <div class="col-6">{el}</div>)}
        </div>
      </div>
    </>
  );
}

export default FieldsContainer;
