import React, { useEffect, useState, useContext } from "react";
import Table from "../../components/table/Table";
import { Link, withRouter } from "react-router-dom";
import Header from "../../components/header/Header";
import { get } from "lodash";
import Sidebar from "../../components/sidebar/Sidebar";
import { getParkingLotSummaryRecords } from "../../utilities/apiUtils";
import { paths, roles } from "../../utilities/constants";
import ViewImage from "../../assets/images/view.svg";
import GlobalContext from "../../utilities/context/GlobalContext";
import "./DashboardPage.scss";
import PageTitle from "../../components/pageTitle/PageTitle";
import { wrapInHierarchy } from "../../utilities/itemUtils";

function DashboardPage(props) {
  const context = useContext(GlobalContext);
  const [parkingLots, setParkingLots] = useState([]);
  const currentUser = get(context, "state.user", "");
  const currentRole = get(currentUser, "roleData.role", "");

  useEffect(() => {
    getParkingLotSummaryRecords().then((res) => {
      if (res.data) {
        console.log(
          wrapInHierarchy(
            res.data.filter((x) => x.entityType != "driver-group"),
            (el) => el.lot.id,
            (el) => el.parentId
          )
        );
        setParkingLots(
          wrapInHierarchy(
            res.data.filter((x) => x.entityType != "driver-group"),
            (el) => el.lot.id,
            (el) => el.parentId
          )
        );
      }
    });
  }, []);

  useEffect(() => {
    if (currentRole === roles.Driver) {
      props.history.push(`${paths.accessManagementPath}/reservations`);
    }
  }, [currentRole]);

  const toggleDetailsView = (id = null) => {
    props.history.push(
      `${paths.accessManagementPath}/parking-lots/details-view/${id}`
    );
  };

  const getItem = (record, nestingLevel) => {
    const item = record.element;
    return (
      <tr>
        <td className={"nesting-level-" + nestingLevel}>{item.lot.name}</td>
        <td>{item.stats.reservationsToday}</td>
        <td>{item.stats.reservationsWeek}</td>
        <td>
          {item.stats.occupancyActive} out of {item.stats.maxCars}
        </td>
        <td>
          <img
            alt="View"
            title="View"
            onClick={() => {
              toggleDetailsView(item.lot.id);
            }}
            src={ViewImage}
            className="action"
          />
        </td>
      </tr>
    );
  };

  const getParkingLotRow = (record, nestingLevel = 0) => {
    if (record.children.length == 0) {
      return [getItem(record, nestingLevel)];
    }

    const rows = [getItem(record, nestingLevel)];

    record.children.forEach((record) => {
      getParkingLotRow(record, nestingLevel + 1).forEach((r) => {
        rows.push(r);
      });
    });

    return [...rows];
  };

  return (
    <>
      <Header />

      <div className="content-page">
        <div class="container-fluid">
          <div class="row">
            <Sidebar />
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10">
              <PageTitle value="Dashboard" />
              {parkingLots && !parkingLots.length && (
                <div class="alert alert-primary">
                  Currently there are no parking lots created. In order to see
                  something in the dashboard, you must{" "}
                  <Link to={`${paths.accessManagementPath}/parking-lots/new`}>
                    create a new parking lot.
                  </Link>
                </div>
              )}
              {parkingLots && !!parkingLots.length && (
                <Table
                  headers={[
                    <th>Parking lot</th>,
                    <th>Reservations today</th>,
                    <th>Reservations this week</th>,
                    <th>Occupancy</th>,
                    <th>Actions</th>,
                  ]}
                  generateRow={getParkingLotRow}
                  items={parkingLots}
                />
              )}
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(DashboardPage);
