import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pageTitle/PageTitle";
import Sidebar from "../../components/sidebar/Sidebar";
import { BFF_URL, getClient, getInvoice } from "../../utilities/apiUtils";

import "./InvoiceDetailsPage.scss";

function InvoiceDetailsPage(props) {
  const { id } = props.match.params;
  const [invoice, setInvoice] = useState(null);
  const [client, setClient] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getInvoice(id).then((res) => {
      if (res.data) {
        setInvoice(res.data);

        getClient(res.data.clientId).then((res) => {
          if (res.data) {
            setClient(res.data.client);
            setLoaded(true);
          }
        });
      }
    });
  }, [id]);

  return (
    <>
      <Header />

      <div className="content-page invoice-details-page">
        <div class="container-fluid">
          <div class="row">
            <Sidebar />
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10">
              <PageTitle value={"Invoice - " + invoice?.invoiceNumber} />
              <div class="card">
                {!loaded && <div class="card-body">Loading.</div>}
                {loaded && (
                  <div class="card-body">
                    <div class="form-group">
                      <button
                        type="button"
                        class="alot-primary-button"
                        onClick={() => props.history.goBack()}
                      >
                        Go back
                      </button>
                      <Link
                        to={{ pathname: BFF_URL + `/invoices/pdf/${id}.pdf` }}
                        target="_blank"
                      >
                        <button type="button" class="alot-primary-button">
                          Get PDF
                        </button>
                      </Link>
                    </div>
                    <table className="invoice-preview-body">
                      <tr>
                        <td class="main-cell" align="left">
                          <table>
                            <tr>
                              <td colspan="2">
                                <h2>Responsible person</h2>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "right" }}>
                                <strong>Name</strong>
                              </td>
                              <td>{invoice.company.name}</td>
                            </tr>
                            <tr>
                              <td align="right">
                                <strong>ID</strong>
                              </td>
                              <td>{invoice.company.taxId}</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "right" }}>
                                <strong>Vat ID</strong>
                              </td>
                              <td>
                                {invoice.company.vatId == ""
                                  ? "-"
                                  : invoice.company.vatId}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "right" }}>
                                <strong>Address</strong>
                              </td>
                              <td>{invoice.company.address}</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "right" }}>
                                <strong>Phone</strong>
                              </td>
                              <td>{invoice.company.phoneNumber}</td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <h2>Company info</h2>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "right" }}>
                                <strong>Name</strong>
                              </td>
                              <td>{client.company.name}</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "right" }}>
                                <strong>ID</strong>
                              </td>
                              <td>{client.company.taxId}</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "right" }}>
                                <strong>Vat ID</strong>
                              </td>
                              <td>
                                {client.company.vatId == ""
                                  ? "-"
                                  : client.company.vatId}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "right" }}>
                                <strong>Address</strong>
                              </td>
                              <td>{client.company.address}</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "right" }}>
                                <strong>Phone</strong>
                              </td>
                              <td>{client.company.phoneNumber}</td>
                            </tr>
                          </table>
                        </td>
                        <td class="main-cell" style={{ textAlign: "right" }}>
                          <table width="100%">
                            <tr>
                              <td colspan="2">
                                <h2>Invoice</h2>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "right" }}>
                                <strong>Invoice number</strong>
                              </td>
                              <td>{invoice.invoiceNumber}</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "right" }}>
                                <strong>Invoice date</strong>
                              </td>
                              <td>{`${invoice.date.year}-${invoice.date.month}-${invoice.date.day}`}</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "right" }}>
                                <strong>Invoice deadline</strong>
                              </td>
                              <td>{`${invoice.deadline.year}-${invoice.deadline.month}-${invoice.deadline.day}`}</td>
                            </tr>
                          </table>
                          <table width="100%">
                            <tr>
                              <td colspan="2">
                                <h2>Items</h2>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Item description</strong>
                              </td>
                              <td>
                                <strong>Amount</strong>
                              </td>
                            </tr>
                            {invoice.items.map((x) => (
                              <tr>
                                <td>{x.description}</td>
                                <td>${x.price.amount.toFixed(2)}</td>
                              </tr>
                            ))}
                            <tr>
                              <td style={{ textAlign: "right" }}>
                                <strong>Grand total</strong>
                              </td>
                              <td>
                                ${invoice.total.amount.toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <h2>Payment details</h2>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                {invoice.managed
                                  ? "Invoice is managed by a.Lot"
                                  : invoice.paymentDetails == ""
                                  ? "-"
                                  : invoice.paymentDetails}
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </div>
                )}
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(InvoiceDetailsPage);
