import React from "react";
import { InputLabel, MenuItem, Select } from "@material-ui/core";

function YearPicker(props) {
  const [getter, setter, minValue, maxLimit] = [
    props.getter,
    props.setter,
    props.minValue || new Date().getFullYear(),
    props.maxLimit || 5,
  ];

  const handleYearChange = (event) => {
    setter(event.target.value);
  };

  const items = [];
  const limit = minValue + maxLimit;
  for (let year = minValue; year <= limit; year++) {
    items.push(year);
  }

  return (
    <div class="form-group">
      <InputLabel id="year-selection">Select year</InputLabel>
      <Select
        labelId="year-selection"
        value={getter}
        onChange={handleYearChange}
      >
        {items.map((item) => (
          <MenuItem value={item.toString()}>{item}</MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default YearPicker;
