import React, { useState, useEffect } from "react";
import "./TextArea.scss";

function TextArea(props) {
  const [
    placeholder,
    validationController,
    onValueChanged,
    label,
    disabled,
    // EditForm-specific
    validationCounter,
    onValidated,
  ] = [
    props.placeholder,
    props.validationController,
    props.onValueChanged,
    props.label,
    props.disabled,
    // EditForm-specific
    props.validationCounter,
    props.onValidated,
  ];

  const [value, setValue] = useState(props.value || "");
  const [errorVisible, setErrorVisible] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    setValue(props.value || "");
  }, [props.value]);

  useEffect(() => {
    setErrorVisible(false);
    setErrorText("");

    if (!props.validationCounter || props.validationCounter <= 0) {
      return;
    }

    const result = validationController(value);
    setErrorVisible(!result.success);
    setErrorText(result.text);
    onValidated(result);
  }, [validationCounter]);

  return (
    <div class={`form-group ${disabled ? " disabled" : ""}`}>
      {label && <label>{label}</label>}
      <textarea
        value={value}
        className="form-control"
        onChange={(event) => {
          setValue(event.target.value);
          onValueChanged(event.target.value);
        }}
        placeholder={placeholder}
        class="alot-textarea-input"
      />
      {errorVisible && (
        <div class="alert alert-danger" role="alert">
          {errorText}
        </div>
      )}
    </div>
  );
}

export default TextArea;
