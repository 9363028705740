export const methods = {
  GET: "get",
  POST: "post",
  PATCH: "patch",
  DELETE: "delete",
  PUT: "put",
};

export const tokenConstants = {
  TOKEN_STORAGE_KEY: "JWT",
  TOKEN_SECRET_VALUE: "EDtinFhPJz",
};

export const roles = {
  MasterUser: "master-user",
  SuperAdmin: "super-admin",
  Admin: "admin",
  SubAdmin: "sub-admin",
  Driver: "driver",
};

export const shareTypes = {
  // Owns the parking lot, i.e. is a super admin.
  Owner: "owner",

  // Can edit respective details of the parking lot. Can be admin or subadmin.
  Administrator: "administrator",

  // Driver group has parking lot spaces assigned.
  DriverGroup: "driver-group",

  // Maybe can decide who enters the parking lot or not? Subadmin only probably.
  Manager: "manager",

  // Can just view details of the lot. Subadmin only probably.
  ViewOnly: "view-only",
};

export const permissions = {
  Default: "default",
  ViewOnly: "view-only",
  Manager: "manager",
  Administrator: "administrator",
};

export const paths = {
  accessManagementPath: "/access-management",
  revenueanagementPath: "/revenue-management",
  multiUserManagementPath: "/multi-user-management",
  settings: "/settings",
};

export const links = {
  Dashboard: "/dashboard",
  Analytics: "/analytics",
  ParkingLots: `${paths.accessManagementPath}/parking-lots`,
  Drivers: `${paths.accessManagementPath}/drivers`,
  GateManagement: `${paths.accessManagementPath}/gate-management`,
  Reservations: `${paths.accessManagementPath}/reservations`,
  Payments: `${paths.revenueanagementPath}/payments`,
  Invoices: `${paths.revenueanagementPath}/invoices`,
  Billing: `${paths.revenueanagementPath}/billing`,
  Admins: `${paths.multiUserManagementPath}/admins`,
  SubAdmins: `${paths.multiUserManagementPath}/sub-admins`,
  DriverGroups: `${paths.multiUserManagementPath}/driver-groups`,
  ReservationSettings: `${paths.settings}/reservation`,
  DriverPaymentSettings: `${paths.settings}/driver-payments`,
  ClientPaymentSettings: `${paths.settings}/client-payments`,
};

export const technologiesUsedOptions = [
  {
    name: "License Plate Recognition",
    id: "lpr",
  },
  {
    name: "Bluetooth",
    id: "bluetooth",
  },
  {
    name: "Manual",
    id: "manual",
  },
];

export const paymentStrategies = {
  Free: 'free',
  Hourly: 'hourly',
  HourlySpecified: 'hourly-specified',
  Daily: 'daily',
  Weekly: 'weekly',
  Monthly: 'monthly',
  FlatFee: 'flat-fee',
  PricePerSpot: 'price-per-spot',
  FlatFeePlusRevenue: 'flat-fee-plus-revenue',
};

export const driverPaymentStrategies = [
  {
    key: paymentStrategies.Free,
    name: 'Free',
  },
  {
    key: paymentStrategies.Hourly,
    name: 'Hourly',
  },
  {
    key: paymentStrategies.HourlySpecified,
    name: 'Specified for hours',
  },
  {
    key: paymentStrategies.Daily,
    name: 'Daily',
  },
  {
    key: paymentStrategies.Weekly,
    name: 'Weekly',
  },
  {
    key: paymentStrategies.Monthly,
    name: 'Monthly',
  },
];

export const clientPaymentStrategies = [
  {
    key: paymentStrategies.Free,
    name: 'Free',
  },
  {
    key: paymentStrategies.FlatFee,
    name: 'Fixed price',
  },
  {
    key: paymentStrategies.PricePerSpot,
    name: 'Price per parking spot',
  },
  {
    key: paymentStrategies.FlatFeePlusRevenue,
    name: 'Revenue sharing',
  },
];