export const expectAtLeastOneOption = (options, errorText) => {
  if (options == undefined || options.length === 0) {
    return {
      success: false,
      text: errorText,
    };
  }

  return {
    success: true,
    text: "",
  };
};
