import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { paths } from "../../utilities/constants";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import {
  cancelReservation,
  deleteReservation,
  getCurrentUserReservationSettings,
  getReservations,
} from "../../utilities/apiUtils";
import CommonModal from "../../components/commonModal/CommonModal";
import { Button } from "react-bootstrap";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";
import Table from "../../components/table/Table";
import EditImage from "../../assets/images/edit.svg";
import DeleteImage from "../../assets/images/delete.svg";
import CancelImage from "../../assets/images/cancel.png";
import { formatTime, getDateFromDatabaseTime } from "../../utilities/timeUtils";
import ReservationsListPage from "./ReservationsListPage";
import PageTitle from "../../components/pageTitle/PageTitle";
import ActionButton from "../../components/actionButton/ActionButton";
import SecondaryButton from "../../components/secondaryButton/SecondaryButton";

function ReservationsPage(props) {
  const [reservations, setReservations] = useState([]);
  const [reservationToBeDeleted, setReservationToBeDeleted] = useState(null);
  const [reservationToBeCanceled, setReservationToBeCanceled] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [reservationsDisabled, setReservationsDisabled] = useState(false);
  const [showReservationsListPage, setShowReservationsListPage] =
    useState(false);

  useEffect(() => {
    getCurrentUserReservationSettings().then((res) => {
      if (res == null) {
        setShowReservationsListPage(true);
        return;
      }

      if (res.data) {
        setPageLoading(false);
        setReservationsDisabled(!res.data.reservationsEnabled);
        loadReservations();
      }
    });
  }, []);

  const loadReservations = () => {
    getReservations().then((res) => {
      if (res.data) {
        setReservations([...res.data]);
      }
    });
  };

  const handleReservationDelete = () => {
    deleteReservation(reservationToBeDeleted).then(() => {
      toggleDeleteModal();
      loadReservations();
    });
  };

  const handleReservationCancel = () => {
    cancelReservation(reservationToBeCanceled).then(() => {
      toggleCancelModal();
      loadReservations();
    });
  };

  const toggleDeleteModal = (id = null) => {
    setReservationToBeDeleted(id);
  };

  const toggleCancelModal = (id = null) => {
    setReservationToBeCanceled(id);
  };

  const toggleEdit = (id = null) => {
    props.history.push(`${paths.accessManagementPath}/reservations/${id}`);
  };

  const getReservationRow = (reservation) => {
    const now = new Date();
    const entryDate = getDateFromDatabaseTime(reservation.entryTime);
    const entryDatFormatted = formatTime(entryDate);
    const exitDate = new Date(
      entryDate.getTime() + reservation.durationMinutes * 60000
    );
    const exitDateFormatted =
      reservation.durationMinutes > 0 ? formatTime(exitDate) : "Not specified";
    let state = "";
    if (entryDate.getTime() > now.getTime()) {
      state = "Planned";
    } else {
      if (
        reservation.durationMinutes > 0 &&
        exitDate.getTime() >= now.getTime()
      ) {
        state = "Active";
      } else {
        state = "Complete";
      }
    }

    return (
      /*        <td>
          <img
            alt="Edit"
            title="Edit"
            onClick={() => {
              toggleEdit(reservation.id);
            }}
            src={EditImage}
            className="action"
          />

          <img
            alt="Delete"
            title="Delete"
            onClick={() => {
              toggleDeleteModal(reservation.id);
            }}
            src={DeleteImage}
            className="action"
          />

          <img
            alt="Cancel"
            title="Cancel"
            onClick={() => {
              toggleCancelModal(reservation.id);
            }}
            src={CancelImage}
            className="action"
          />
        </td>*/
      <tr key={reservation.id}>
        <td>{entryDatFormatted}</td>
        <td>{exitDateFormatted}</td>
        <td>{reservation.parkingLot.name}</td>
        <td>{reservation.licensePlateNumber}</td>
        <td>{state}</td>
      </tr>
    );
  };

  return (
    <>
      <Header />
      <CommonModal
        show={!!reservationToBeDeleted}
        onClose={toggleDeleteModal}
        body="Are you sure you want to delete this reservation?"
        title="Reservation removal"
        footer={
          <div>
            <SecondaryButton
              value="Cancel"
              onClick={() => toggleDeleteModal()}
            />
            <ActionButton
              value="Delete"
              onClick={() => handleReservationDelete()}
            />
          </div>
        }
      />
      <CommonModal
        show={!!reservationToBeCanceled}
        onClose={toggleCancelModal}
        body="Are you sure you want to cancel this reservation?"
        title="Cancel reservation"
        footer={
          <div>
            <Button variant="secondary" onClick={() => toggleCancelModal()}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => handleReservationCancel()}>
              Cancel reservation
            </Button>
          </div>
        }
      />

      <div className="content-page">
        <div class="container-fluid">
          <div class="row">
            <Sidebar />
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10">
              <PageTitle value="Reservations" />
              {showReservationsListPage && <ReservationsListPage />}
              {!showReservationsListPage && (
                <>
                  {reservationsDisabled && (
                    <div class="alert alert-primary">
                      You can not create reservations, since reservations for
                      you are disabled.
                    </div>
                  )}
                  {pageLoading ? (
                    <div class="alert alert-primary">Loading.</div>
                  ) : (
                    !reservationsDisabled && (
                      <>
                        <Link
                          to={`${paths.accessManagementPath}/reservations/new`}
                        >
                          <PrimaryButton value="New reservation" />
                        </Link>
                        <br />
                        <br />
                        {reservations && !reservations.length && (
                          <div class="alert alert-primary">
                            Currently, there are no reservations.
                          </div>
                        )}
                        {reservations && !!reservations.length && (
                          <Table
                            headers={[
                              <th>Entry time</th>,
                              <th>Exit time</th>,
                              <th>Parking lot</th>,
                              <th>License plate</th>,
                              <th>State</th>,
                              //<th>Actions</th>,
                            ]}
                            generateRow={getReservationRow}
                            items={reservations}
                          />
                        )}
                      </>
                    )
                  )}
                </>
              )}
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(ReservationsPage);
